export enum BIRDI_PLANS {
    BRD_01 = 'BRD01',
    BRD_02 = 'BRD02',
    NONE = ''
}

export enum BIRDI_REGISTRATION_FLOW_PLAN {
    ASSIST_OTHERS = BIRDI_PLANS.BRD_01,
    MEMBERSHIP = BIRDI_PLANS.BRD_02,
    INSURANCE = 'INSURANCE'
}

export enum PLAN_COVERAGE_TYPE {
    PRIMARY = 'PRIMARY',
    INACTIVE = 'INACTIVE'
}

export const PLANS_ALLOWING_PRICE = [BIRDI_PLANS.BRD_01, BIRDI_PLANS.BRD_02];
