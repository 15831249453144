import { Allergies, HealthConditions, MedicalConditions } from 'types/health-profile';

import { lowercaseAndCapitalize } from 'util/string';

const removeNoneConditions = (reducedConditions: string[]) => {
    if (reducedConditions.length > 1 && reducedConditions.includes('None')) {
        return reducedConditions.filter((condition) => condition !== 'None');
    }
    return reducedConditions;
};

const reduceConditions = (conditions: MedicalConditions | Allergies) => {
    return Object.entries(conditions)
        .map((keyValPair) => {
            const key = keyValPair[0];
            const val = keyValPair[1];

            if (typeof val === 'boolean' && key !== 'messageStatus') {
                return key;
            }
            return '';
        })
        .filter((cond) => cond && cond.length > 0)
        .sort();
};

export const reduceExistingConditions = ({
    conditions,
    capitalizeKey = false
}: {
    conditions: MedicalConditions | Allergies | undefined;
    capitalizeKey?: boolean;
}): string[] => {
    if (!conditions) return [];
    const reducedConditions = Object.entries(conditions)
        .map((keyValPair) => {
            const key = keyValPair[0];
            const val = keyValPair[1];

            if (
                typeof val === 'boolean' &&
                Boolean(val) &&
                key.toLocaleLowerCase() !== 'messagestatus' &&
                key.toLocaleLowerCase() !== 'undefined'
            ) {
                return capitalizeKey ? lowercaseAndCapitalize(key) : key;
            }
            return '';
        })
        .filter((cond) => cond && cond.length > 0)
        .sort();

    return removeNoneConditions(reducedConditions);
};

export const mapHealthConditions = (
    healthConditions: HealthConditions | undefined,
    conditionType: 'allergies' | 'medicalConditions'
): string[] =>
    healthConditions && healthConditions[conditionType]
        ? reduceExistingConditions({ conditions: healthConditions[conditionType] })
        : [];

export const formatConditionsChoices = (conditions: MedicalConditions | Allergies): string[] => {
    if (!conditions) return [];
    const reducedConditions = reduceConditions(conditions);
    const none = reducedConditions.find((condition: string) => condition === 'None');
    return [none, ...reducedConditions.filter((condition: string) => condition !== 'None')].filter(
        (condition): condition is string => !!condition
    );
};
