import classNames from 'classnames';
import React from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import { closeModal, ModalCTAButton } from 'state/birdi-modal/birdi-modal.reducers';
import {
    birdiModalAlignmentSelector,
    birdiModalBackdropSelector,
    birdiModalBusySelector,
    birdiModalClassnameSelector,
    birdiModalContentSelector,
    birdiModalCtaOrientationSelector,
    birdiModalFooterStyleSelector,
    birdiModalHeaderContentSelector,
    birdiModalKeyboardSelector,
    birdiModalOnCloseSelector,
    birdiModalOpenSelector,
    birdiModalOverflowSelector,
    birdiModalShowCloseSelector,
    birdiModalSizeSelector,
    birdiModalTypeSelector
} from 'state/birdi-modal/birdi-modal.selectors';

import './birdi-modal.style.scss';

/**
 * A Modal that should be present in all the pages, this only activates if the prop isOpen
 * set to true.
 * @param {ModalProps} props Modal props to use for managing state of the modal.
 * @return {JSX.Element} Returns a rendered modal, that may stay hidden until required.
 */
export default function BirdiModal() {
    const isOpened = useSelector(birdiModalOpenSelector);
    const onClose = useSelector(birdiModalOnCloseSelector);
    const showCloseButton = useSelector(birdiModalShowCloseSelector);
    const className = useSelector(birdiModalClassnameSelector);
    const headerContent = useSelector(birdiModalHeaderContentSelector);
    const content = useSelector(birdiModalContentSelector);
    const isBusy = useSelector(birdiModalBusySelector);
    const modalBackdrop = useSelector(birdiModalBackdropSelector);
    const modalSize = useSelector(birdiModalSizeSelector);
    const modalType = useSelector(birdiModalTypeSelector);
    const modalFooterStyle = useSelector(birdiModalFooterStyleSelector);
    const modalCtaOrientation = useSelector(birdiModalCtaOrientationSelector);
    const isModalCentered = useSelector(birdiModalAlignmentSelector);
    const isAllowEscapeClose = useSelector(birdiModalKeyboardSelector);
    const overflow = useSelector(birdiModalOverflowSelector);
    const dispatch = useDispatch();

    return (
        <ModalRender
            className={className}
            contentClassName={content.contentClassName}
            onHide={() => {
                dispatch(closeModal({})); //TODO: Need to confirm this is the default functionality
            }}
            showCloseButton={showCloseButton}
            onClose={onClose}
            isOpened={isOpened}
            headerContent={headerContent}
            bodyContent={content.bodyContent}
            ctas={content.ctas}
            isBusy={isBusy}
            backdrop={modalBackdrop}
            size={modalSize}
            type={modalType}
            footerStyle={modalFooterStyle}
            ctaOrientation={modalCtaOrientation}
            isCentered={isModalCentered}
            keyboard={isAllowEscapeClose}
            overflow={overflow}
        />
    );
}

export interface ModalRenderProps
    extends Pick<ModalProps, 'centered' | 'scrollable' | 'size' | 'backdrop' | 'keyboard'> {
    className?: string;
    contentClassName?: string;
    isOpened: boolean;
    bodyContent?: JSX.Element;
    headerContent?: JSX.Element;
    footerContent?: JSX.Element;
    ctas?: ModalCTAButton[];
    ctaOrientation?: 'horizontal' | 'vertical';
    showCloseButton: boolean;
    onHide: () => void;
    onClose?: () => void;
    isBusy?: boolean;
    footerStyle?: 'sticky' | 'reverse';
    type?: 'default' | 'danger';
    isCentered?: boolean;
    backdrop?: boolean | 'static' | undefined;
    overflow?: 'hidden' | 'unset';
}

export const ModalRender = React.memo(function ({
    className,
    contentClassName,
    isOpened,
    bodyContent,
    headerContent,
    footerContent,
    ctas = [],
    ctaOrientation,
    showCloseButton,
    onClose,
    onHide,
    isBusy,
    backdrop,
    scrollable,
    size = 'xl',
    type,
    footerStyle,
    isCentered,
    keyboard,
    overflow = 'hidden'
}: ModalRenderProps) {
    const isStickyFooter = footerStyle === 'sticky';
    const isReverseFooter = footerStyle === 'reverse';
    const dialogClasses = classNames(className, 'birdi-modal mx-xs-5 mx-xs-auto', {
        'mt-5': !isCentered,
        'sticky-footer-modal': isStickyFooter
    });
    const contentClasses = classNames(
        'birdi-modal__content',
        contentClassName,
        { 'with-header': type !== undefined },
        { [`header-type-${type}`]: type !== undefined },
        `birdi-modal__content--overflow-${overflow}`
    );
    const handleHide = () => {
        if (onHide) onHide();
        if (onClose) onClose();
    };
    const modalTitle =
        bodyContent && bodyContent?.props?.title ? bodyContent.props.title.replace(/ /g, '') : 'unknown-modal';

    const hasCustomFooterContent = !!footerContent;

    const defaultFooterClasses = classNames('d-flex', 'justify-content-center', 'birdi-modal__footer', {
        'flex-column-reverse': isReverseFooter,
        'flex-column': ctaOrientation === 'vertical',
        'flex-row': ctaOrientation !== 'vertical'
    });

    return (
        <Modal
            size={size}
            dialogClassName={dialogClasses}
            contentClassName={contentClasses}
            show={isOpened}
            onHide={handleHide}
            data-ga-type="modal"
            backdrop={backdrop}
            centered={isCentered}
            scrollable={scrollable}
            keyboard={keyboard}
        >
            <Modal.Header closeButton={showCloseButton}>{headerContent}</Modal.Header>
            <Modal.Body>{bodyContent}</Modal.Body>

            {hasCustomFooterContent || ctas.length > 0 ? (
                <Modal.Footer className={isStickyFooter ? undefined : defaultFooterClasses}>
                    {!hasCustomFooterContent &&
                        ctas.map((cta, idx) => (
                            <React.Fragment key={`modal-footer-button-${idx}`}>
                                {cta.async && (
                                    <Button
                                        async
                                        dataGAType={'modal'}
                                        dataGALocation={cta?.dataGALocation?.replace(/ /g, '') || modalTitle}
                                        {...cta}
                                        type={'button'}
                                        isBusy={isBusy}
                                        disabled={isBusy}
                                    />
                                )}
                                {!cta.async && (
                                    <Button
                                        dataGAType={'modal'}
                                        dataGALocation={cta?.dataGALocation?.replace(/ /g, '') || modalTitle}
                                        {...cta}
                                        type={'button'}
                                    />
                                )}
                            </React.Fragment>
                        ))}

                    {hasCustomFooterContent && footerContent}
                </Modal.Footer>
            ) : (
                <></>
            )}
        </Modal>
    );
});
