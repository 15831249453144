import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';

import { PhoneNumberTextProps } from 'ui-kit/phone-number-text/phone-number-text.props';
import Text from 'ui-kit/text/text';

const formatMap = {
    US: {
        countryCode: 'US',
        maxLength: 10
    },
    CA: {
        countryCode: 'CA',
        maxLength: 10
    }
};

function cleanPhone(phone: any): string {
    if (phone) {
        if (typeof phone === 'object') {
            return String(phone[0]);
        } else {
            return String(phone);
        }
    } else {
        return '';
    }
}

const PhoneNumberText = ({ countryCode, onChange, value, ...rest }: PhoneNumberTextProps) => {
    const format = formatMap[countryCode];
    const formatType = format?.countryCode === 'US' ? 'NATIONAL' : 'INTERNATIONAL';
    const phoneNumberValue = parsePhoneNumberFromString(cleanPhone(value), countryCode);
    const formattedValue = phoneNumberValue?.format(formatType);

    const handleChange = (e: any) => {
        // DRX-2030: Remove non-numeric characters to avoid international numbers
        const formattedValue = new AsYouType(countryCode).input(e.currentTarget.value.replace(/\D/g, ''));
        const parsedNumber = parsePhoneNumberFromString(e.currentTarget.value, countryCode);

        if (parsedNumber && parsedNumber?.nationalNumber.length > format.maxLength) {
            e.currentTarget.value = value;
        } else {
            // DRX-3064: Remove leading 1 on paste or autoComplete phone number
            e.currentTarget.value = formattedValue.replace(/^1/, '').trim();
        }

        if (onChange) onChange(e);
    };

    return (
        <div className={`phone-number-text`}>
            <Text
                onChange={handleChange}
                onBlur={rest.onBlur}
                type="text"
                value={formattedValue}
                defaultValue={formattedValue}
                name={rest.name}
                label={rest.label}
                errors={rest.errors}
                touched={rest.touched}
                className={rest.className}
                autoComplete={rest.autoComplete}
                inputRef={rest.inputRef}
                disabled={rest.disabled}
            />
        </div>
    );
};

export default PhoneNumberText;
