const buildQueryParams = <T extends Record<string, any>>(params: T): string => {
    return Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
        .join('&');
};

const isNullOrEmptyObject = (variable: any): boolean => {
    if (variable === null || variable === undefined) {
        return true;
    }

    if (typeof variable === 'string') {
        try {
            const parsedVariable = JSON.parse(variable);
            if (typeof parsedVariable === 'object' && Object.keys(parsedVariable).length === 0) {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    if (typeof variable === 'object' && Object.keys(variable).length === 0) {
        return true;
    }

    return false;
};

const isDeepEqual = (object1: any, object2: any) => {
    // Ensuring that type object is the same
    if (typeof object1 !== typeof object2) return false;
    // // Ensuring that both objects are not equally undefined or null.
    if (object1 === undefined && object2 === undefined) return true;

    // Ensuring that object length is the same
    if (object1.length !== object2.length) return false;

    // Validate HTML object
    if (isHTMLElement(object1) || isHTMLElement(object2)) {
        // Validate HTML objects
        const stringHTML = Object.prototype.hasOwnProperty.call(object1, 'outerHTML') && object1?.outerHTML;
        const stringHTML2 = Object.prototype.hasOwnProperty.call(object2, 'outerHTML') && object2?.outerHTML;
        return stringHTML === stringHTML2;
    }

    if (Array.isArray(object1) && Array.isArray(object2)) {
        // Trying to compare objects first as a string, this will help us
        //for performance when objects are identical.
        const objString1 = JSON.stringify(object1);
        const objString2 = JSON.stringify(object2);

        if (objString1 === objString2) return true;
    }

    // When objects are not identical (i.e. different order)
    // then we validate each property
    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);

    if (objKeys1.length !== objKeys2.length) return false;

    for (const key of objKeys1) {
        const value1 = object1[key];
        const value2 = object2[key];

        const isObjects = isObject(value1) && isObject(value2);

        if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
        }
    }
    return true;
};

const isObject = (object: object) => {
    return object != null && typeof object === 'object';
};

const isHTMLElement = (object: any) =>
    typeof object === 'object' && !!object.tagName && object instanceof HTMLElement && !!object.nodeType;

export { isDeepEqual, buildQueryParams, isNullOrEmptyObject };
