import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';

import BirdiAccordion from 'ui-kit/accordion/accordion';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import HealthConditions, { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import { HealthConditionsSubmitEvent } from 'components/health-conditions/health-conditions.props';
import {
    HealthConditionRendererProps,
    HealthProfileConditionsUpdateEvent,
    HealthProfileProps
} from 'components/health-profile/health-profile.props';

import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine
} from 'state/medical-conditions/medical-conditions.routines';

import { filterUndefined } from 'util/function';

import './health-profile.style.scss';

export const renderHealthConditions = ({
    action,
    ePostPatientNum,
    existingConditions,
    existingFreeformConditions,
    freeformConditionsLabel,
    conditionChoices,
    conditionType,
    onUpdateHealthConditions,
    submitLabel,
    formName,
    isInModal
}: HealthConditionRendererProps) => {
    const handleUpdateConditionsClick = ({
        action,
        e: { conditions, freeformConditions },
        choices
    }: HealthProfileConditionsUpdateEvent) => {
        //  set values on the update payload
        const formattedChoicesObject = choices.reduce<{ [condition: string]: false }>(
            (obj, cond) => ({
                ...obj,
                [cond]: false
            }),
            {}
        );
        const formattedConditionsObject = conditions.reduce<{ [condition: string]: true }>(
            (obj, cond) => ({
                ...obj,
                [cond]: true
            }),
            {}
        );

        const update = {
            ePostPatientNum: ePostPatientNum,
            Other: freeformConditions,
            TemporaryOther: freeformConditions,
            ...formattedChoicesObject,
            ...formattedConditionsObject
        };

        if (onUpdateHealthConditions) onUpdateHealthConditions({ action, update });
    };

    return (
        <HealthConditions
            existingConditions={existingConditions}
            conditionChoices={conditionChoices}
            conditionType={conditionType}
            freeformExistingConditions={existingFreeformConditions}
            freeformConditionsLabel={freeformConditionsLabel}
            onSubmit={(e: HealthConditionsSubmitEvent) => {
                handleUpdateConditionsClick({
                    action,
                    e,
                    choices: conditionChoices
                });
            }}
            submitLabel={submitLabel}
            formName={formName}
            isInModal={isInModal}
            dataGaLocation="HealthconditionsModal"
        />
    );
};

const HealthProfile = ({
    className,
    includeAccordionSectionTitlePillIcon,
    onUpdateHealthConditions,
    healthConditions,
    isLoadingAllergies,
    isLoadingHealthConditions
}: HealthProfileProps) => {
    const { t } = useTranslation();

    const classes = classNames('health-profile-component', className);

    const {
        allergyChoices,
        existingAllergies,
        existingFreeformAllergies,
        conditionChoices,
        existingConditions,
        existingFreeformConditions,
        ePostPatientNum
    } = healthConditions;

    const medicalConditionsRendered = useMemo(() => {
        return renderHealthConditions({
            action: medicalConditionsAddOrUpdateRoutine,
            conditionChoices: conditionChoices.filter(filterUndefined),
            conditionType: 'condition',
            ePostPatientNum,
            existingConditions,
            existingFreeformConditions,
            onUpdateHealthConditions,
            submitLabel: t('components.healthConditions.labels.submitConditions'),
            formName: t('accordions.healthConditions.title')
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthConditions]);

    const allergiesRendered = useMemo(() => {
        return renderHealthConditions({
            action: medicalConditionsAllergiesAddOrUpdateRoutine,
            existingConditions: existingAllergies,
            conditionChoices: allergyChoices.filter(filterUndefined),
            conditionType: 'allergy',
            ePostPatientNum,
            existingFreeformConditions: existingFreeformAllergies,
            freeformConditionsLabel: t('components.healthConditions.labels.freeformAllergiesLabel'),
            onUpdateHealthConditions,
            submitLabel: t('components.healthConditions.labels.submitAllergies'),
            formName: t('accordions.allergies.title')
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthConditions]);

    const isHealthProfileIncomplete = existingConditions.length === 0 || existingAllergies.length === 0;
    return (
        <div className={classes}>
            {isHealthProfileIncomplete ? (
                <ToastBox variant="warning" icon="warning">
                    <Trans i18nKey={'pages.requestTelemedicineVisit.healthProfile.healthProfileConfirmation'} />
                </ToastBox>
            ) : (
                <p className="health-profile-component_note"> {t('pages.profile.healthProfile.subHeading')}</p>
            )}

            <BirdiAccordion>
                <BirdiAccordion.Toggle
                    title={t('accordions.healthConditions.title')}
                    toggleText={t('accordions.healthConditions.toggleText')}
                    includeTitleIcon={includeAccordionSectionTitlePillIcon}
                    alwaysOpenContent={
                        isLoadingHealthConditions ? (
                            <SpinnerInline />
                        ) : (
                            <HealthConditionPills conditions={existingConditions} />
                        )
                    }
                >
                    {isLoadingHealthConditions ? <></> : medicalConditionsRendered}
                </BirdiAccordion.Toggle>
                <BirdiAccordion.Spacer />
                <BirdiAccordion.Toggle
                    title={t('accordions.allergies.title')}
                    includeTitleIcon={includeAccordionSectionTitlePillIcon}
                    toggleText={t('accordions.allergies.toggleText')}
                    alwaysOpenContent={
                        isLoadingAllergies ? <SpinnerInline /> : <HealthConditionPills conditions={existingAllergies} />
                    }
                >
                    {isLoadingAllergies ? <></> : allergiesRendered}
                </BirdiAccordion.Toggle>
            </BirdiAccordion>
        </div>
    );
};

export default HealthProfile;
