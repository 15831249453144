import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RegistrationHandlers } from './registration.handlers';
import { ContinueRegistrationErrorsEnum } from './registration.helpers';
import { registrationInitialState } from './registration.initial-values';
import {
    registrationContinueRegistrationRoutine,
    registrationCreateWebAccountRoutine,
    registrationInsuredValidationRoutine,
    registrationMinimalFinalizeRoutine
} from './registration.routines';
import { RegistrationFormSchema, RegistrationState } from './registration.types';

const registrationSlice = createSlice({
    name: 'registration',
    initialState: registrationInitialState,
    reducers: {
        setRegistrationFormValues: (state, action: PayloadAction<Partial<RegistrationFormSchema>>) =>
            RegistrationHandlers.setFormValues(state, action),
        clearRegistrationFormFields: (state) => RegistrationHandlers.clearAllFormFields(state),
        setAcceptedTerms: (state) => {
            state.isAcceptedTerms = true;
        }
    },
    extraReducers: ({ addCase }) => {
        // Registration Create Web Account Cases
        addCase(registrationCreateWebAccountRoutine.TRIGGER, (state) => RegistrationHandlers.setLoading(state, true));
        addCase(registrationCreateWebAccountRoutine.SUCCESS, (state) => RegistrationHandlers.setLoading(state, false));
        addCase(registrationCreateWebAccountRoutine.FAILURE, (state) => RegistrationHandlers.setLoading(state, false));

        // Registration Continue Cases
        addCase(registrationContinueRegistrationRoutine.TRIGGER, (state) => {
            state.isLoading = true;
            state.messageErrorText = undefined;
            state.isRegistrationCompleted = undefined;
        });
        addCase(
            registrationContinueRegistrationRoutine.SUCCESS,
            (state, { payload }: PayloadAction<RegistrationState>) => {
                state.registration = payload.registration;
                state.isRegistrationCompleted =
                    payload?.messageStatus === false &&
                    payload?.messageText === ContinueRegistrationErrorsEnum.RegistrationCompleted;
                state.isLoading = false;
            }
        );
        addCase(
            registrationContinueRegistrationRoutine.FAILURE,
            (state, { payload }: PayloadAction<RegistrationState>) => {
                state.isLoading = false;
                state.registration = {};
                state.isRegistrationCompleted = false;
                state.messageErrorText = payload?.messageErrorText;
            }
        );

        // Registration Minimal Finalize Cases
        addCase(registrationMinimalFinalizeRoutine.TRIGGER, (state) => RegistrationHandlers.setLoading(state, true));
        addCase(registrationMinimalFinalizeRoutine.SUCCESS, (state, { payload }: PayloadAction<RegistrationState>) => {
            state.registration = payload;
        });
        addCase(registrationMinimalFinalizeRoutine.FAILURE, (state) => RegistrationHandlers.setLoading(state, false));

        // Insured Registration 2nd attempt
        addCase(registrationInsuredValidationRoutine.TRIGGER, (state) => RegistrationHandlers.setLoading(state, true));
        addCase(registrationInsuredValidationRoutine.SUCCESS, (state) => RegistrationHandlers.setLoading(state, false));
        addCase(registrationInsuredValidationRoutine.FAILURE, (state) => RegistrationHandlers.setLoading(state, false));
    }
});

export const { setRegistrationFormValues, clearRegistrationFormFields, setAcceptedTerms } = registrationSlice.actions;
export default registrationSlice.reducer;
