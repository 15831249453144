import { ProfileObjectPayload } from 'state/account/account.services';
import { Dependent } from 'state/family-profile/family-profile.reducers';

import { CartPatient } from 'types/cart';
import { GetEasyRefillPatientDataResponse } from 'types/easy-refill';
import { PatientBasicData } from 'types/family-account';

/**
 * @param prescriptionNumber Defines the specific RX that the caregiver uses to add the dependent
 * @returns correct translation for every error case.
 */
export const mapAddFamilyMemberErrors = (prescriptionNumber: string): Record<string, string> => ({
    [`No result found for prescriptionNumber: ${prescriptionNumber}`]:
        'components.addFamilyMember.errorModals.noResultsFoundError',
    [`Patient details are invalid for prescriptionNumber: ${prescriptionNumber}`]:
        'components.addFamilyMember.errorModals.rxNotMatchError',
    [`Patient prescription ${prescriptionNumber} is invalid, rx need to be filled within 12 months`]:
        'components.addFamilyMember.errorModals.rxExpired',
    [`Prescription ${prescriptionNumber} is already associated.`]:
        'components.addFamilyMember.errorModals.invalidDetails',
    [`Dependent has already been added to the Caregiver`]: 'components.addFamilyMember.errorModals.alreadyAssociated',
    [`Dependent is already associated with another family member`]:
        'components.addFamilyMember.errorModals.alreadyAssociated',
    [`There is already an invitation for this Patient`]: 'components.addFamilyMember.errorModals.alreadyAssociated'
});

/**
 * @param errors Gets an Array of strings with the error responses
 * @param prescriptionNumber Defines the specific RX that the caregiver uses to add the dependent
 * @returns the correct translation for the error modal.
 */
export const mapFamilyAccountErrors = (errors: string[], prescriptionNumber: string): string | undefined => {
    const possibleErrors = mapAddFamilyMemberErrors(prescriptionNumber);

    for (const error of errors) {
        if (Object.prototype.hasOwnProperty.call(possibleErrors, error)) {
            return possibleErrors[error];
        }
    }
    return undefined;
};

/**
 * Maps API error messages from the SendInvite endpoint to their corresponding translation keys.
 *
 * DRX-4482: These are the API errors from the SendInvite endpoint. As of now, specific error messages
 * are not provided, so the same translation key is used for most errors. This mapping will allow
 * consistent handling and display of error messages in the UI.
 *
 * @param {string} [epostPatientNumCaregiver] - The ePost patient number of the caregiver.
 * @param {string} [epostPatientNumDependent] - The ePost patient number of the dependent.
 *
 * @returns {Record<string, string>} A record object mapping error messages returned by the API
 * to corresponding translation keys used in the UI.
 *
 */
export const sendInviteErrors = (
    epostPatientNumCaregiver?: string,
    epostPatientNumDependent?: string
): Record<string, string> => ({
    'Something unexpected happened. Check logs for details.': 'components.addFamilyMember.errorModals.rxNotMatchError',
    'Something unexpected happened': 'components.addFamilyMember.errorModals.rxNotMatchError',
    [`Defaulted to email. Dependent does not have an email address in epost and no notification settings are present: ${epostPatientNumDependent}`]:
        'components.addFamilyMember.errorModals.rxNotMatchError',
    [`Caregiver does not have an email address in epost and no notification settings are present: ${epostPatientNumCaregiver}`]:
        'components.addFamilyMember.errorModals.rxNotMatchError',
    'Dependent is already associated with another family member':
        'components.addFamilyMember.errorModals.alreadyAssociated',
    'Caregiver dependent not found': 'components.addFamilyMember.errorModals.rxNotMatchError'
});

/**
 * Maps error strings to their corresponding translations.
 *
 * @param errors An array of error strings received from the API
 * @param epostPatientNumCaregiver The caregiver's ePost patient number.
 * @param epostPatientNumDependent The dependent's ePost patient number.
 * @returns The translation key for the error modal or undefined if no match is found.
 */
export const mapSendInviteError = (
    errors: string[],
    epostPatientNumCaregiver: string,
    epostPatientNumDependent: string
): string | undefined => {
    const possibleErrors = sendInviteErrors(epostPatientNumCaregiver, epostPatientNumDependent);

    for (const error of errors) {
        if (Object.prototype.hasOwnProperty.call(possibleErrors, error)) {
            return possibleErrors[error];
        }
    }
    return undefined;
};

export const getBasicPatientData = (
    patient: CartPatient | Dependent | GetEasyRefillPatientDataResponse | ProfileObjectPayload
): PatientBasicData => {
    // Check if the patient contains the property patientFirstName which means the object
    // has the structure of ProfileObjectPayload
    if ((patient as ProfileObjectPayload).patientFirstName) {
        const patientMapped: ProfileObjectPayload = patient as ProfileObjectPayload;
        return {
            epostPatientNum: patientMapped.epostPatientNum,
            firstName: patientMapped.patientFirstName,
            lastName: patientMapped.patientLastName
        };
    }

    // Check if the cart patient or the easy refill patient data has epostPatientNum property
    // to consider it has the properties required by PatientBasicData
    if ((patient as CartPatient | GetEasyRefillPatientDataResponse).epostPatientNum) {
        const patientMapped: PatientBasicData = patient as PatientBasicData;
        return {
            epostPatientNum: patientMapped.epostPatientNum,
            firstName: patientMapped.firstName,
            lastName: patientMapped.lastName
        };
    }

    // Otherwise map data as Dependent object
    const patientMapped: Dependent = patient as Dependent;

    return {
        epostPatientNum: patientMapped.ePostPatientNum,
        firstName: patientMapped.familyMemberFirstName,
        lastName: patientMapped.familyMemberLastName || ''
    };
};
