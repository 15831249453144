import { createSelector } from '@reduxjs/toolkit';

import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import {
    familyMembersEligibilitySelector,
    familyMembersPricingDataSelector
} from 'state/family-profile/family-profile.selectors';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';
import { RootState } from 'state/store';

import { AddressPayload } from 'types/account';
import { CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { DependentsPricingData, FamilyAutoRefillData } from 'types/family-account';
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { PlansObjectPayload } from 'types/plans';

import { ApiStatus } from 'enums/api-status';

import { getCartObject } from 'util/cart';

import {
    accountCreditCardsSelector,
    accountPlansSelector,
    accountProfileAddressesSelector,
    accountProfileApiStatusSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector,
    accountStateSelector
} from '../account/account.selectors';
import {
    getAllEpostsCarts,
    getCartShippingMethodId,
    getCurrentCardOrderBillship,
    getCurrentCartAddress,
    getCurrentCartCard,
    getPatientCart
} from './cart.helpers';

export const CartSelector = (state: RootState) => state.cartReducer;

export const cartSelector = createSelector(CartSelector, (cart) => cart.cart);
export const cartItemsSelector = createSelector(CartSelector, (state) =>
    state.cart?.flatMap((cart) => cart.Order.refillRxs)
);

export const isLoadingAddressShippingCartSelector = createSelector(
    CartSelector,
    (cart) => cart.isLoadingAddressShipping
);
export const isLoadingPaymentsCartSelector = createSelector(CartSelector, (cart) => cart.isLoadingPayments);
export const isLoadingCancelPrescriptionsCartSelector = createSelector(
    CartSelector,
    (cart) => cart.isLoadingCancelPrescriptions
);

export const cartOrderBillShipSelector = createSelector([cartSelector], (carts) => getCurrentCardOrderBillship(carts));

export const mainCartSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector],
    (carts, patientEpostNum) => {
        return getPatientCart(carts, patientEpostNum);
    }
);
export const cartOrderBillShipMethodSelector = createSelector([cartSelector], (carts) =>
    getCartShippingMethodId(carts)
);

export const cartIsBusySelector = createSelector(
    [
        CartSelector,
        accountProfileApiStatusSelector,
        accountStateSelector,
        isLoadingHealthConditionsSelector,
        isLoadingAllergiesSelector
    ],
    (cart, accountProfileApiStatus, accountSelector, isLoadingHealthConditions, isLoadingAllergies) =>
        cart.isBusy ||
        cart.isLoadingAddressShipping ||
        cart.isLoadingPayments ||
        cart.isLoadingAddressShipping ||
        cart.isLoadingCancelPrescriptions ||
        accountProfileApiStatus === ApiStatus.LOADING ||
        accountSelector?.isBusy ||
        isLoadingHealthConditions ||
        isLoadingAllergies
);

export const cartApiStatusSelector = createSelector(CartSelector, ({ cartApiStatus }) => cartApiStatus);

export const cartOrderShippingAddressSelector = createSelector(
    [accountProfileAddressesSelector, cartSelector],
    (addresses, carts) => {
        return getCurrentCartAddress(carts as CartPayload[], addresses as AddressPayload[]);
    }
);

export const cartOrderPaymentCardSelector = createSelector(
    [accountCreditCardsSelector, cartSelector, cartItemsSelector],
    (
        cards: CreditCardPayload[] | undefined,
        carts: CartPayload[] | undefined,
        cartItems: RefillRxs[] | undefined
    ): CreditCardPayload | undefined => {
        if (cartItems?.length === 0 || !cards || !carts) return;
        return getCurrentCartCard(carts, cards);
    }
);

export const cartOrderHeaderSelector = createSelector([cartSelector], (carts) => {
    /**
     * Bugfix DRX-2275: As caregiver accounts uses the Birdi Order Number information,
     * and Complete Order for non-caregiver accounts don't have the EpostNum to be
     * possible to use the getMainCart function, we can just return the first position
     * for the cart object.
     */
    return carts && carts[0]?.Order.orderHeader;
});

export const cartOrderBirdiOrderNumberSelector = createSelector(
    [cartSelector, accountProfilEPostPatientNumSelector, accountProfileSelector, accountProfilIsCaregiverSelector],
    (carts, EpostPatientNum, profileObject, isCaregiver) => {
        if ((!isCaregiver && !profileObject?.dependents) || profileObject?.dependents === undefined) return [];

        const epostTargets = [
            ...profileObject.dependents.map((dependent) => dependent?.epostPatientNum),
            EpostPatientNum
        ];
        const dependentCarts = getAllEpostsCarts(carts, epostTargets);

        return dependentCarts && dependentCarts?.[0]?.BirdiOrderNumber;
    }
);

export const cartOrderPlacedSelector = createSelector(CartSelector, (cart) => cart.orderPlaced);

export const cartZipCodeSelector = createSelector(
    [cartOrderShippingAddressSelector, cartIsBusySelector],
    (address, cartIsBusy) => {
        if (cartIsBusy) return;
        return address?.zipcode;
    }
);

export const cartProcessedSelector = createSelector(
    [
        cartSelector,
        accountPlansSelector,
        familyMembersPricingDataSelector,
        familyMembersEligibilitySelector,
        drugsWithDiscountSelector,
        cartOrderShippingAddressSelector,
        cartOrderBillShipSelector,
        cartOrderPaymentCardSelector
    ],
    (
        carts: CartPayload[] | undefined,
        familyPlanDetails: PlansObjectPayload[],
        familyPricingData: DependentsPricingData[],
        familyAutoRefillData: FamilyAutoRefillData[],
        drugDiscountPrices: DrugWithDiscountPrice[],
        cartAddress?: AddressPayload | null,
        OrderBillShip?: OrderBillShip,
        cartPaymentMethod?: CreditCardPayload
    ) => {
        if (!carts) return;
        return getCartObject(
            carts || [],
            familyPlanDetails,
            familyPricingData,
            familyAutoRefillData,
            drugDiscountPrices,
            cartAddress,
            OrderBillShip,
            cartPaymentMethod
        );
    }
);

export const cartItemsTotalSelector = createSelector(cartProcessedSelector, (cart) => cart?.cartItems || 0);

export const cartSubtotalSelector = createSelector(cartProcessedSelector, (cart) => cart?.subTotal);

export const lastOrderSelector = createSelector(CartSelector, (cart) => cart.lastOrder);

export const orderCompleteRxAutoRefillStatusSelector = createSelector(
    CartSelector,
    (cart) => cart.orderCompleteRxAutoRefillStatus
);
