import { REACT_APP_QNACLIENTFILTER, REGISTRATION_DOMAIN } from 'gatsby-env-variables';

import type { accountFinalizeUninsuredRequest, accountUninsuredData } from 'types/account';
import { PlansObjectPayload } from 'types/plans';

import { getMonthNum } from 'util/payments';

export enum TypePlan {
    // eslint-disable-next-line no-unused-vars
    Monthly = 0,
    // eslint-disable-next-line no-unused-vars
    Yearly = 1,
    // eslint-disable-next-line no-unused-vars
    Quarter = 2
}

export const getCardExpiration = (month: string, year: string) => {
    return `${getMonthNum(month)?.toString().padStart(2, '0')}/${year.toString()}`;
};

export function getTypePlanFromString(typePlanString: string): number {
    switch (typePlanString) {
        case 'Monthly':
            return TypePlan.Monthly;
        case 'Yearly':
            return TypePlan.Yearly;
        case 'Quarter':
            return TypePlan.Quarter;
        default:
            return TypePlan.Monthly;
    }
}

export const getMiddleNames = (names: []): string => {
    if (names.length < 2) {
        return '';
    } else {
        return names
            .map((name, index) => {
                return index !== 0 && index !== names.length - 1 ? name : '';
            })
            .join(' ');
    }
};

export function formatAccountFinalizeUninsuredData(
    formValues: accountUninsuredData,
    responseErrorPayload: any, // @TODO: Apply the correct type for the response error payload
    planAlias: string | null
): accountFinalizeUninsuredRequest {
    return {
        PlanAlias: planAlias || 'BRD01',
        CacheId: responseErrorPayload?.CacheId || '',
        CacheIdMembership: responseErrorPayload?.CacheIdMembership || '',
        PatientRegistration: {
            FirstName: formValues?.firstName,
            LastName: formValues?.lastName,
            MiddleName: responseErrorPayload?.PatientRegistration?.MiddleName || '',
            GenderCode: formValues?.gender,
            EmailAddress: formValues?.email || formValues?.username,
            BirthDate: `${formValues?.dobYear}-${formValues?.dobMonth}-${formValues?.dobDay
                .toString()
                .padStart(2, '0')}`,
            PhoneNumber: formValues?.phoneNumber,
            Address1: formValues?.address1,
            Address2: formValues?.address2,
            City: formValues?.city,
            StateCode: formValues?.state,
            ZipCode: formValues?.zipcode,
            Zip4: formValues?.zip4,
            RaceId: formValues?.race,
            EthnicityId: formValues?.ethnicity,
            AutoRefill: formValues?.autoRefill, // need to check
            PrimaryCareProvider: formValues?.careProvider,
            epostPatientNum: responseErrorPayload?.PatientRegistration?.epostPatientNum || -1,
            FamilyId: responseErrorPayload?.PatientRegistration?.FamilyId || -1,
            AccountId: responseErrorPayload?.PatientRegistration?.AccountId || -1,
            PatientStatusCode: responseErrorPayload?.PatientRegistration?.PatientStatusCode || null,
            PatientStatus: responseErrorPayload?.PatientRegistration?.PatientStatus || null,
            CardNumber: responseErrorPayload?.PatientRegistration?.CardNumber || null,
            CoverageTypeId: responseErrorPayload?.PatientRegistration?.CoverageTypeId || -1,
            CoverageType: responseErrorPayload?.PatientRegistration?.CoverageType || null,
            RelationshipNumber: responseErrorPayload?.PatientRegistration?.RelationshipNumber || -1,
            Relationship: responseErrorPayload?.PatientRegistration?.Relationship || null,
            Payor: responseErrorPayload?.PatientRegistration?.Payor || null,
            PayorNum: responseErrorPayload?.PatientRegistration?.PayorNum || null,
            PlanAlias: responseErrorPayload?.PatientRegistration?.PlanAlias || null,
            GroupId: responseErrorPayload?.PatientRegistration?.GroupId || null,
            GroupName: responseErrorPayload?.PatientRegistration?.GroupName || null,
            PhoneSeqNum: responseErrorPayload?.PatientRegistration?.PhoneSeqNum || -1,
            PhoneTypeNum: 1,
            PhoneType: responseErrorPayload?.PatientRegistration?.PhoneType || null,
            AreaCode: responseErrorPayload?.PatientRegistration?.AreaCode || null,
            Ext: responseErrorPayload?.PatientRegistration?.Ext || null,
            AddressSeqNumb: responseErrorPayload?.PatientRegistration?.AddressSeqNumb || -1,
            AddressTypeNum: 1,
            Address3: responseErrorPayload?.PatientRegistration?.Address3 || null,
            StateNumber: responseErrorPayload?.PatientRegistration?.StateNumber || null,
            Country: responseErrorPayload?.PatientRegistration?.Country || null,
            CountryNumber: formValues?.state === 'PR' ? 3 : 1,
            RecordLocation: responseErrorPayload?.PatientRegistration?.RecordLocation || null,
            HasUserProfile: responseErrorPayload?.PatientRegistration?.HasUserProfile || -1,
            UserName: responseErrorPayload?.PatientRegistration?.UserName || null,
            WebId: responseErrorPayload?.PatientRegistration?.WebId || null,
            IsMID: responseErrorPayload?.PatientRegistration?.IsMID || 0,
            CoverageStartDate: responseErrorPayload?.PatientRegistration?.CoverageStartDate || null,
            CoverageEndDate: responseErrorPayload?.PatientRegistration?.CoverageEndDate || null,
            ReadHIPAA: responseErrorPayload?.PatientRegistration?.ReadHIPAA || null,
            AcceptedHIPAA: responseErrorPayload?.PatientRegistration?.AcceptedHIPAA || null,
            CacheId: responseErrorPayload?.PatientRegistration?.CacheId || null,
            RegCode: responseErrorPayload?.PatientRegistration?.RegCode || null,
            IsMedicareMedicaid: false,
            messageErrorText: responseErrorPayload?.PatientRegistration?.messageErrorText || null,
            messageStatus: responseErrorPayload?.PatientRegistration?.messageStatus || true,
            messageText: responseErrorPayload?.PatientRegistration?.messageText || null
        },
        AccountSignup: {
            Email: formValues?.email || formValues?.username,
            UserName: formValues?.username,
            Password: formValues?.password
        },
        RetryError: responseErrorPayload?.RetryError || 0,
        messageErrorText: '',
        messageStatus: true,
        messageText: '',
        Payor: REACT_APP_QNACLIENTFILTER,
        Domain: REGISTRATION_DOMAIN
    };
}

export const findPrimaryPlanAlias = (plans: PlansObjectPayload[], epostPatientNum?: string): string => {
    const primaryPlan = plans.find(
        (plan) => plan.epostPatientNum === epostPatientNum && plan.coverageType === 'PRIMARY'
    );
    return primaryPlan ? primaryPlan.planAlias : '';
};
