import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';
import { WritableDraft } from 'immer/dist/internal';

import { drupalFetchCSRFRoutine, drupalMaintenanceRoutine } from 'state/drupal/drupal.routines';

import { MaintenanceType } from 'types/drupal';

export interface DrupalState {
    csrfToken: string | undefined;
    maintenance: {
        maintenanceMode: boolean;
        isChecked: boolean;
        maintenanceContent?: {
            body: string | null;
            eyebrow: string | null;
            subtitle: string | null;
            title: string | null;
        };
    };
}

export const initialState: DrupalState = {
    csrfToken: undefined,
    maintenance: {
        maintenanceMode: false,
        isChecked: false
    }
};

const drupalSlice = createSlice({
    name: 'drupal',
    initialState,
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(drupalFetchCSRFRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.csrfToken = payload;
            })
        );
        addCase(drupalFetchCSRFRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.csrfToken = undefined;
            })
        );
        addCase(drupalMaintenanceRoutine.TRIGGER, (state: WritableDraft<DrupalState>) =>
            produce(state, (draftState) => {
                // Mark as checked on trigger to avoid multiple calls to API
                draftState.maintenance.isChecked = true;
            })
        );
        addCase(
            drupalMaintenanceRoutine.SUCCESS,
            (state: WritableDraft<DrupalState>, { payload }: PayloadAction<MaintenanceType>) =>
                produce(state, (draftState) => {
                    draftState.maintenance.maintenanceMode = payload.maintenance_mode;
                    if (payload.maintenance_content) {
                        draftState.maintenance.maintenanceContent = payload.maintenance_content;
                    }
                })
        );
        addCase(drupalMaintenanceRoutine.FAILURE, (state: WritableDraft<DrupalState>) =>
            produce(state, (draftState) => {
                draftState.maintenance.maintenanceMode = false;
            })
        );
    }
});

export default drupalSlice.reducer;
