// General
import { useLocation } from '@reach/router';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Ui-kit
import Spinner from 'ui-kit/spinner/spinner';

// Components
import AcknowledgementModal from 'components/acknowledgement/acknowledgement-modal/acknowledgement-modal';
import AutoRefillModalContent from 'components/auto-refill-modal';
import { AutoRefillImage } from 'components/auto-refill-modal/auto-refill-modal';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import {
    accountFetchProfileRoutine,
    accountSetAcknowledgementRoutine,
    accountUpdateAutoRefill
} from 'state/account/account.routines';
import {
    accountAcknowledgementSelector,
    accountAutoRefillEligibleSelector,
    accountHasInsuranceSelector,
    accountHIPAASelector,
    accountIsCaliforniaUserSelector,
    accountIsLoggedInSelector,
    accountProfileApiStatusSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { AcknowledgementPayload } from 'state/account/account.services';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getCartRoutine } from 'state/cart/cart.routines';
import { cartApiStatusSelector, cartOrderPlacedSelector } from 'state/cart/cart.selectors';
import { familyProfileGetDependentsRoutine } from 'state/family-profile/family-profile.routines';
import { dependentsApiStatusSelector } from 'state/family-profile/family-profile.selectors';
import { medicineCabinetLoadRoutine } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { registrationContinueRegistrationRoutine } from 'state/registration/registration.routines';
import {
    registrationContinueRegistrationSelector,
    registrationIsLoadingSelector
} from 'state/registration/registration.selector';

// Const
import { getClientIp } from 'const/options';

// Utils
import { validateAutoRefillModalDisplay } from 'util/autorefill';
import { getPhoneNumber } from 'util/globalVariables';
import storageHelper from 'util/storageHelper';

// Hooks
import { useApiStatus } from 'hooks/useApiStatus';
import { useGlobalLink } from 'hooks/useGlobalLink';
import useSecurePage from 'hooks/useSecurePage';

export interface WithSecurityProps {
    isLoggedIn: boolean;
    isSecurePage: boolean;
}

interface TrackingPayload {
    event: string;
    logged_in: boolean;
    user_type?: 'funded' | 'cash-card' | null;
}

const withSecurity =
    <P extends object>(Component: React.ComponentType<P>) =>
    (props: P) => {
        // Hooks
        const location = useLocation();
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const { isSecurePage } = useSecurePage();
        const globalLink = useGlobalLink();
        const { handleSignOut } = globalLink;
        // Selectors
        const isLoggedIn = useSelector(accountIsLoggedInSelector);
        const profileObject = useSelector(accountProfileSelector);
        const { isIdle: isIdleProfile } = useApiStatus(accountProfileApiStatusSelector);
        const { isIdle: isIdleDependents } = useApiStatus(dependentsApiStatusSelector);
        const { isSuccess: isSuccessCart } = useApiStatus(cartApiStatusSelector);
        const accountHasInsurance = useSelector(accountHasInsuranceSelector);
        const accountAcknowledgement: AcknowledgementPayload | undefined = useSelector(accountAcknowledgementSelector);
        const accountHIPAA: boolean | undefined = useSelector(accountHIPAASelector);
        const isOrderPlaced = useSelector(cartOrderPlacedSelector);
        const isAutoRefillEligible = useSelector(accountAutoRefillEligibleSelector);
        const isCAResident = useSelector(accountIsCaliforniaUserSelector);
        const isLoadingRegistration = useSelector(registrationIsLoadingSelector);
        const continueRegistrationResponseState = useSelector(registrationContinueRegistrationSelector);

        const { isRegistrationCompleted, messageErrorText: completeRegistrationError } =
            continueRegistrationResponseState;

        const handleToggleAutoRefill = () => {
            dispatch(
                accountUpdateAutoRefill.trigger({
                    epostPatientNumber: profileObject?.epostPatientNum,
                    autoRefillFlag: !profileObject?.autoRefill,
                    onSuccess: () => {
                        closeModalHandler();
                        dispatch(accountFetchProfileRoutine.trigger());

                        // only display success modal for toggling OFF auto refill
                        if (!profileObject?.autoRefill) {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <BirdiModalContent
                                            title={t('pages.profile.autoRefill.successModal')}
                                            body={
                                                <div className="success-content-container">
                                                    <p className="success-content-container-subtitle">
                                                        {t('pages.profile.autoRefill.successToggleTitle')}
                                                    </p>
                                                </div>
                                            }
                                            icon={'default'}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('pages.profile.autoRefill.successDoneBtn'),
                                            variant: 'primary',
                                            className: 'auto-refill-success-btn',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            },
                                            dataGALocation: 'AutoRefillToggleModalSuccess'
                                        }
                                    ]
                                })
                            );
                        }
                    },
                    onFailure: (error: any) => {
                        // DRX-4428: Only closes the modal without setting any local flag, since it is a failure scenario
                        dispatch(closeModalComponent());

                        dispatch(
                            openModal({
                                showClose: false,
                                type: 'danger',
                                size: 'lg',
                                onClose: () => dispatch(closeModal({})),
                                headerContent: (
                                    <BirdiModalHeaderDanger
                                        headerText={t('components.membershipModals.errorTitle')}
                                        icon="alert"
                                    />
                                ),
                                bodyContent: (
                                    <BirdiModalContentAlt
                                        subTitle={t(`pages.medicineCabinet.messages.callbacks.autoRefillErrorMessage`)}
                                        note={t(`pages.medicineCabinet.messages.callbacks.errorMessageNote`, {
                                            phoneNumber: getPhoneNumber({ isEnd: true })
                                        })}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        };

        const closeModalHandler = () => {
            dispatch(closeModalComponent());
            storageHelper.local.setAutoRefillToggleFlag();
        };

        const dispatchAutoRefillModal = () =>
            dispatch(
                openModalComponent({
                    title: t('pages.autoRefill.userVerification.title'),
                    isCloseable: true,
                    hasDefaultFooter: false,
                    hasModalHeader: true,
                    variation: 'side-view',
                    isCentered: true,
                    hasCustomContent: false,
                    sideView: <AutoRefillImage />,
                    content: <AutoRefillModalContent onClose={closeModalHandler} onContinue={handleToggleAutoRefill} />,
                    onClose: closeModalHandler
                })
            );

        const pageData = useStaticQuery(graphql`
            query {
                termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
                    nodes {
                        drupal_internal__vid
                    }
                }
            }
        `);

        const currentVID = pageData?.termsOfService ? pageData?.termsOfService.nodes[0]?.drupal_internal__vid / 100 : 0;

        const showAcknowledgmentModal = (acceptPayload: unknown) => {
            const shouldDisplayAutoRefillModal = validateAutoRefillModalDisplay(isAutoRefillEligible, isCAResident);

            dispatch(
                openModal({
                    className: 'acknowledgement-modal-content',
                    showClose: false,
                    bodyContent: <AcknowledgementModal />,
                    backdrop: 'static',
                    keyboard: false, // disable "ESC" close function
                    ctas: [
                        {
                            async: true,
                            label: t('components.acknowledgement.labels.agree'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(setBusyModal(true));
                                dispatch(
                                    accountSetAcknowledgementRoutine.trigger({
                                        data: acceptPayload,
                                        onSuccess: () => {
                                            dispatch(setBusyModal(false));
                                            dispatch(closeModal({}));
                                            if (shouldDisplayAutoRefillModal) {
                                                dispatchAutoRefillModal();
                                            }
                                        },
                                        onError: () => {
                                            dispatch(
                                                openModal({
                                                    showClose: false,
                                                    type: 'danger',
                                                    size: 'lg',
                                                    backdrop: 'static',
                                                    keyboard: false, // disable "ESC" close function
                                                    onClose: () => {
                                                        handleSignOut();
                                                        dispatch(closeModal({}));
                                                    },
                                                    headerContent: (
                                                        <BirdiModalHeaderDanger
                                                            headerText={t('components.membershipModals.errorTitle')}
                                                            icon="alert"
                                                        />
                                                    ),
                                                    bodyContent: (
                                                        <BirdiModalContentAlt
                                                            subTitle={t('registration.errors.modals.default.body')}
                                                        />
                                                    ),
                                                    ctas: []
                                                })
                                            );
                                        }
                                    })
                                );
                            },
                            dataGALocation: 'AcknowledgementModal'
                        },
                        {
                            label: t('components.acknowledgement.labels.decline'),
                            variant: 'text',
                            onClick: () => {
                                handleSignOut();
                                dispatch(closeModal({}));
                            },
                            dataGALocation: 'AcknowledgementModal'
                        }
                    ]
                })
            );
        };

        /**
         * Since this HOC works as a decorator encompassing all security pages, the API call should only
         * be made here to prevent redundancy and multiple calls to the Profile API. Therefore, after
         * profile verification, we transfer the authentication page logic to this HOC to separate
         * responsibilities and remove the firstLogin prop from local storage.
         */
        useEffect(() => {
            if (isLoggedIn && isRegistrationCompleted === false) return;

            if (isLoggedIn && isIdleProfile && profileObject === undefined && isRegistrationCompleted === true) {
                dispatch(
                    accountFetchProfileRoutine.trigger({
                        onSuccess: () => {
                            dispatch(medicineCabinetLoadRoutine.trigger({ fetchRxSubStatus: true }));
                        }
                    })
                );
            }
        }, [dispatch, isLoggedIn, isIdleProfile, profileObject, isRegistrationCompleted]);

        useEffect(() => {
            if (typeof window !== 'undefined' && window.dataLayer) {
                const trackingPayload: TrackingPayload = {
                    event: 'pageview',
                    logged_in: isLoggedIn
                };

                if (!isLoggedIn) {
                    window.dataLayer.push(trackingPayload);
                }
                if (isLoggedIn && profileObject !== undefined) {
                    trackingPayload.user_type = isLoggedIn ? (accountHasInsurance ? 'funded' : 'cash-card') : null;
                    window.dataLayer.push(trackingPayload);
                }
            }
        }, [isLoggedIn, accountHasInsurance, profileObject]);

        // Since we've encapsulated the pages with this higher-order component,
        // we've shifted the API call logic to execute just once upon user login.
        // This approach helps us prevent redundant API calls within the components themselves.
        useEffect(() => {
            if (profileObject?.isCaregiver && isIdleDependents) {
                dispatch(familyProfileGetDependentsRoutine.trigger());
            }
        }, [dispatch, profileObject, isIdleDependents]);

        useEffect(() => {
            if (profileObject !== undefined && isSuccessCart && !isOrderPlaced) {
                dispatch(getCartRoutine.trigger());
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [dispatch, profileObject, isOrderPlaced]);

        /**
         * useEffect responsible for displaying the acknowledgment modal when necessary.
         *
         * This effect runs whenever the dependencies [isLoggedIn, profileObject, accountAcknowledgement, accountHIPAA] change.
         * It checks if the user is logged in, if profile information is available, and if account acknowledgments have been completed.
         * If any of these conditions are not met, the acknowledgment modal will be displayed.
         *
         * @note If `getClientIp()` fails, the IP will default to '0.0.0.0'.
         */
        useEffect(() => {
            if (isLoggedIn && profileObject && accountAcknowledgement !== undefined && accountHIPAA !== undefined) {
                let acknowledgementVersion = 0;
                if (accountAcknowledgement.Acknowledgements && accountAcknowledgement.Acknowledgements.length > 0) {
                    acknowledgementVersion = accountAcknowledgement.Acknowledgements[0].Version
                        ? accountAcknowledgement.Acknowledgements[0].Version
                        : 0;
                }
                const acceptedHIPAA = accountHIPAA ? accountHIPAA : false;
                if (!acknowledgementVersion || currentVID > acknowledgementVersion || !acceptedHIPAA) {
                    getClientIp()
                        .catch(() => null) // Returns `null` to avoid execution problems.
                        .then((res) => {
                            showAcknowledgmentModal({
                                patientId: profileObject.epostPatientNum,
                                clientIP: res?.toString() || '0.0.0.0', // Uses 0.0.0.0 as fallback in case the IP can't be found.
                                currentVID: currentVID > acknowledgementVersion ? currentVID : undefined
                            });
                        });
                }
            }
        }, [isLoggedIn, profileObject, accountAcknowledgement, accountHIPAA]); // eslint-disable-line react-hooks/exhaustive-deps

        /**
         * Handles registration logic when the user's logged-in state changes.
         *
         * - If the registration process is currently loading (`isLoadingRegistration`), it skips execution.
         * - If a registration completion error (`completeRegistrationError`) exists, it triggers a sign-out.
         * - If the user's registration state is undefined (`isRegistrationCompleted`),
         *   it re-triggers the registration continuation routine.
         *
         * Dependencies:
         * - `isLoggedIn`: Checks if the user is logged in.
         * - `isLoadingRegistration`: Ensures no duplicate registration attempts are triggered during loading.
         * - `isRegistrationCompleted`: Identifies if the registration status needs to be updated.
         * - `completeRegistrationError`: Handles errors related to completing registration.
         */
        useEffect(() => {
            if (!isLoggedIn) return;

            // Skip if the registration process is still loading.
            if (isLoadingRegistration) return;

            // Handle registration error by signing out the user.
            if (completeRegistrationError !== undefined) {
                handleSignOut();
                return;
            }

            // Trigger registration routine if the registration status is undefined.
            const shouldTriggerRegistration = isLoggedIn && isRegistrationCompleted === undefined;

            if (shouldTriggerRegistration) {
                dispatch(registrationContinueRegistrationRoutine.trigger());
            }
        }, [isLoggedIn, isLoadingRegistration, isRegistrationCompleted, completeRegistrationError]);

        /**
         * Determines whether the user should be redirected to the onboarding page.
         *
         * Conditions for redirection:
         * - The current page is marked as "secure" (`isSecurePage`).
         * - The user is logged in (`isLoggedIn`).
         * - The user's profile object (`profileObject`) has not been loaded or is undefined.
         * - Registration has started but is not yet completed (`isRegistrationCompleted === false`).
         * - The current path is NOT part of the onboarding flow (any subpath of `/secure/onboarding`).
         * @returns {boolean}
         */
        const shouldRedirectToOnboardingPage = useMemo(() => {
            // Checks if the current path is part of the onboarding flow.
            const isOnboardingPath = /^\/secure\/onboarding(\/|$)/.test(location.pathname);

            const isEligibleForRedirection = isSecurePage && isLoggedIn && !isOnboardingPath;
            const needsProfileCompletion = profileObject === undefined && isRegistrationCompleted === false;

            return isEligibleForRedirection && needsProfileCompletion;
        }, [location, isLoggedIn, isSecurePage, isLoadingRegistration, profileObject, isRegistrationCompleted]);

        if (isSecurePage && !isLoggedIn && typeof window !== 'undefined') {
            // Check if dataLayer exists
            const shouldRedirect = window.dataLayer?.some(
                (logout) => logout.event === 'logout' && logout.loginStatus === 'logged out'
            );

            if (shouldRedirect) {
                // Redirect based on logout event
                navigate('/sign-in');
            } else {
                // Default redirect with query params
                navigate('/sign-in?redirect=' + location.pathname + encodeURIComponent(location.search));
            }

            return null;
        } else if (shouldRedirectToOnboardingPage) {
            navigate('/secure/onboarding');
            return null;
        } else {
            const hideSpinner: boolean =
                !isSecurePage ||
                (isSecurePage && isLoggedIn && isRegistrationCompleted === false) ||
                (isSecurePage && isLoggedIn && profileObject !== undefined);
            return (
                <>
                    <Spinner isVisible={!hideSpinner} t={t} />
                    <Component {...props} isSecurePage={isSecurePage} isLoggedIn={isLoggedIn} />
                </>
            );
        }
    };

export default withSecurity;
