import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import { Allergies, MedicalConditions } from 'types/health-profile';

import axiosClient from 'util/axiosClient';
import { threatHealthConditionsPayload } from 'util/medical-conditions';

import { AllergiesResponse, MedicalConditionsResponse } from './medical-conditions.reducers';

export default class MedicalConditionsService {
    static medicalConditions() {
        return {
            get: async (payload: any): Promise<MedicalConditionsResponse> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/v3/MedicalConditions/${payload.epostPatientNumFamily}`
                );
                return response.data;
            },
            addOrUpdate: async (payload: MedicalConditions): Promise<MedicalConditions[]> => {
                const { TemporaryOther, ...updatedPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(updatedPayload as MedicalConditions);
                const request = {
                    ...threatedPayload,
                    messageErrorText: '',
                    messageStatus: false,
                    messageText: ''
                };
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/v3/MedicalConditions/Update`,
                    request
                );
                return response.data;
            }
        };
    }
    static allergies() {
        return {
            get: async (payload: any): Promise<AllergiesResponse> => {
                const response = await axiosClient.get(
                    `${GATSBY_API_BASE_URL}/api/v3/Allergies/${payload.epostPatientNumFamily}`
                );
                return response.data;
            },
            addOrUpdate: async (payload: Allergies): Promise<Allergies[]> => {
                const { TemporaryOther, ...updatedPayload } = payload;
                const threatedPayload = threatHealthConditionsPayload(updatedPayload as Allergies);
                const request = {
                    ...threatedPayload,
                    messageErrorText: '',
                    messageStatus: false,
                    messageText: ''
                };
                const response = await axiosClient.post(`${GATSBY_API_BASE_URL}/api/v3/Allergies/Update`, request);
                return response.data;
            }
        };
    }
}
