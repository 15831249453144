import { TFunction } from 'react-i18next';

import { CartOrderRx, CartPatient } from 'types/cart';

const CURRENCY = 'USD';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export type ViewItemType = {
    rxNumber: string;
    name: string;
    variant: string;
    price: number;
};

export type FormTransferObjectType = {
    flowName: string;
    accountHasInsurance?: boolean;
    prescriptionName?: string;
    form?: string;
    strength?: string;
    quantity?: string;
};

export interface FlowAbandonmentType {
    flowName: string;
    formName: string;
    stepName: string;
    lastFormField: string;
}

export function InitOptimize() {
    // ININITALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate' - needs to go on each layout
    if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'optimize.activate' });
        window.document.documentElement.classList.remove('async-hide');
    }
}

function getFormName(form: HTMLElement): string {
    let fName = 'unknown-form';
    if (form) {
        if (form.getAttribute('data-ga-form-name')) {
            fName = form.getAttribute('data-ga-form-name') || 'unknown-form';
        } else {
            if (form.id) {
                fName = form.id;
            }
        }
    }
    return fName;
}

function getFormStepName(form: HTMLElement): string {
    let formStepName = 'OnePageForm';
    if (form) {
        let formSteps = form.querySelector('.workflow-steps');
        if (!formSteps) {
            const workflowWrapper = form.closest('[class*="layout-form-wrapper-content"]');
            if (workflowWrapper) {
                formSteps = workflowWrapper.querySelector('.workflow-steps');
            }
        }
        if (formSteps && formSteps.getAttribute('data-ga-form-stepname')) {
            formStepName = formSteps.getAttribute('data-ga-form-stepname') || 'OnePageForm';
        }
    }
    return formStepName;
}

function getTargetForm(t: HTMLElement): any {
    let form = t.closest('form');
    if (!form) {
        form = t.closest('[data-ga-form-name]');
    }
    if (form) {
        return form;
    }
}

export function TrackInputFocus(eventTarget: HTMLElement, label?: string | object) {
    if (eventTarget && typeof window !== 'undefined' && window.dataLayer) {
        const form = getTargetForm(eventTarget);
        const formName = getFormName(form);
        const formStepName = getFormStepName(form);

        const { name = 'unknown' } = eventTarget;

        window.dataLayer.push({
            event: 'inputFocus',
            'form-field': label ? label.toString().replace(/ /g, '-') : name,
            'form-stepName': formStepName,
            'form-name': formName
        });
    }
}

export function TrackEvent(eventName: string, eventValue: string) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({ event: eventName, value: eventValue });
    }
}

export function TrackGenericEvent(eventObject: { event: string | null; eventName: string | null }) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        if (!eventObject.event && eventObject.eventName) {
            eventObject.event = eventObject.eventName;
            eventObject.eventName = null;
        }
        window.dataLayer.push(eventObject);
    }
}

export function TrackInputError(
    eventTarget: HTMLElement | null,
    label: string | object | undefined,
    errorText: string,
    formName?: string
) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        if (!formName && eventTarget) {
            const form = getTargetForm(eventTarget);
            formName = getFormName(form);
        }
        let fieldName = 'unknown';

        if (label) {
            if (typeof label === 'object') {
                if (label.props && label.props.i18nKey) {
                    fieldName = label.props.i18nKey;
                }
            } else {
                fieldName = label.toString().replace(/ /g, '-');
            }
        }

        window.dataLayer.push({
            event: 'inputError',
            form_name: formName,
            field_name: fieldName,
            error_message_text: errorText
        });
    }
}

export function TrackFormSuccess(formName: string) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: 'formSubmitSuccess',
            'form-name': formName
        });
    }
}

export function TrackTransferObjectFlowComplete(form: FormTransferObjectType) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: 'flow_complete',
            flow_name: form.flowName,
            have_insurance: form.accountHasInsurance,
            form: form.form,
            prescription_name: form.prescriptionName,
            strength: form.strength,
            flow_quantity: form.quantity
        });
    }
}

export function TrackFlowComplete(flowName: string) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: 'flow_complete',
            flow_name: flowName
        });
    }
}

export function TrackError(location: string, func: string, error: object | string) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        const hasError = !!error;
        const isString = hasError && typeof error === 'string';
        const hasToString = hasError && typeof error?.toString === 'function';
        const errorMessage = isString ? error : hasToString ? error.toString() : 'unspecified error';

        window.dataLayer.push({
            event: 'Error',
            location: location,
            function: func,
            errorMessage
        });
    }
}

export function TrackVirtualPageView(eventType: string, flowType: string, pagePath: string) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: eventType,
            addType: flowType,
            vpvURL: pagePath
        });
    }
}

export function TrackCheckoutStep({
    stepName,
    step,
    carts,
    prescriptions,
    shippingCost,
    t,
    accountHasInsurance
}: {
    stepName: 'purchase' | 'checkout' | 'add' | 'remove';
    step: string;
    carts: CartPatient[];
    prescriptions: CartOrderRx[];
    shippingCost: string;
    t: TFunction<'translation'>;
    accountHasInsurance: boolean;
}) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        carts?.forEach((cart) => {
            if (cart && cart.cartRxs && cart.cartRxs.length > 0) {
                const cartProducts: any[] = [];
                cart.cartRxs?.forEach((lineItem) => {
                    const currentRx = prescriptions?.find((obj) => {
                        return obj.rxDetails.rxNumber === lineItem.rxDetails.rxNumber;
                    });
                    if (currentRx) {
                        cartProducts.push({
                            name: currentRx.rxDetails?.prescriptionDetail?.dispensedProductName,
                            price: lineItem.cartPrice?.showBirdiCashPrice
                                ? lineItem.cartPrice.birdiPrice
                                    ? lineItem.cartPrice.birdiPrice
                                    : 0
                                : lineItem.cartPrice?.patientCopay
                                ? lineItem.cartPrice?.patientCopay
                                : 0,
                            brand: currentRx.rxDetails?.prescriptionDetail?.dispensedDrugMaker,
                            variant: `${t('pages.cart.quantity', {
                                fillQuantity: currentRx.rxDetails?.prescriptionDetail?.fillQuantity
                            })} ${currentRx.rxDetails?.prescriptionDetail?.dispensedProductStrength}${
                                currentRx.rxDetails?.prescriptionDetail?.dispensedProductUnitOfMeasure
                            } ${currentRx.rxDetails?.prescriptionDetail?.dispensedProductDosageForm}`,
                            quantity: 1,
                            metric3: lineItem.cartPrice?.hasAdjudicatedPrice ? 1 : 0, // Adjudicated = 1, not adjudicated = 0
                            dimension6: lineItem.rxDetails?.rxLineError,
                            dimension16: accountHasInsurance ? lineItem.cartPrice?.showBirdiCashPrice : true
                        });
                    }
                });

                let eventType = '';
                switch (stepName) {
                    case 'add':
                        eventType = 'EEaddToCart';
                        break;
                    case 'remove':
                        eventType = 'EEremovefromcart';
                        break;
                    case 'purchase':
                        eventType = 'Purchase';
                        break;
                    default:
                        eventType = 'EEcheckout';
                        break;
                }
                const action =
                    stepName === 'purchase'
                        ? {
                              id: cart.order?.orderHeader?.orderInvoiceNumber,
                              revenue: cart?.orderTotal + shippingCost,
                              tax: cart?.order.orderHeader?.orderTax,
                              shipping: shippingCost
                          }
                        : { step: step };

                if (cartProducts.length > 0) {
                    window.dataLayer.push({
                        event: eventType,
                        ecommerce: {
                            [stepName]:
                                eventType === 'EEcheckout' || eventType === 'Purchase'
                                    ? {
                                          actionField: action,
                                          products: cartProducts
                                      }
                                    : {
                                          products: cartProducts
                                      }
                        }
                    });
                }
            }
        });
    }
}

export function TrackNewPrescriptionNumber(eventTarget: HTMLElement, dataGAPrescriptionNumber: number) {
    const button = eventTarget.closest('button');

    if (!button) return;

    const gaLocation = button.getAttribute('data-ga-location');

    if (dataGAPrescriptionNumber && gaLocation && typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: 'cta_click',
            click_location: gaLocation,
            prescription_number: dataGAPrescriptionNumber
        });
    }
}

export function TrackViewItem(item: ViewItemType) {
    if (typeof window !== 'undefined' && window.dataLayer && item) {
        window.dataLayer.push({
            event: 'view_item',
            currency: CURRENCY,
            value: item.price,
            items: [
                {
                    item_id: item.rxNumber,
                    item_name: item.name,
                    item_variant: item.variant, //fill quantity
                    currency: CURRENCY,
                    price: item.price
                }
            ]
        });
    }
}

export function TrackSignupFlowInteraction(linkText: string) {
    if (typeof window !== 'undefined' && window.dataLayer && linkText) {
        window.dataLayer.push({
            event: 'signup_flow_interaction',
            link_text: linkText
        });
    }
}

export const TrackFlowAbandonment = (item: FlowAbandonmentType) => {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({
            event: 'flow_abandonment',
            flow_name: item.flowName,
            form_name: item.formName,
            step_name: item.stepName,
            last_form_field: item.lastFormField
        });
    }
};
