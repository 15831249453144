import { MembershipPlanId } from 'state/membership/membership.selector';

import { PlansObjectPayload } from 'types/plans';

import { BIRDI_PLANS } from 'enums/plans';

import { doesPlanAllowsPricing } from './pricing';

export const isOnDemandPlan = (planType: number | undefined) => {
    return planType === MembershipPlanId.ON_DEMAND || planType === MembershipPlanId.NONE;
};

export function getBirdiCashPricePlanNum(plans: PlansObjectPayload[] | PlansObjectPayload[] | undefined) {
    if (plans) {
        const matchingPlan = plans.find((plan) => !plan.planExpired && plan.planAlias !== BIRDI_PLANS.BRD_01);
        if (matchingPlan) {
            return matchingPlan.epostPlanNum;
        }
    }
    return undefined;
}

export const isPlanInsurance = (planAlias?: BIRDI_PLANS) => !doesPlanAllowsPricing(planAlias);
