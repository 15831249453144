import { MembershipPlanId } from 'state/membership/membership.selector';

import { GetEasyAutoRefillPatientDataResponse } from 'types/auto-refill';
import { GetEasyRefillPatientDataResponse } from 'types/easy-refill';
import { DependentsPricingData, FamilyAutoRefillData } from 'types/family-account';

import { EASY_REFILL_MEMBERSHIP_PLAN_TYPES } from 'enums/easy-refill';
import { BIRDI_PLANS } from 'enums/plans';

import { isOnDemandPlan } from 'util/plans';

import { lowercaseAndCapitalize } from './string';

const mapFamilyMembers = (familyMembers: GetEasyRefillPatientDataResponse[], willUseEposPatienNum: boolean) => {
    return familyMembers.map(({ epostPatientNum, firstName, lastName }) => {
        const patientName = `${firstName} + ${lastName}`;

        return {
            key: patientName,
            label: lowercaseAndCapitalize(patientName),
            value: willUseEposPatienNum
                ? epostPatientNum
                : [...lowercaseAndCapitalize(patientName).split(' '), epostPatientNum]
        };
    });
};

export const mapEasyRefillFamilyMembers = (
    familyMembers: GetEasyRefillPatientDataResponse[],
    willUseEposPatienNum: boolean
) => {
    const dependents = familyMembers.length > 0 ? mapFamilyMembers(familyMembers, willUseEposPatienNum) : [];

    return [...dependents];
};

export const mapEasyRefillFamilyMemberPlanType = (
    planType: EASY_REFILL_MEMBERSHIP_PLAN_TYPES | undefined
): MembershipPlanId => {
    if (!planType) return MembershipPlanId.NONE;

    const enumKey = Object.keys(EASY_REFILL_MEMBERSHIP_PLAN_TYPES).find(
        (key: string) => (EASY_REFILL_MEMBERSHIP_PLAN_TYPES as any)[key] === planType
    );
    return enumKey ? (MembershipPlanId as any)[enumKey] : MembershipPlanId.NONE;
};

export const mapEasyRefillFamilyPricingData = ({
    members,
    mainUserPlanAlias,
    mainUserEpostPatientNum,
    mainUserPlanType = MembershipPlanId.NONE,
    mainUserZipCode
}: {
    members: GetEasyAutoRefillPatientDataResponse[] | GetEasyRefillPatientDataResponse[];
    mainUserPlanAlias: BIRDI_PLANS;
    mainUserEpostPatientNum: string;
    mainUserPlanType?: MembershipPlanId;
    mainUserZipCode?: string;
}): DependentsPricingData[] => {
    if (!mainUserZipCode) return [];

    const accountPricingData: DependentsPricingData = {
        planAlias: mainUserPlanAlias,
        zipCode: mainUserZipCode,
        planType: mainUserPlanType,
        epostPatientNum: mainUserEpostPatientNum,
        isOnDemandPlan: isOnDemandPlan(mainUserPlanType)
    };
    if (members.length > 0) {
        return members.map((member: GetEasyRefillPatientDataResponse) => {
            const { planAlias, zipCode, planType, epostPatientNum } = member;
            const planTypeMapped = mapEasyRefillFamilyMemberPlanType(planType);
            return {
                planAlias: planAlias,
                zipCode,
                planType: planTypeMapped,
                epostPatientNum,
                isOnDemandPlan: isOnDemandPlan(planTypeMapped)
            };
        });
    } else {
        return [accountPricingData];
    }
};

export const mapEasyRefillFamilyAutoRefillData = (
    members: GetEasyAutoRefillPatientDataResponse[] | GetEasyRefillPatientDataResponse[]
): FamilyAutoRefillData[] =>
    members.map((member) => ({
        epostPatientNum: member.epostPatientNum,
        planEligibleForAutoRefill: member.patientAutoRefill ? member.patientAutoRefill : false
    }));
