import { WritableDraft } from 'immer/dist/internal';

import { AddressPayload } from 'types/account';
import { CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { DEFAULT_SHIPPING_ID } from 'util/cart';

import { cartState } from './cart.reducers';

/**
 * Returns the main cart from a list of carts based on the EpostPatientNum.
 *
 * @param {Array<cartState['cart']>} carts - The list of carts to search through.
 * @param {string | undefined} EpostPatientNum - The EpostPatientNum to match against.
 * @return {cartState['cart'] | undefined} The main cart that matches the EpostPatientNum, or undefined if no match is found.
 */
export const getPatientCart = (
    carts: CartPayload[] | undefined,
    epostPatientNum: string | undefined
): CartPayload | undefined => {
    if (!carts || !epostPatientNum) return;

    return carts?.find((cart) => epostPatientNum === cart.EpostPatientNum);
};

export const getAllEpostsCarts = (carts: cartState['cart'], eposts: string[]) => {
    return carts?.filter((cart) => {
        return cart.EpostPatientNum ? eposts.includes(cart.EpostPatientNum) : false;
    });
};

export const getCurrentCardOrderBillship = (carts: CartPayload[] | undefined): OrderBillShip | undefined => {
    return carts?.find(
        (cart) => !!cart.Order.orderBillShip.patientBillAddressSeq || !!cart.Order.orderBillShip.dependentAddressSeqNum
    )?.Order.orderBillShip;
};

export const getCurrentCartAddress = (
    carts: CartPayload[],
    addresses: AddressPayload[]
): AddressPayload | null | undefined => {
    const billShipAddress = getCurrentCardOrderBillship(carts);
    return addresses.find(
        (address) =>
            address.addressSeqNum === billShipAddress?.patientShipAddressSeq ||
            address.addressSeqNum === billShipAddress?.dependentAddressSeqNum
    );
};

export const getCurrentCartCard = (
    carts: CartPayload[],
    creditCards: CreditCardPayload[] | CreditCardPayload[]
): CreditCardPayload | undefined => {
    const cartCard = carts?.find(
        (cart: CartPayload) =>
            !!cart.Order?.orderBillShip.dependentPaymentSeqNum || !!cart.Order?.orderBillShip.paymentCardSeqNum
    );

    if (!cartCard) return;
    return creditCards.find(
        (card) =>
            cartCard.Order.orderBillShip.dependentPaymentSeqNum === String(card.cardSeqNum) ||
            cartCard.Order.orderBillShip.paymentCardSeqNum === String(card.cardSeqNum)
    );
};

export const createRxObject = (rxNumber: string, currentPrescription?: RxDetails): RefillRxs => {
    return {
        rxNumber,
        lastRefillScriptId: String(currentPrescription?.prevScriptId),
        originationNum: '6',
        messageText: '',
        messageStatus: true,
        rxLineError: 0,
        lineTotal: null,
        fillNote: '',
        epostRxScriptId: '',
        orderNum: '',
        patientCopay: null,
        planNum: '',
        insurancePayment: null,
        messageErrorText: ''
    };
};

export const createOrder = (
    draftState: WritableDraft<CartPayload>,
    cart: CartPayload,
    orderBillShip: OrderBillShip,
    rxsToOrder: RefillRxs[],
    orderDate: string
) => {
    const isFirstRxInCart = cart?.Order.refillRxs.length === 0;

    return {
        ...draftState.Order,
        orderBillShip,
        doNotSubmitToWorkflow: true,

        orderHeader: {
            ...draftState.Order.orderHeader,
            forceReview: false,
            locationId: cart?.Order.orderHeader.locationId,
            orderDate,
            orderHighPriority: false,
            orderStatusNum: isFirstRxInCart ? null : draftState.Order?.orderHeader?.orderStatusNum,
            originationNum: '6',
            workflowTypeNum: '3',
            orderInvoiceNumber: isFirstRxInCart ? null : draftState.Order?.orderHeader?.orderInvoiceNumber,
            orderNum: isFirstRxInCart ? null : draftState.Order?.orderHeader?.orderNum
        },
        originationNum: '6',
        cartId: (draftState.Order.cartId = isFirstRxInCart ? null : draftState.Order?.cartId),
        refillRxs: [...rxsToOrder],

        // DRX-3081; Reset the message* fields on the order, this is needed when attempting to add multiple RXs to the order
        messageErrorText: '',
        messageStatus: true,
        messageText: ''
    };
};

export const getCartShippingMethodId = (carts: CartPayload[] | undefined): string => {
    return (
        carts?.find((cart) => cart.Order.orderBillShip.shipMethodId !== null)?.Order?.orderBillShip?.shipMethodId ||
        DEFAULT_SHIPPING_ID
    );
};
