import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AddressPayload } from 'types/account';
import { CartObject } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { DependentsPricingData } from 'types/family-account';
import { Order } from 'types/order-prescription';

export interface CartOrderSnapshot {
    cart: Order;
    shippingAddress: AddressPayload;
    paymentCard: CreditCardPayload;
}

export interface cartState {
    familyPricingData: DependentsPricingData[];
    cart: CartObject | undefined;
    cartZipCode: string | undefined;
}

export const initialState: cartState = {
    familyPricingData: [],
    cart: undefined,
    cartZipCode: undefined
};

const cacheSlice = createSlice({
    name: 'cache',
    initialState,
    reducers: {
        setFamilyPricingDataCache(state, action: PayloadAction<DependentsPricingData[]>) {
            state.familyPricingData = action.payload;
        },
        setCartCache(state, action: PayloadAction<CartObject | undefined>) {
            state.cart = action.payload;
        },
        setCartZipCodeCache(state, action: PayloadAction<string | undefined>) {
            state.cartZipCode = action.payload;
        },
        clearCache(state) {
            state.familyPricingData = initialState.familyPricingData;
        }
    }
});

export const { setFamilyPricingDataCache, setCartCache, setCartZipCodeCache, clearCache } = cacheSlice.actions;

export default cacheSlice.reducer;
