import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { RxDetails } from 'types/prescription';

import { isRxOrderedInProgress } from 'util/prescription';

import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetLoadRoutine,
    medicineCabinetToggleAutoRefillForRx
} from './medicine-cabinet.routines';
import { RxStatusResponse } from './medicine-cabinet.services';

export interface MedicineCabinetState {
    error?: string;
    isBusy?: boolean;
    currentPrescriptions?: RxDetails[];
    medicineCabinetLoaded: boolean;
    showNewPrescriptionModal: {
        isRxLoaded: boolean;
        prescriptions: {
            rxNumber: string;
            prescriptionName: string;
            alreadyDisplayedInModal: boolean;
            epostPatientNum: string;
        }[];
    };
    autoRefillToggleBusy: boolean;
    medicineCabinetActiveTab?: string;
    medicineCabinetFilterTab?: string;
    medicineCabinetIsLoading: boolean;
    medicineCabinetHasError?: boolean;
    cachedSubStatuses: {
        [key: string]: RxStatusResponse;
    };
    shouldRefresh?: boolean;
}

export const initialState: MedicineCabinetState = {
    currentPrescriptions: [],
    medicineCabinetLoaded: false,
    showNewPrescriptionModal: {
        isRxLoaded: false,
        prescriptions: []
    },
    autoRefillToggleBusy: false,
    medicineCabinetActiveTab: '',
    medicineCabinetFilterTab: 'all',
    medicineCabinetIsLoading: false,
    medicineCabinetHasError: false,
    cachedSubStatuses: {},
    shouldRefresh: false
};

const medicineCabinetSlice = createSlice({
    name: 'medicine-cabinet',
    initialState,
    reducers: {
        setMedicineCabinet(state) {
            state.error = undefined;
            state.isBusy = false;
        },
        showNewPrescriptionModal(state, { payload }: PayloadAction<MedicineCabinetState['showNewPrescriptionModal']>) {
            state.showNewPrescriptionModal = payload;
            state.showNewPrescriptionModal.isRxLoaded = payload.isRxLoaded;
        },
        setMedicineCabinetActiveTab(state, { payload }: PayloadAction<string>) {
            state.medicineCabinetActiveTab = payload;
        },
        setMedicineCabinetFilterTab(state, { payload }: PayloadAction<string>) {
            state.medicineCabinetFilterTab = payload;
        },
        setMedicineCabinetIsBusy(state, { payload }: PayloadAction<boolean>) {
            state.isBusy = payload;
        },
        setMedicineCabinetShouldRefresh(state, { payload }: PayloadAction<boolean>) {
            state.shouldRefresh = payload;
        },
        resetMedicineCabinetCachedSubStatus(state) {
            state.cachedSubStatuses = {};
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Prescriptions Reducers
         */
        addCase(medicineCabinetGetAllPrescriptions.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.medicineCabinetLoaded = initialState.medicineCabinetLoaded;
                draftState.medicineCabinetIsLoading = true;
                draftState.medicineCabinetHasError = false;
            })
        );
        addCase(medicineCabinetGetAllPrescriptions.SUCCESS, (state, { payload }: PayloadAction<RxDetails[]>) =>
            produce(state, (draftState) => {
                draftState.currentPrescriptions = payload;
                draftState.medicineCabinetLoaded = !initialState.medicineCabinetLoaded;
                draftState.medicineCabinetIsLoading = false;
                draftState.medicineCabinetHasError = false;
            })
        );
        addCase(medicineCabinetGetAllPrescriptions.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.medicineCabinetLoaded = !initialState.medicineCabinetLoaded;
                draftState.medicineCabinetIsLoading = false;
                draftState.medicineCabinetHasError = true;
            })
        );
        addCase(medicineCabinetToggleAutoRefillForRx.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.autoRefillToggleBusy = true;
            })
        );
        addCase(medicineCabinetToggleAutoRefillForRx.SUCCESS, (state, { payload }: PayloadAction<RxDetails[]>) =>
            produce(state, (draftState) => {
                draftState.currentPrescriptions = payload;
                draftState.autoRefillToggleBusy = initialState.autoRefillToggleBusy;
            })
        );
        addCase(
            medicineCabinetGetStatusForRx.TRIGGER,
            (
                state,
                {
                    payload
                }: PayloadAction<{
                    rxNumber: string;
                    epostNumFamilyMember?: string;
                    onSuccess?: () => void;
                    onFailure?: () => void;
                }>
            ) =>
                produce(state, (draftState) => {
                    const isCached = Object.keys(draftState.cachedSubStatuses).some((key) => key === payload.rxNumber);

                    if (isCached) return;
                    const prescriptions =
                        draftState.currentPrescriptions &&
                        draftState.currentPrescriptions.filter((rx) => isRxOrderedInProgress(rx));

                    const updatedRxs = draftState.currentPrescriptions?.map((rx) => {
                        const rxNumbers = prescriptions?.map((rx) => rx.rxNumber);

                        if (rxNumbers?.includes(rx.rxNumber)) {
                            return { ...rx, rxSubStatus: { loading: true } };
                        } else {
                            return rx;
                        }
                    });

                    draftState.currentPrescriptions = updatedRxs;
                })
        );
        addCase(medicineCabinetGetStatusForRx.SUCCESS, (state, { payload }: PayloadAction<RxStatusResponse>) =>
            produce(state, (draftState) => {
                const prescriptions = draftState.currentPrescriptions;

                if (payload.rxNumber) {
                    draftState.cachedSubStatuses[payload.rxNumber] = payload;
                }

                const rxIndex = prescriptions?.findIndex((rx) => rx.rxNumber === payload.rxNumber);
                draftState.currentPrescriptions = prescriptions?.map((rx, index) => {
                    if (index !== rxIndex) {
                        return rx;
                    }

                    return { ...rx, rxSubStatus: payload };
                });
            })
        );
        addCase(medicineCabinetGetStatusForRx.FAILURE, (state, { payload }: PayloadAction<RxStatusResponse>) =>
            produce(state, (draftState) => {
                const prescriptions = draftState.currentPrescriptions;

                const rxIndex = prescriptions?.findIndex((rx) => rx.rxNumber === payload.rxNumber);
                draftState.currentPrescriptions = prescriptions?.map((rx, index) => {
                    if (index !== rxIndex) {
                        return rx;
                    }

                    return { ...rx, rxSubStatus: payload };
                });
            })
        );
        addCase(medicineCabinetToggleAutoRefillForRx.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.autoRefillToggleBusy = initialState.autoRefillToggleBusy;
            })
        );

        addCase(
            medicineCabinetLoadRoutine.TRIGGER,
            (
                state,
                action: PayloadAction<{
                    selectedTab?: string;
                    selectedDependent?: string;
                }>
            ) => {
                produce(state, (draftState) => {
                    const { selectedDependent, selectedTab } = action.payload;
                    draftState.medicineCabinetIsLoading = true;
                    draftState.medicineCabinetActiveTab = selectedDependent;
                    draftState.medicineCabinetFilterTab = selectedTab;
                });
            }
        );
        addCase(medicineCabinetLoadRoutine.SUCCESS, (state) => {
            produce(state, (draftState) => {
                draftState.medicineCabinetIsLoading = false;
            });
        });
        addCase(medicineCabinetLoadRoutine.FAILURE, (state) => {
            produce(state, (draftState) => {
                draftState.medicineCabinetIsLoading = false;
            });
        });
    }
});

export const {
    setMedicineCabinet,
    showNewPrescriptionModal,
    setMedicineCabinetActiveTab,
    setMedicineCabinetFilterTab,
    setMedicineCabinetIsBusy,
    setMedicineCabinetShouldRefresh,
    resetMedicineCabinetCachedSubStatus
} = medicineCabinetSlice.actions;

export default medicineCabinetSlice.reducer;
