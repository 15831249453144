import { CartPayload } from 'types/cart';
import {
    CaregiverAddressesResponse,
    EasyRefillCaregiverPaymentData,
    EasyRefillCartPayload,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPaymentCardDataResponse
} from 'types/easy-refill';

import { EasyRefillState } from './easy-refill.reducer';

/**
 * Adapts the CaregiverPaymentMethods Object.
 *
 * @param {response} - The Caregiver payment methods
 */
export function adaptCaregiverPaymentsResponse(
    response: EasyRefillCaregiverPaymentData
): GetEasyRefillPaymentCardDataResponse {
    const adaptedResponse: GetEasyRefillPaymentCardDataResponse = {
        messageStatus: response.messageStatus,
        messageText: response.messageText,
        paymentCards: []
    };

    if (response.paymentCards.familyCards && response.paymentCards.familyCards.length > 0) {
        const caregiverPaymentMethods = response.paymentCards.familyCards[0];
        if (caregiverPaymentMethods.paymentCards && caregiverPaymentMethods.paymentCards.results) {
            adaptedResponse.paymentCards = adaptedResponse.paymentCards.concat(
                caregiverPaymentMethods.paymentCards.results
            );
        }
    }

    return adaptedResponse;
}

/**
 * Adapts the CaregiverAddresses Object.
 *
 * @param {response} - The Caregiver Address object
 */
export function adaptCaregiverAddresessResponse(
    response: CaregiverAddressesResponse
): GetEasyRefillAddressDataResponse {
    const adaptedResponse: GetEasyRefillAddressDataResponse = {
        messageStatus: response.messageStatus,
        messageText: response.messageText,
        addresses: []
    };

    if (response.data.length > 0) {
        const caregiverAddresess = response.data[0].address.results;
        adaptedResponse.addresses = adaptedResponse.addresses.concat(caregiverAddresess);
    }

    return adaptedResponse;
}

/**
 * Validate if any user has rxs available to access to the easy refill flow..
 *
 * @param data The users inside the response of GetEasyRefillPatientFamilyData
 */
export function hasRxInFamily(data: GetEasyRefillPatientDataResponse[]) {
    for (const patient of data) {
        if (patient.rxResults && patient.rxResults.length > 0) {
            return true;
        }
    }
    return false;
}

export function getCaregiversShipping(carts: EasyRefillState['cart']) {
    const caregiverCart = carts?.find((cart) => cart.Type === 'Caregiver');
    return caregiverCart?.Order?.orderBillShip?.shipMethodId || null;
}

/**
 * Validate if the credit card is expired
 * @TODO: Should be removed after the BE returns this flag like logged area
 *
 * @param expiryDate string format month/year: xx/xxxx
 * @returns boolean
 */
export function isCreditCardExpired(expiryDate: string): boolean {
    // Parse the expiry date
    const [month, year] = expiryDate.split('/').map(Number);

    // Create Date objects for comparison
    const expiry = new Date(year, month - 1, 1);
    const currentDate = new Date();

    // Compare dates
    return expiry < currentDate;
}

/**
 * Add flag cardIsExpired based on cardExpiration
 * @TODO: Should be removed after the BE returns this flag like logged area
 *
 * @param paymentResponse GetEasyRefillPaymentCardDataResponse
 * @returns GetEasyRefillPaymentCardDataResponse
 */
export function setIsExpiredFlagToCard(
    paymentResponse: GetEasyRefillPaymentCardDataResponse
): GetEasyRefillPaymentCardDataResponse {
    const mappedPaymentCards = paymentResponse.paymentCards.map((card) => {
        if (isCreditCardExpired(card.cardExpiration)) {
            return { ...card, cardIsExpired: true };
        }
        return { ...card, cardIsExpired: false };
    });

    return {
        ...paymentResponse,
        paymentCards: mappedPaymentCards
    };
}

export function transformEasyRefillCartPayload(easyRefill: EasyRefillCartPayload[]): CartPayload[] {
    return easyRefill.map((item) => ({
        BirdiOrderNumber: item.birdiOrderNumber,
        FirstName: item.firstName || '',
        LastName: item.lastName || '',
        Type: item.type,
        EpostPatientNum: item.epostPatientNum,
        Order: item.order,
        messageErrorText: item.messageErrorText,
        messageStatus: item.messageStatus,
        messageText: item.messageText
    }));
}

export function transformCartPayloadToEasyRefill(
    cartPayload: CartPayload | undefined
): EasyRefillCartPayload | undefined {
    if (!cartPayload) return;

    return {
        birdiOrderNumber: cartPayload.BirdiOrderNumber,
        firstName: cartPayload.FirstName,
        lastName: cartPayload.LastName,
        type: cartPayload.Type,
        epostPatientNum: cartPayload.EpostPatientNum,
        order: cartPayload.Order,
        messageErrorText: cartPayload.messageErrorText,
        messageStatus: cartPayload.messageStatus,
        messageText: cartPayload.messageText
    };
}
