import { Field } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import FormSelect from 'ui-kit/form-select/form-select';
import Text from 'ui-kit/text/text';
import TextSetValue from 'ui-kit/text/textSetValue';

import { extractPackageOptions } from 'components/drug-lookup-form/drug-lookup-form.component';

import {
    DrugOption,
    PackageOption,
    setDrugDetailValues,
    StrengthOptionAlt
} from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import { addTransferPrescriptionDrugDetailsSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';

import { PrescriptionTypes } from 'types/prescription';

import { noop } from 'util/function';

import { useGlobalLink } from 'hooks/useGlobalLink';

import { PrescriptionInfoDetailsPropsAlt } from './prescription-info-details.props';

export const PrescriptionInfoDetailsAlt = ({
    formik,
    extractStrengths,
    selectOptions,
    onQuantityChange
}: PrescriptionInfoDetailsPropsAlt) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [showPackageOptions, setShowPackageOptions] = useState(false);
    const [quantityLabel, setQuantityLabel] = useState<string | undefined>();
    const requestFormValues = useSelector(addTransferPrescriptionDrugDetailsSelector);

    const globalLink = useGlobalLink();

    const setDrugForm = (value) => {
        formik.setFieldValue('form', value);
        setDrugStrength('', '');
    };

    const setDrugStrength = (value, gpi) => {
        formik.setFieldValue('strength', value);
        formik.setFieldValue('gpi', gpi);
        setDrugPackage('', '', '');
    };

    const setDrugPackage = (value, label, storeValue) => {
        formik.setFieldValue('ndc', value);
        formik.setFieldValue('packageDisplay', label);
        formik.setFieldValue('strengthWithPackage', storeValue);
        if (!value) {
            setQuantityLabel(undefined);
        }
        setDrugQty('');
    };

    const setDrugQty = (value, debounce = false) => {
        const prescriptionDetails = value !== '' ? { ...formik.values, qty: value } : null;

        formik.setFieldValue('qty', value);

        if (prescriptionDetails && debounce) {
            handleQuantityChange(prescriptionDetails);
        } else {
            onQuantityChange(prescriptionDetails);
        }
    };

    const handleQuantityChange = useCallback(
        debounce((prescription: PrescriptionTypes) => {
            onQuantityChange(prescription);
        }, 500),
        []
    );

    useEffect(() => {
        if (requestFormValues.drugStrengthOptions.length === 1) {
            handleDrugStrengthChange({
                key: requestFormValues.drugStrengthOptions[0].key,
                option: requestFormValues.drugStrengthOptions[0]
            });
        }
    }, [requestFormValues.drugStrengthOptions]);

    useEffect(() => {
        if (requestFormValues.drugPackageOptions?.length === 1) {
            setShowPackageOptions(!!requestFormValues.drugPackageOptions[0].isUoU);
            handleDrugPackageChange({
                key: requestFormValues.drugPackageOptions[0].key,
                option: requestFormValues.drugPackageOptions[0]
            });
        } else {
            if (requestFormValues.drugPackageOptions?.length > 1) {
                setShowPackageOptions(true);
                if (formik.values.ndc) {
                    const selectedPackageOption = requestFormValues.drugPackageOptions.find(
                        (option) => option.value === formik.values.ndc
                    );
                    if (selectedPackageOption) {
                        handleDrugPackageChange({
                            key: selectedPackageOption.key,
                            option: selectedPackageOption
                        });
                    }
                }
            } else {
                setShowPackageOptions(false);
            }
        }
    }, [requestFormValues.drugPackageOptions]);

    useEffect(() => {
        // Cannot use the setDrugForm, setDrugStrength functions, because the quantity label gets cleared out after
        // being corerctly set by the formik.initialValues.
        formik.setFieldValue('form', formik.initialValues.form);
        formik.setFieldValue('strength', formik.initialValues.strength);
        formik.setFieldValue('gpi', formik.initialValues.gpi);
        formik.setFieldValue('ndc', formik.initialValues.ndc);
        formik.setFieldValue('packageDisplay', formik.initialValues.packageDisplay);
        formik.setFieldValue('strengthWithPackage', formik.initialValues.strengthWithPackage);
        formik.setFieldValue('qty', formik.initialValues.qty);
    }, []);

    const handleDrugFormChange = (drugForm: { key: string; option: DrugOption }) => {
        setDrugForm(drugForm.option.value);
        dispatch(
            setDrugDetailValues({
                ...requestFormValues,
                drugStrengthOptions: extractStrengths(drugForm.option.strengths),
                drugPackageOptions: []
            })
        );
    };

    const handleDrugStrengthChange = (drugStrength: { key: string; option: StrengthOptionAlt }) => {
        setDrugStrength(drugStrength.option.value, drugStrength.option.gpi);
        dispatch(
            setDrugDetailValues({
                ...requestFormValues,
                drugPackageOptions: extractPackageOptions(drugStrength.option)
            })
        );
    };

    const handleDrugPackageChange = (drugPackage: { key: string; option: PackageOption }) => {
        setDrugPackage(
            drugPackage.option.value,
            drugPackage.option.isUoU ? drugPackage.option.label : '',
            drugPackage.option.isUoU ? drugPackage.option.packageWithStrength : ''
        );
        setQuantityLabel(drugPackage.option.quantityLabelDesc);
        if (drugPackage.key !== formik.initialValues.ndc) {
            setDrugQty(drugPackage.option.quantity90days);
        } else {
            setDrugQty(formik.initialValues.qty);
        }
    };

    const getPackageLabel = (ndc: string): string => {
        const pkgs = requestFormValues.drugPackageOptions;
        if (pkgs) {
            const matchingPkg = pkgs?.find((pkg) => pkg.ndc === ndc) || pkgs[0];
            return matchingPkg ? matchingPkg.label : '';
        } else {
            return '';
        }
    };

    return (
        <>
            <Row xs={1}>
                <Col>
                    <Field
                        id="drugForm"
                        name="drugForm"
                        options={selectOptions ? selectOptions : requestFormValues.drugFormOptions}
                        component={FormSelect}
                        showSelectPlaceholder={true}
                        placeholder={t('prescriptionInfoForm.inputs.drugForm')}
                        touched={formik?.touched?.drugForm}
                        value={formik?.values?.drugForm}
                        errors={
                            formik?.errors?.drugForm
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.drugForm')
                                  })
                                : undefined
                        }
                        onSelectionChanged={handleDrugFormChange}
                        disabled={requestFormValues.drugFormOptions && requestFormValues.drugFormOptions.length === 0}
                        defaultValue={formik?.initialValues.drugForm}
                        formikControlled={true}
                        singleOptionComponent={
                            <TextSetValue
                                name="drugForm"
                                label={t('prescriptionInfoForm.inputs.drugForm')}
                                value={formik?.values.drugForm ? formik?.values.drugForm : ''}
                                onChange={noop}
                                readOnly={true}
                            />
                        }
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.drugForm'))}
                    />
                </Col>
            </Row>
            <Row xs={1} lg={showPackageOptions ? 3 : 2}>
                <Col>
                    <Field
                        id="strength"
                        name="strength"
                        options={requestFormValues.drugStrengthOptions}
                        component={FormSelect}
                        showSelectPlaceholder={true}
                        placeholder={t('prescriptionInfoForm.inputs.strength')}
                        touched={formik?.touched.strength}
                        value={formik?.values.strength}
                        errors={
                            formik?.errors?.strength
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.strength')
                                  })
                                : undefined
                        }
                        disabled={
                            requestFormValues.drugStrengthOptions && requestFormValues.drugStrengthOptions.length === 0
                        }
                        onSelectionChanged={handleDrugStrengthChange}
                        defaultValue={formik?.initialValues.strength}
                        formikControlled={true}
                        singleOptionComponent={
                            <TextSetValue
                                name="strength"
                                label={t('prescriptionInfoForm.inputs.strength')}
                                value={formik?.values.strength ? formik?.values.strength : ''}
                                onChange={noop}
                                readOnly={true}
                            />
                        }
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.strength'))}
                    />
                </Col>
                <Col className={showPackageOptions ? '' : 'd-none'}>
                    <Text className={'d-none'} type={'hidden'} name="packageDisplay" />
                    <Text className={'d-none'} type={'hidden'} name="strengthWithPackage" />
                    <Field
                        id="ndc"
                        name="ndc"
                        options={requestFormValues.drugPackageOptions}
                        component={FormSelect}
                        showSelectPlaceholder={true}
                        showTitle={true}
                        placeholder={t('prescriptionInfoForm.inputs.package')}
                        touched={formik?.touched.ndc || formik?.touched.qty}
                        value={formik?.values?.ndc}
                        errors={
                            formik?.errors?.ndc
                                ? t('forms.errorMessages.requiredField', {
                                      label: t('prescriptionInfoForm.inputs.package')
                                  })
                                : undefined
                        }
                        disabled={
                            requestFormValues.drugStrengthOptions && requestFormValues.drugStrengthOptions.length === 0
                        }
                        onSelectionChanged={handleDrugPackageChange}
                        defaultValue={formik?.initialValues.ndc}
                        formikControlled={true}
                        singleOptionComponent={
                            <TextSetValue
                                name="ndc"
                                label={t('prescriptionInfoForm.inputs.package')}
                                value={formik?.values.ndc ? getPackageLabel(formik?.values?.ndc) : ''}
                                title={formik?.values.ndc ? getPackageLabel(formik?.values?.ndc) : ''}
                                onChange={noop}
                                readOnly={true}
                            />
                        }
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.package'))}
                    />
                </Col>
                <Col>
                    <TextSetValue
                        name="qty"
                        label={t('prescriptionInfoForm.inputs.qty')}
                        footNote={quantityLabel}
                        onChange={(e) => setDrugQty(e.target.value, true)}
                        errors={
                            formik?.errors?.qty
                                ? formik.values.qty === ''
                                    ? t('forms.errorMessages.requiredField', {
                                          label: t('prescriptionInfoForm.inputs.qty')
                                      })
                                    : formik.errors.qty === 'drug-qty-max'
                                    ? t('forms.errorMessages.maxCharacters', {
                                          field: t('prescriptionInfoForm.inputs.qty'),

                                          max: '6'
                                      })
                                    : t('forms.errorMessages.nonNumericalValue', {
                                          field: t('prescriptionInfoForm.inputs.qty')
                                      })
                                : formik?.errors?.qty
                        }
                        touched={formik?.touched.qty}
                        value={formik.values.qty ? formik.values.qty : ''}
                        disabled={formik?.values?.strength === ''}
                        autoComplete="off"
                        onFocus={() => globalLink.handleFieldFocus(t('prescriptionInfoForm.inputs.qty'))}
                    />
                </Col>
            </Row>
        </>
    );
};
