import { RegistrationState } from './registration.types';

export const registrationInitialState: RegistrationState = {
    isLoading: false,
    registration: {},
    isRegistrationCompleted: undefined,
    isAcceptedTerms: undefined,
    formValues: {
        email: '',
        zipCode: '',
        city: '',
        state: '',
        firstName: '',
        lastName: '',
        password: '',
        typePlan: '',
        promoCode: '',
        isAuthorizePayment: false,
        cardNumber: '',
        nameOnCard: '',
        expMonth: '',
        expYear: '',
        fsaCard: false,
        cvcNumber: '',
        membershipPlanId: '',
        dobMonth: '',
        dobYear: '',
        dobDay: '',
        isAgeConfirmed: false,
        isGovernmentInsuranceEnrolled: false
    },
    insuredRegistration: undefined,
    messageStatus: undefined,
    messageErrorText: undefined
};
