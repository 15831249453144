import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import FAQIcon from 'ui-kit/icons/frequently-asked-questions/faq-icon';

import '../../ui-kit/utils/smoke/smoke.style.scss';
import { FAQProps, FAQQuestionProps } from './frequently-asked-questions.props';
import './frequently-asked-questions.style.scss';

const QuestionAnswerPair = ({ question, answer, index }: FAQQuestionProps): ReactElement => {
    return (
        <details className="question-wrapper" data-ga-location={`FAQs ${index + 1}`}>
            <summary>
                <div className="question-query h3">
                    <FAQIcon />
                    <span className="query-text">{question}</span>
                    <ChevronIcon direction="down" />
                </div>
            </summary>
            <div
                className="question-answer"
                dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(answer, {
                        allowedAttributes: {
                            a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                            img: ['src', 'alt'],
                            '*': ['class']
                        }
                    })
                }}
            />
        </details>
    );
};

const FrequentlyAskedQuestions = ({ background, allQuestions, sectionIndex }: FAQProps): ReactElement => {
    const { t } = useTranslation();

    return (
        <Container
            fluid
            className={`frequently-asked-questions ${background ?? ''}`}
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            id="faq"
        >
            <Row>
                <Col xs="12" sm="12" lg={{ span: 10, offset: 1 }}>
                    <div className="h5 faq-subhead">{t('frequentlyAskedQuestions.subhead')}</div>
                    {sectionIndex === 0 && <h1 className="h2 faq-header">{t('frequentlyAskedQuestions.header')}</h1>}
                    {sectionIndex > 0 && <h2 className="faq-header">{t('frequentlyAskedQuestions.header')}</h2>}
                    {allQuestions.map(({ question, answer, id }, index) => {
                        return <QuestionAnswerPair question={question} answer={answer} key={id} index={index} />;
                    })}
                </Col>
            </Row>
        </Container>
    );
};

export default FrequentlyAskedQuestions;
