import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import {
    accountCreditCardsSelector,
    accountIsLoadingPaymentMethodsSelector,
    accountIsLoggedInSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { getCartRoutine } from 'state/cart/cart.routines';
import { cartApiStatusSelector } from 'state/cart/cart.selectors';
import {
    medicineCabinetGetAllPrescriptions,
    medicineCabinetGetStatusForRx,
    medicineCabinetLoadRoutine,
    medicineCabinetToggleAutoRefillAllRxs,
    medicineCabinetToggleAutoRefillForRx
} from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetCachedSubStatusesSelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import MedicineCabinetService, {
    RxStatusResponse,
    ToggleAutoFillRequest,
    ToggleAutoFillResponse
} from 'state/medicine-cabinet/medicine-cabinet.services';

import { CreditCardPayload } from 'types/credit-card';
import { RxDetails } from 'types/prescription';

import { ApiStatus } from 'enums/api-status';
import { RX_STATUS } from 'enums/prescription';

import { UnknownFunction } from 'util/function';
import { getRxDisplayStatus, getRxStatus } from 'util/payload-to-props';
import { isRxOnHold, isRxOrderedInProgress } from 'util/prescription';
import { baseEffectHandler } from 'util/sagas/sagas';

import { showNewPrescriptionModal } from './medicine-cabinet.reducers';
import { medicineCabinetPrescriptionsSelector } from './medicine-cabinet.selectors';

export default function* medicineCabinetSaga() {
    yield takeLatest(
        medicineCabinetGetAllPrescriptions.TRIGGER,
        function* (
            action: PayloadAction<{
                epostNumFamilyMember?: string;
                fetchRxSubStatus?: boolean;
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
            }>
        ) {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload | undefined = yield select(accountProfileSelector);

            const { epostNumFamilyMember, fetchRxSubStatus } = action.payload;

            yield baseEffectHandler({
                service: MedicineCabinetService.allRxs().get,
                data: epostNumFamilyMember,
                isAuthenticatedService: true,
                isLoggedIn,
                *onResponse(data: RxDetails[]) {
                    try {
                        if (!data || !data.length) {
                            yield put(medicineCabinetGetAllPrescriptions.success([]));
                        }

                        const prescriptions: RxDetails[] = (data ?? []).map((prescription: RxDetails) => ({
                            ...prescription,
                            // realRxCardStatus contains the "real" status, ignoring if the
                            // prescription is in the cart.
                            realRxCardStatus: getRxStatus(prescription),
                            // rxCardStatus contains the status to display in
                            // the rx card, includes in cart status.
                            rxCardStatus: getRxDisplayStatus(prescription)
                        }));

                        //Diff check for rxs that are stored for modal vs sent from endpoint

                        //Extracts the rx numbers from the api
                        const responseRxs = prescriptions
                            .filter((prescription: RxDetails) => prescription.rxCardStatus === RX_STATUS.NEW_RX)
                            .map((prescription: RxDetails) => {
                                return {
                                    prescriptionName: prescription.dispensedProductName,
                                    rxNumber: prescription.rxNumber,
                                    alreadyDisplayedInModal: false,
                                    epostPatientNum: prescription.epostPatientNum
                                };
                            });

                        if (responseRxs.length > 0) {
                            // Get our cached storage for past modals displayed. Contains RX numbers
                            const displayedRxsString =
                                localStorage.getItem('newRxsDisplayed') || '{"time": null, "prescriptions": []}';

                            // Get cached modal data from localStorage
                            const displayRxData: {
                                prescriptions: {
                                    prescriptionName: string;
                                    rxNumber: string;
                                    alreadyDisplayedInModal: boolean;
                                    epostPatientNum: string;
                                }[];
                                time: string | null;
                            } = JSON.parse(displayedRxsString);

                            // Validate storedDate before modifying it
                            const storedDate = displayRxData.time ? new Date(displayRxData.time) : new Date();
                            if (!isNaN(storedDate.getTime())) {
                                storedDate.setDate(storedDate.getDate() + 2);
                            }

                            if (Date.now() >= storedDate.getTime()) {
                                displayRxData.prescriptions = [];
                            }

                            // Extract only rxNumbers from stored prescriptions
                            const storedRxNumbers = new Set((displayRxData.prescriptions || []).map((p) => p.rxNumber));

                            // Find new RXs that were not in localStorage
                            const newRxs = responseRxs.filter((rx) => !storedRxNumbers.has(rx.rxNumber));

                            if (newRxs.length > 0) {
                                // If we are visiting back two days later and we do not have new RX numbers from the API
                                // we should display a modal to the user.

                                yield put(
                                    showNewPrescriptionModal({
                                        isRxLoaded: true,
                                        prescriptions: newRxs
                                    })
                                );

                                const newRxsDisplayedStored = JSON.parse(
                                    localStorage.getItem('newRxsDisplayed') || '{}'
                                );

                                const getUpdatedPrescriptions = (
                                    storedPrescriptions: typeof newRxs,
                                    newPrescriptions: typeof newRxs
                                ) => {
                                    const updatedPrescriptions = [
                                        ...(storedPrescriptions || []).map((storedRx) => {
                                            // check if any prescriptions changed only "alreadyDisplayedInModal" value and update it
                                            const matchingNewRx = newPrescriptions.find(
                                                (newRx) => newRx.rxNumber === storedRx.rxNumber
                                            );
                                            return matchingNewRx
                                                ? {
                                                      ...storedRx,
                                                      alreadyDisplayedInModal: matchingNewRx.alreadyDisplayedInModal
                                                  }
                                                : storedRx;
                                        }),
                                        // add new prescriptions
                                        ...newPrescriptions.filter(
                                            (newRx) =>
                                                !(storedPrescriptions || []).some(
                                                    (storedRx) => storedRx.rxNumber === newRx.rxNumber
                                                )
                                        )
                                    ];
                                    return updatedPrescriptions;
                                };

                                localStorage.setItem(
                                    'newRxsDisplayed',
                                    JSON.stringify({
                                        time: Date.now(), // Store as timestamp
                                        prescriptions: getUpdatedPrescriptions(
                                            newRxsDisplayedStored?.prescriptions,
                                            newRxs
                                        )
                                    })
                                );
                            }
                        }

                        yield put(medicineCabinetGetAllPrescriptions.success(prescriptions));

                        if (fetchRxSubStatus && profileObject) {
                            const filteredRxNumbers = prescriptions
                                .filter((rx) => !isRxOnHold(rx) && isRxOrderedInProgress(rx))
                                .map((rx) => rx.rxNumber);

                            for (const rxNumber of filteredRxNumbers) {
                                yield put(
                                    medicineCabinetGetStatusForRx.trigger({
                                        rxNumber,
                                        epostNumFamilyMember
                                    })
                                );
                            }
                        }

                        const { onSuccess } = action.payload;
                        if (onSuccess) onSuccess();
                    } catch (error) {
                        yield put(medicineCabinetGetAllPrescriptions.failure(data));
                        const { onFailure } = action.payload;
                        if (onFailure) onFailure();
                    }
                },
                *onError(data) {
                    yield put(medicineCabinetGetAllPrescriptions.failure(data));
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        }
    );

    function* toggleAutoRefillForRxSaga(
        action: PayloadAction<{
            rxNumber: string;
            rxSeqNum: string;
            autoRefillEnabled: boolean;
            onSuccess?: () => void;
            onFailure?: () => void;
            isRenew?: boolean;
        }>
    ) {
        try {
            const { rxNumber, autoRefillEnabled, isRenew, onSuccess, onFailure } = action.payload;
            const currentPrescriptions: RxDetails[] = yield select(medicineCabinetPrescriptionsSelector);

            const data: ToggleAutoFillRequest = {
                RxNumber: rxNumber,
                AutoFillToggle: autoRefillEnabled
            };

            yield baseEffectHandler({
                service: MedicineCabinetService.toggleAutofillForRx().post,
                data,
                *onResponse(data: ToggleAutoFillResponse) {
                    if (!data.messageErrorText) {
                        // success
                        const currentRx = currentPrescriptions.find((rx) => rx.rxNumber === rxNumber);
                        const updatedRx = {
                            ...currentRx,
                            autoRefillEnabled: autoRefillEnabled,
                            // DRX-2084: if isRenew is true reset consentExpiration in order to remove renew button from UI
                            consentExpiration: isRenew ? '' : currentRx?.consentExpiration
                        };

                        const newRxs = currentPrescriptions.map((rx) => {
                            return rx.rxNumber === updatedRx.rxNumber ? updatedRx : rx;
                        });

                        yield put(medicineCabinetToggleAutoRefillForRx.success(newRxs));
                        if (onSuccess) onSuccess();
                    } else {
                        // error
                        yield put(medicineCabinetToggleAutoRefillForRx.failure(data));
                        if (onFailure) onFailure();
                    }
                    return data;
                },
                *onError(error) {
                    yield put(medicineCabinetToggleAutoRefillForRx.failure(error));
                }
            });
        } catch (error) {
            yield put(medicineCabinetToggleAutoRefillForRx.failure(error));
        }
    }

    yield takeLatest(medicineCabinetToggleAutoRefillForRx.TRIGGER, toggleAutoRefillForRxSaga);

    // This routine is not being used currently but might be used in the future.
    yield takeLatest(
        medicineCabinetToggleAutoRefillAllRxs.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumbers: ToggleAutoFillRequest[];
                onSuccess?: UnknownFunction;
                onFailure?: UnknownFunction;
                isRenew?: boolean;
            }>
        ) {
            try {
                const { rxNumbers, onSuccess, onFailure } = action.payload;

                yield all(
                    rxNumbers.map((rxAutoFillRequest) => {
                        const payloadForSaga: PayloadAction<{
                            rxNumber: string;
                            rxSeqNum: string;
                            autoRefillEnabled: boolean;
                            onSuccess?: () => void;
                            onFailure?: () => void;
                            isRenew?: boolean;
                        }> = {
                            payload: {
                                rxNumber: rxAutoFillRequest.RxNumber,
                                // @ts-expect-error interface is not correct, but routine is apparently
                                // not being triggered anywhere so I can't verify
                                rxSeqNum: rxAutoFillRequest.RxSeqNum,
                                autoRefillEnabled: rxAutoFillRequest.AutoFillToggle,
                                onSuccess,
                                onFailure,
                                isRenew: rxAutoFillRequest.isRenew
                            },
                            type: ''
                        };

                        const response = call(toggleAutoRefillForRxSaga, payloadForSaga);
                        return response;
                    })
                );
                if (onSuccess) onSuccess();
            } catch (error) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        }
    );

    yield takeEvery(
        medicineCabinetGetStatusForRx.TRIGGER,
        function* getSubStatusForRxSaga(
            action: PayloadAction<{
                rxNumber: string;
                epostNumFamilyMember?: string;
                onSuccess?: () => void;
                onFailure?: () => void;
            }>
        ) {
            const { rxNumber, epostNumFamilyMember } = action.payload;
            const cachedSubStatuses: {
                [key: string]: RxStatusResponse;
            } = yield select(medicineCabinetCachedSubStatusesSelector);
            const isCached = Object.keys(cachedSubStatuses).some((key) => key === rxNumber);
            if (isCached) {
                yield put(medicineCabinetGetStatusForRx.success(cachedSubStatuses[rxNumber]));
                return;
            }

            const response: RxStatusResponse = yield call(
                MedicineCabinetService.getStatusForRx().get,
                rxNumber,
                epostNumFamilyMember
            );
            if (response.messageErrorText) {
                yield put(medicineCabinetGetStatusForRx.failure({ ...response, rxNumber }));
            } else {
                yield put(medicineCabinetGetStatusForRx.success({ ...response, rxNumber }));
            }
        }
    );

    yield takeLatest(
        medicineCabinetLoadRoutine.TRIGGER,
        function* firstLoadMedicineCabinetSaga(
            action: PayloadAction<{
                selectedTab?: string;
                selectedDependent?: string;
                fetchRxSubStatus?: boolean;
            }>
        ) {
            try {
                const { selectedDependent, fetchRxSubStatus } = action.payload || {};

                if (selectedDependent) {
                    yield put(
                        medicineCabinetGetAllPrescriptions.trigger({
                            epostNumFamilyMember: selectedDependent,
                            fetchRxSubStatus: fetchRxSubStatus
                        })
                    );

                    yield put(medicineCabinetLoadRoutine.success());
                    return;
                }

                const profileObject: ProfileObjectPayload | undefined = yield select(accountProfileSelector);

                if (!profileObject) return;

                const cartApiStatus: ApiStatus = yield select(cartApiStatusSelector);

                if (cartApiStatus !== ApiStatus.LOADING) {
                    yield put(getCartRoutine.trigger());
                }

                const paymentData: CreditCardPayload[] | undefined = yield select(accountCreditCardsSelector);
                const isLoadingPaymentData: boolean = yield select(accountIsLoadingPaymentMethodsSelector);

                if (!paymentData?.length && !isLoadingPaymentData) {
                    yield put(accountGetAllCreditCardsRoutine.trigger());
                }

                yield put(
                    medicineCabinetGetAllPrescriptions.trigger({
                        epostNumFamilyMember: selectedDependent || profileObject.epostPatientNum,
                        fetchRxSubStatus: fetchRxSubStatus
                    })
                );

                yield put(medicineCabinetLoadRoutine.success());
            } catch (error) {
                yield put(medicineCabinetLoadRoutine.failure());
            }
        }
    );
}
