import { ALLOW_INSURED_BIRDI_PRICE, OVERRIDE_ADJUDICATION } from 'gatsby-env-variables';

import { getCartShippingMethodId } from 'state/cart/cart.helpers';

import { AddressPayload } from 'types/account';
import { CartObject, CartOrderRx, CartPatient, CartPayload, CartRxPrice } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { DependentsPricingData, FamilyAutoRefillData, PatientBasicData } from 'types/family-account';
import { OrderBillShip, OTCs, RefillRxs } from 'types/order-prescription';
import { PlansObjectPayload } from 'types/plans';

import { RX_LINE_ERROR } from 'enums/cart';
import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';

import { findBirdiPrice } from 'util/drug';
import { getBasicPatientData } from 'util/family-account';
import { patientHasMembership } from 'util/membership';
import { getBirdiCashPricePlanNum, isPlanInsurance } from 'util/plans';

import { doesPlanAllowsPricing } from '../pricing';

//
// --- Types ---

type RxLineErrorCodeKey = keyof typeof RX_LINE_ERROR;
export type RxLineErrorCode = typeof RX_LINE_ERROR[RxLineErrorCodeKey];

//
// --- Constants ---

export const EXPEDITED_SHIPPING_COST = 25;
export const EXPEDITED_SHIPPING_ID = '638';
export const DEFAULT_SHIPPING_ID = '505';

/** rx_line_error codes for various insurance related issues that result in birdi price being displayed */
export const birdiPriceRxLineErrorCodes: ReadonlyArray<RxLineErrorCode> = [
    RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY,
    RX_LINE_ERROR.NO_LONGER_COVERED,
    RX_LINE_ERROR.NOT_COVERED
];

//
// --- Cart Util Functions ---

/**
 * Function that validate if an Rx in the cart
 * is returning a value through adjudication.
 *
 * @param {CartOrderRx} cartRx
 * @returns {boolean}
 */
export const hasAdjudicatedPrice = (cartRx: CartOrderRx): boolean => {
    if (OVERRIDE_ADJUDICATION) return true;
    // When we get an rxLineError as O we are expecting to have a valid
    // patient copay, which means we have adjudicated price.
    // We shouldn't have an scenario where patientCopay is not available
    // and rxLineError is 0,
    if (cartRx.rxDetails.rxLineError === RX_LINE_ERROR.NONE) return true;

    // Other possible errors we may get from adjudication are defined at
    // RX_LINE_ERROR constant at the top of this file.

    // If we get any rxLineError we will first validate that we have loaded
    // rx prescription details information to continue.
    if (!cartRx.rxDetails.prescriptionDetail) return false;

    // This is legacy logic.
    if (
        cartRx.rxDetails.messageStatus &&
        cartRx.rxDetails.prescriptionDetail.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE &&
        cartRx.rxDetails.prescriptionDetail.webEligibilityStatus !== RX_WEB_ELIGIBILITY_STATUS.AUTH_REQ
    )
        return true;

    return cartRx.rxDetails.messageStatus;
};

/**
 * Function that validates that the patientCopay is a valid value
 *
 * @param {CartOrderRx} cartRx
 * @returns {boolean}
 */
export const isValidPatientCopay = (cartRx: CartOrderRx): boolean => {
    if (cartRx.rxDetails.patientCopay === null) return false;
    const hasRxLineError = birdiPriceRxLineErrorCodes.includes(cartRx.rxDetails.rxLineError as RxLineErrorCode);

    // A valid patient copay will exist when:
    return (
        // PatientCopay must be a number
        !isNaN(cartRx.rxDetails.patientCopay) &&
        // if primary plan is insurance must be 0 or higher
        ((cartRx.isPrimaryPlanInsurance && cartRx.rxDetails.patientCopay >= 0 && !hasRxLineError) ||
            // if primary plan is not insurance it must me higher than 0
            (!cartRx.isPrimaryPlanInsurance && cartRx.rxDetails.patientCopay > 0))
    );
};

/**
 * Function that returns true if would be valid to display birdi price from /medimpact-pricing API
 *
 * @param {CartOrderRx} cartRx
 * @returns {boolean}
 */
export const shouldShowBirdiCashPrice = (cartRx: CartOrderRx, birdiPrice?: DrugWithDiscountPrice): boolean => {
    const primaryPlanAllowsBirdiPrice = doesPlanAllowsPricing(cartRx.primaryPlanAlias);
    const isAdjudicated = hasAdjudicatedPrice(cartRx);
    // Validate if a secondary plan allows us to use birdi cash price
    const isSecondaryPlanAllowsBirdiPrice = !!cartRx.birdiPricePlanNum;

    return (
        // If the patient is Membership (BRD02 and not on demand) it will always display birdi price
        (primaryPlanAllowsBirdiPrice && cartRx.isPatientMembership) ||
        // If the primary plan allows birdi price and has adjudicated price
        // the value received as adjudicated will be displayed as birdi price (BRD01 or BRD02 patients)
        (primaryPlanAllowsBirdiPrice && isAdjudicated) ||
        // If a user is insurance, and ALLOW_INSURED_BIRDI_PRICE feature flag is on
        // but also a secondary plan allows birdi price (BRD01 or BRD02)
        (cartRx.isPrimaryPlanInsurance &&
            ALLOW_INSURED_BIRDI_PRICE &&
            isSecondaryPlanAllowsBirdiPrice &&
            // Only rxLineError codes 40, 65 and 70 allows displaying of birdi cash price
            birdiPriceRxLineErrorCodes.includes(cartRx.rxDetails.rxLineError as RxLineErrorCode))

        // Please note that rxLineError 2 will not attempt to get birdiCashPrice, so
        // we expect to show the Price currently unavailable label.
        // However, for BRD02 users we are allowed to get prices from ScriptSave,
        // via /medimpact-pricing API. Although we wouldn't expect there is a missing
        // price on cart, but being available in the medimpact-pricing, because they are
        // both getting prices from ScriptSave in the backend.
    );
};

/**
 * Validate if we should display the strikethrough pricing if the awp price is higher
 * than the final price (patientCopay or birdiPrice according to plan details)
 *
 * @param {CartOrderRx} cartRx
 * @returns {boolean}
 */
export const hasStrikeThruPricing = (
    cartRx: CartOrderRx,
    finalPrice = 0,
    birdiPrice?: DrugWithDiscountPrice
): boolean => {
    // If we don't have birdi price or awpPrice is not available we directly return false
    if (!birdiPrice) return false;
    if (!birdiPrice?.awpPriceValue || birdiPrice?.awpPriceValue <= 0) return false;

    // If our price is 0, we directly say there is no strike thru pricing
    if (finalPrice === 0) return false;

    // If AWP price is higher than our final price then we know there is a value to display
    const isStrikeThruPricing = birdiPrice?.awpPriceValue !== 0 && birdiPrice?.awpPriceValue > finalPrice;

    // We will return true if there is strike thru pricing and if we are allowed to show
    // birdi cash price (birdi cash, membership plan or insurance with rxLineError 40, 65, 70)
    return isStrikeThruPricing && shouldShowBirdiCashPrice(cartRx, birdiPrice);
};

/**
 *  Function that builds the CartRxPrice object within the cart object for each Rx in the cart.
 *
 * @param {CartOrderRx} cartRx
 * @param {DrugWithDiscountPrice} birdiPrice
 * @returns {CartRxPrice}
 */
export const getCartRxPrice = (cartRx: CartOrderRx, birdiPrice?: DrugWithDiscountPrice): CartRxPrice => {
    // Initialize variables
    let finalPrice = 0;
    let discount = 0;
    // If the API doesn't return rxLineError
    // and the patientCopay is more than 0 will be considered as valid
    const validPatientCopay = isValidPatientCopay(cartRx);

    // Building initial CartRxPrice object loading some initial data
    const cartRxPrice: Partial<CartRxPrice> = {
        rxLineError: Number(cartRx.rxDetails.rxLineError),
        hasAdjudicatedPrice: hasAdjudicatedPrice(cartRx),
        patientCopay: cartRx.rxDetails.patientCopay,
        planAlias: cartRx.primaryPlanAlias,
        // If there is birdiPrice available (loaded via /medimpact-pricing API will be loaded here)
        birdiPrice,
        // Verify if we should show birdi cash price
        showBirdiCashPrice: shouldShowBirdiCashPrice(cartRx, birdiPrice),
        awpPrice: birdiPrice?.awpPriceValue
    };

    // If we have a valid patient copay we will use it as our final price
    if (validPatientCopay && cartRx.rxDetails.patientCopay) {
        finalPrice = cartRx?.rxDetails.patientCopay;
    }

    // If there is no valid patient copay and we are allowed to show birdi
    // cash price  (birdi cash, membership plan or insurance with rxLineError
    // 40, 65, 70) and the value from /medimpact-pricing API exists we will use it.
    // See shouldShowBirdiCashPrice function to see rxLineErrors validation
    const hasRxLineError = birdiPriceRxLineErrorCodes.includes(cartRx.rxDetails.rxLineError as RxLineErrorCode);

    const hasValidBirdiPrice =
        (!validPatientCopay || hasRxLineError) &&
        !!cartRxPrice.showBirdiCashPrice &&
        !!birdiPrice?.priceValue &&
        birdiPrice?.priceValue > 0;

    if (hasValidBirdiPrice && !!birdiPrice?.priceValue) {
        finalPrice = birdiPrice.priceValue;
    }

    // Has known price will let us know whether we have a valid price to show
    const hasKnownPrice =
        // If final price is more than 0 we consider it as a valid price
        finalPrice > 0 ||
        // Only insurance users that are not showing birdi cash price, can allow price to be 0
        (cartRx.isPrimaryPlanInsurance &&
            ((validPatientCopay && cartRx.rxDetails.patientCopay === 0 && !!cartRxPrice.hasAdjudicatedPrice) ||
                (hasValidBirdiPrice && hasRxLineError)));

    // Verify if we should load the strike thru pricing
    const showStrikeThruPricing = hasStrikeThruPricing(cartRx, finalPrice, birdiPrice);

    // If we have strike thru pricing we get the discount amount by
    // calculating the difference of awpPrice - finalPrice
    if (showStrikeThruPricing && cartRxPrice.awpPrice && finalPrice) {
        discount = cartRxPrice.awpPrice > finalPrice ? cartRxPrice.awpPrice - finalPrice : 0;
    }

    // Return the updated values
    const cartRxPriceUpdated: CartRxPrice = {
        ...(cartRxPrice as CartRxPrice),
        ...{
            finalPrice,
            hasKnownPrice,
            discount,
            showStrikeThruPricing
        }
    };
    return cartRxPriceUpdated;
};

/**
 * After a cartRx has been filled with pricing information
 * we process the cart order to validate whether we should show
 * any disclaimer.
 *
 * @param {CartOrderRx} cartRx
 * @returns {string | undefined}
 */
export const getCartDisclaimer = (cartRx: CartOrderRx): string | undefined => {
    let disclaimerKey;

    /*
        IMPORTANT, THE FOLLOWING LOGIC WAS UPDATED BASED ON A CONVERSATION WITH CHRISTINE
        See figma messages at: https://www.figma.com/design/4NZtgEMeQ8Q3ozHa1Y6sme/5.0-(Caregivers)?node-id=1675-191111&t=9m4shUqTFUpewBEm-4

        When getting rxLineError as 2 we will display the following text:

       "We attempted to process your prescription request and the copay is currently unavailable.
       You may continue without the estimated copay or remove the item and contact support
       at {{- phoneNumber}}"
    */
    if (cartRx.rxDetails.rxLineError === RX_LINE_ERROR.ADJUDICATE_RX_ERROR) {
        disclaimerKey = 'pages.cart.rxItemCopayErrorMessage';
    }

    /*
        We will get rxLineError 70 when a user is insurance, but the prescription
        is not covered, we will validate at shouldShowBirdiCashPrice() this error
        code and whether we have a price to display (please note that a cash price
        plan must be active as secondary to get this price), so then we can continue
        with the following logic:
 */
    if (cartRx.rxDetails.rxLineError === RX_LINE_ERROR.NOT_COVERED) {
        /*
            If we have a cash price to display, we will show the Est. Cash Price suffix
            and the following disclaimer:

            "This medication is not covered by insurance but is available for purchase
            using Cash Price. Contact support at {{- phoneNumber}} with questions."

            If we don't have the price, we will show the default price unavailable message
            and the UI will show "Price Currently Unavailable" label with the following message:

            "We attempted to process your prescription request and the estimated cash price
            is currently unavailable. You may continue without the estimated cash price
            or remove the item and contact support at {{- phoneNumber}}"
        */

        disclaimerKey = cartRx.cartPrice?.showBirdiCashPrice
            ? 'pages.cart.rxItemNotCoveredErrorMessage'
            : 'pages.cart.rxItemCashPriceErrorMessage';
    }

    /*
        We may get an rxLineError 40 when Pharmacy is not contracted with plan, and will
        behave similar as rxLineError 70, se should try to get the birdi price first.
    */
    if (cartRx.rxDetails.rxLineError === RX_LINE_ERROR.NO_CONTRACT_WITH_PHARMACY) {
        /*
            If price is available via birdi price we should show the Est. Cash Price and:

            "Your insurance is no longer contracted with the pharmacy, but Birdi can
            still help you! You may continue with “The Cash Price” or contact support
            at {{- phoneNumber}} with questions."

            If Price is not available we will show the "Price Currently Unavailable" label
            along with the message:

            "We attempted to process your prescription request and the estimated cash price is
            currently unavailable. You may continue without the estimated cash price or
            remove the item and contact support at {{- phoneNumber}}"
        */
        disclaimerKey = cartRx.cartPrice?.showBirdiCashPrice
            ? 'pages.cart.rxItemNoPharmacyContractErrorMessage'
            : 'pages.cart.rxItemCashPriceErrorMessage';
    }

    /*
        We may get an rxLineError 65 when Patient is no longer covered by insurance, and will
        behave similar as rxLineError 70 and 40, se should try to get the birdi price first.
    */

    if (cartRx.rxDetails.rxLineError === RX_LINE_ERROR.NO_LONGER_COVERED) {
        /*
            If price is available via birdi price we should show:

            "Your insurance is no longer contracted with the pharmacy,
            but Birdi can still help you! You may continue with “The Cash Price”
            or contact support at {{- phoneNumber}} with questions."

            If Price is not available we will show the "Price Currently Unavailable" label
            along with the message:

            "We attempted to process your prescription request and the estimated
            cash price is currently unavailable. You may continue without the
            estimated cash price or remove the item and contact support at"
        */

        disclaimerKey = cartRx.cartPrice?.showBirdiCashPrice
            ? 'pages.cart.rxItemNoPharmacyContractErrorMessage'
            : 'pages.cart.rxItemCashPriceErrorMessage';
    }

    return disclaimerKey;
};

/**
 * Function that maps each prescription in the cart to get the
 * data that will be displayed in the cart but also to calculate
 * each Rx price
 *
 * @param {RefillRxs[]} cartItems
 * @param {PlansObjectPayload[]} familyPlanDetails
 * @param {DependentsPricingData[]} familyPricingDetails
 * @param {PrescriptionCardProps[]} prescriptionCards
 * @param {DrugWithDiscountPrice[]} drugDiscountPrices
 * @param {string} cartZipCode
 * @returns {CartOrderRx[]}
 */
export const mapCartRxs = (
    cartItems: RefillRxs[],
    familyPlanDetails: PlansObjectPayload[],
    familyPricingDetails: DependentsPricingData[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    cartZipCode?: string
): CartOrderRx[] => {
    // Ensure we have the data loaded in prescriptionDetail before trying to map it,
    // This will prevent to avoid errors when starting the cart while getCart API responds
    const cartRxs = cartItems.filter((cartRx: RefillRxs) => !!cartRx.prescriptionDetail);
    if (cartRxs.length === 0) return [];

    // Once we confirm we have the data needed for each Rx, then we process it
    const updatedCartRxs = cartRxs.map((cartRx: RefillRxs) => {
        // Find patient specific plans and pricing data
        const patientPlans = familyPlanDetails.filter(
            (plan) => plan && plan.epostPatientNum === cartRx.prescriptionDetail?.epostPatientNum
        );
        const patientPricingData = familyPricingDetails.find(
            (dependent) => dependent.epostPatientNum === cartRx.prescriptionDetail?.epostPatientNum
        );
        // Get primary plan alias
        const primaryPlanAlias = patientPricingData?.planAlias;
        // Identify if a primaryPlan is insurance
        const isPrimaryPlanInsurance = isPlanInsurance(primaryPlanAlias);
        const birdiPricePlanNum: string | undefined = getBirdiCashPricePlanNum(patientPlans);
        // Validate if user has active membership plan
        const isPatientMembership = patientHasMembership(
            cartRx.prescriptionDetail?.epostPatientNum,
            patientPricingData
        );
        // Get the isBirdiSelect from the rxCard
        const isBirdiSelect = (cartRx.prescriptionDetail?.isBirdiSelect && isPatientMembership) || false;
        // Verify if the Rx has auto refill on
        const autoRefillEnabled = cartRx.prescriptionDetail?.autoRefillEnabled || false;

        // Get birdi price from /medimpact-pricing API when loaded
        const birdiPrice = findBirdiPrice({
            prescription: cartRx.prescriptionDetail,
            familyPricingDetails,
            cartZipCode,
            cartItems,
            drugDiscountPrices,
            isCart: true
        });

        // Prepare initial object to be returned in the cart
        const updatedRx: CartOrderRx = {
            isPrimaryPlanInsurance,
            primaryPlanAlias,
            isPatientMembership,
            isBirdiSelect: isBirdiSelect,
            birdiPricePlanNum,
            autoRefillEnabled,
            rxDetails: {
                ...cartRx
            }
        };

        // Run the getCartRxPrice function to calculate
        // our price, this function will be responsible
        // to load everything needed according to the
        // rxLineError and to the corresponding plan
        updatedRx.cartPrice = getCartRxPrice(updatedRx, birdiPrice);
        updatedRx.disclaimerTranslationKey = getCartDisclaimer(updatedRx);

        return updatedRx;
    });

    return updatedCartRxs;
};

/**
 * Function that maps each patient in the cart to process its carts items
 *
 * @param {CartPayload[]} carts
 * @param {PlansObjectPayload[]} familyPlanDetails
 * @param {DependentsPricingData[]} familyPricingData
 * @param {PrescriptionCardProps[]} prescriptionCards
 * @param {FamilyAutoRefillData[]} familyAutoRefillData
 * @param {DrugWithDiscountPrice[]} drugDiscountPrices
 * @param {string | undefined} cartZipCode
 *
 * @returns {CartPatient}
 */
export const mapCartPatients = (
    cart: CartPayload,
    familyPlanDetails: PlansObjectPayload[],
    familyPricingData: DependentsPricingData[],
    familyAutoRefillData: FamilyAutoRefillData[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    cartZipCode?: string
): CartPatient => {
    const autoRefillPatientData = familyAutoRefillData.find(
        (member) => member.epostPatientNum === cart.EpostPatientNum
    );

    // Iterate each cart Rx and get rxCard data
    const cartRxs = mapCartRxs(
        cart.Order.refillRxs,
        familyPlanDetails,
        familyPricingData,
        drugDiscountPrices,
        cartZipCode
    );

    // Build the object data for each patient in the cart
    const cartPatient: CartPatient = {
        birdiOrderNumber: cart.BirdiOrderNumber,
        firstName: cart.FirstName,
        lastName: cart.LastName,
        epostPatientNum: cart.EpostPatientNum,
        planEligibleForAutoRefill: autoRefillPatientData?.planEligibleForAutoRefill,
        isCaregiver: cart.Type === 'Caregiver',
        order: { ...cart.Order, otcs: cart.Order.OTCs as OTCs[] },
        orderTotal: cartRxs.reduce((acc, rx) => (rx.cartPrice?.finalPrice ? acc + rx.cartPrice?.finalPrice : acc), 0),
        cartRxs: cartRxs
    };

    return cartPatient;
};

/**
 * Function that maps each dependents and iterates the validation
 * of each item in the cart to get the final cart object.
 *
 * @param {CartPayload[]} carts
 * @param {PlansObjectPayload[]} familyPlanDetails
 * @param {DependentsPricingData[]} familyPricingData
 * @param {PrescriptionCardProps[]} prescriptionCards
 * @param {FamilyAutoRefillData[]} familyAutoRefillData
 * @param {DrugWithDiscountPrice[]} drugDiscountPrices
 * @param {string} cartShippingMethod
 * @param {string | undefined} cartZipCode
 * @param {AddressPayload | null | undefined} cartAddress
 * @param {OrderBillShip | undefined} OrderBillShip
 * @param {CreditCardPayload | undefined} cartPaymentMethod
 * @returns {CartObject | undefined}
 */
export const getCartObject = (
    carts: CartPayload[],
    familyPlanDetails: PlansObjectPayload[],
    familyPricingData: DependentsPricingData[],
    familyAutoRefillData: FamilyAutoRefillData[],
    drugDiscountPrices: DrugWithDiscountPrice[],
    cartAddress?: AddressPayload | null,
    OrderBillShip?: OrderBillShip,
    cartPaymentMethod?: CreditCardPayload
): CartObject | undefined => {
    if (!carts) return;
    const cartShippingMethod = getCartShippingMethodId(carts);

    // Process the data for each patient that belongs the family
    const patients: CartPatient[] = carts.map(
        (cart: CartPayload): CartPatient =>
            mapCartPatients(
                cart,
                familyPlanDetails,
                familyPricingData,
                familyAutoRefillData,
                drugDiscountPrices,
                cartAddress?.zipcode
            )
    );

    // Getting the shipping price depending on user's selection
    const shipMethodPrice = cartShippingMethod === EXPEDITED_SHIPPING_ID ? EXPEDITED_SHIPPING_COST : 0;
    // Calculate subtotal by summing the order total of each patient in the cart
    const subTotal = patients.reduce((acc, patient) => patient.orderTotal + acc, 0);

    // Preparing final object
    const cartObject: CartObject = {
        cartPayload: carts,
        patients,
        subTotal: subTotal,
        // Gets the cart total summing the subtotal and the ship price
        total: subTotal + shipMethodPrice,
        address: cartAddress,
        orderAddress: OrderBillShip,
        paymentMethod: cartPaymentMethod,
        shipMethodId: cartShippingMethod,
        shipMethodPrice,
        // Verifies if any of the patient's cart items has unknown price
        itemHasUnknownPrice: patients.some((patient) => patient.cartRxs.some((rx) => !rx.cartPrice?.hasKnownPrice)),
        // Number of elements in the cart
        cartItems: patients?.reduce((total, cart) => {
            return total + cart?.cartRxs?.length;
        }, 0)
    };

    return cartObject;
};

/**
 * Get the list of rxs that are in the cart
 *
 * @param {CartObject} cart
 * @returns {CartOrderRx[]}
 */
export const getRxsListFromCart = (cart: CartObject): CartOrderRx[] => {
    return cart?.patients.flatMap((patient) => patient.cartRxs);
};

/**
 * Function that gets a cart object and map the patients to return
 * firstName, lastName and epostPatientNum for each patient.
 *
 * @param {CartObject | undefined} cart
 * @returns {PatientBasicData[]}
 */
export function getPatientsInCart(cart?: CartObject | undefined): PatientBasicData[] {
    return (
        cart?.patients
            .filter((patient: CartPatient) => patient.cartRxs.length > 0)
            .map((patient) => getBasicPatientData(patient)) || []
    );
}

/**
 * Function that verifies if an rxNumber exists in the cart.
 *
 * @param {string} rxNumber
 * @param {RefillRxs[]} cartItems
 * @returns {boolean}
 */
export const searchRxNumberInCart = (rxNumber: string, cartItems: RefillRxs[] = []): boolean =>
    cartItems.some((cartItem) => cartItem.rxNumber === rxNumber);
