import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TFunction } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Skeleton from 'ui-kit/utils/skeleton/skeleton';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import HealthProfileDisplay from 'components/health-profile/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import ProfileLayout from 'components/layouts/profile/profile.layout';

import { accountProfileSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { familyProfileIsLoadingSelector } from 'state/family-profile/family-profile.selectors';
import { medicalConditionsFetchHealthConditionsForPatientRoutine } from 'state/medical-conditions/medical-conditions.routines';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';

import { getPhoneNumber } from 'util/globalVariables';

import useHealthConditions from 'hooks/useHealthConditions';

import './health-profile.style.scss';

export const HealthConditionsWarningModalContent = ({ translation }: { translation: TFunction<'translation'> }) => {
    return (
        <BirdiModalContentAlt
            subTitle={translation('modals.prescription.addAllergy.subTitle', {
                phoneNumber: getPhoneNumber({})
            })}
        />
    );
};

const HealthProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const profileObject = useSelector(accountProfileSelector);
    const isLoadingAllergies = useSelector(isLoadingAllergiesSelector);
    const isLoadingHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const isLoadingFamilyAccount = useSelector(familyProfileIsLoadingSelector);
    const { getHealthConditionsByPatientNum } = useHealthConditions();

    const isMembershipHealthConditions = profileObject?.hasMembership;
    const [activeTab, setActiveTab] = useState('');
    const activeEpostPatientNumber = activeTab || profileObject?.epostPatientNum || '';

    const healthConditions = useMemo(
        () => getHealthConditionsByPatientNum(activeEpostPatientNumber),
        [activeEpostPatientNumber, getHealthConditionsByPatientNum]
    );

    useEffect(() => {
        if (!healthConditions && activeEpostPatientNumber) {
            dispatch(
                medicalConditionsFetchHealthConditionsForPatientRoutine.trigger({
                    ePostPatientNumber: activeEpostPatientNumber
                })
            );
        }
    }, [activeEpostPatientNumber, dispatch, healthConditions]);

    const handleUpdateConditionsClick = useCallback(
        ({ action, update }: HealthProfileBubbleUpdateEvent) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    className: 'modal-health-profile-warning',
                    headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                    bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                    ctas: [
                        {
                            label: t('modals.healthConditions.submit'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                dispatch(action(update));
                            },
                            dataGALocation: 'HealthProfileWarning'
                        }
                    ]
                })
            );
        },
        [dispatch, t]
    );

    const sections = useMemo(() => {
        if ((profileObject?.isCaregiver && isLoadingFamilyAccount) || !healthConditions) {
            const loadingSkeleton = {
                children: <Skeleton isLoading skeletonHeight={500} />,
                suppressChildrenContainer: true
            };

            return [loadingSkeleton];
        }

        const healthProfileSection = {
            heading: t('pages.profile.healthProfile.heading'),
            children: (
                <div className="health-profile-container">
                    <HealthProfileDisplay
                        onUpdateHealthConditions={handleUpdateConditionsClick}
                        healthConditions={healthConditions}
                        isMembershipHealthConditions={isMembershipHealthConditions}
                        isLoadingAllergies={isLoadingAllergies}
                        isLoadingHealthConditions={isLoadingHealthConditions}
                    />
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            activeTab: activeEpostPatientNumber,
            onTabItemChange: setActiveTab,
            showEveryone: false
        };

        return [healthProfileSection];
    }, [
        t,
        healthConditions,
        isMembershipHealthConditions,
        isLoadingAllergies,
        isLoadingHealthConditions,
        isLoadingFamilyAccount,
        activeEpostPatientNumber,
        handleUpdateConditionsClick,
        profileObject?.isCaregiver
    ]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.healthProfile.eyebrowText`)}
            title={t(`pages.profile.healthProfile.title`)}
            sections={sections}
        />
    );
};

export default HealthProfile;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
