import { CloseIconProps } from 'ui-kit/icons/close-icon/close-icon.props';

import './close-icon.style.scss';

const CloseIcon = ({ onClick, style, ariaLabel }: CloseIconProps) => {
    return (
        <div className="close-icon-container" style={style}>
            <svg
                className="close-icon"
                aria-label={ariaLabel}
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 30 30"
                onClick={onClick ?? undefined}
            >
                <g>
                    <ellipse fill="none" stroke="#FFFFFF" strokeWidth="2.3" cx="15" cy="15" rx="13.8" ry="13.8" />
                    <g>
                        <path
                            fill="none"
                            stroke="#FFFFFF"
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            strokeWidth="3.75"
                            d="M11 11.2l8 7.6"
                        />
                        <path
                            fill="none"
                            stroke="#FFFFFF"
                            strokeLinecap="round"
                            strokeLinejoin="miter"
                            strokeWidth="3.75"
                            d="M19 11.2l-8 7.6"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
};

export default CloseIcon;
