import { REACT_APP_QNACLIENTFILTER, REGISTRATION_DOMAIN } from 'gatsby-env-variables';

import { creditCardMonthOptions } from 'const/options';

import { getCreditCardEnding } from 'util/payments';

export interface accountFinalizeNotInsuranced {
    PlanAlias: string | null;
    CacheId: string | null;
    CacheIdMembership: string | null;
    PatientRegistration: {
        FirstName: string | null;
        MiddleName: string | null;
        LastName: string | null;
        BirthDate: string | null;
        GenderCode: string | null;
        epostPatientNum: number;
        FamilyId: number;
        AccountId: number;
        PatientStatusCode: null;
        PatientStatus: null;
        CardNumber: null;
        CoverageTypeId: number;
        CoverageType: null;
        RelationshipNumber: number;
        Relationship: null;
        Payor: null;
        PayorNum: number | null;
        PlanAlias: null;
        GroupId: null;
        GroupName: null;
        EmailAddress: string | null;
        PhoneSeqNum: number;
        PhoneTypeNum: number;
        PhoneType: null;
        AreaCode: null;
        PhoneNumber: string | null;
        Ext: null;
        AddressSeqNumb: number;
        AddressTypeNum: number;
        Address1: string | null;
        Address2: string | null;
        Address3: null;
        City: string | null;
        StateCode: string | null;
        StateNumber: number | null;
        ZipCode: string | null;
        Zip4: string | null;
        Country: null;
        CountryNumber: number | null;
        RecordLocation: null;
        HasUserProfile: number;
        UserName: null;
        WebId: null;
        IsMID: number;
        CoverageStartDate: null;
        CoverageEndDate: null;
        ReadHIPAA: null;
        AcceptedHIPAA: null;
        CacheId: null;
        RegCode: number | null;
        RaceId: string | null;
        EthnicityId: string | null;
        AutoRefill: string | null;
        PrimaryCareProvider: string | null;
        IsMedicareMedicaid: boolean;
        messageErrorText: string | null;
        messageStatus: boolean;
        messageText: string | null;
    };
    AccountSignup: {
        Email: string | null;
        UserName: string | null;
        Password: string | null;
    };
    MembershipAssociation: {
        PlanId: number;
        PromoCode: string | null;
        IsAnotheBillingAddress: string | null;
        IsPaymentFSAorHSA: string | null;
        IsAuthorizePayment: string | null;
        TypePlan: number | null;
        AnotheAddress: {
            active: boolean;
            address1: string | null;
            address2: string | null;
            address3: null;
            addressNote: null;
            addressSeqNum: number | null;
            addressTypeDesc: null;
            addressTypeNum: number | null;
            city: string | null;
            country: string | null;
            defaultBill: boolean;
            defaultPhoneNumber: null;
            defaultPhoneSeq: null;
            defaultShip: boolean;
            endDate: null;
            epostPatientNum: null;
            startDate: null;
            state: string | null;
            stateNum: null;
            zipcode: string | null;
            zipcodeFour: null;
            currentShipping: boolean;
        };
        PaymentCard: {
            fsaCard: string | null;
            cardActive: boolean;
            cardFirstName: string | null;
            cardLastName: string | null;
            cardMiddleName: string | null;
            defaultCard: boolean;
            epostPatientNum: null;
            forceApproval: boolean;
            cardMonthNum: string | null;
            cardMonthDesc: string | null;
            cardNumber: string | null;
            cardSeqNum: string | null;
            secureCardNumber: string | null;
            cardTypeNum: string | null;
            cardType: string | null;
            cardYear: string | null;
            pciToken: string | null;
            securityCode: string | null;
            cardExpiration: string | null;
            cardName: string | null;
            spendingLimit: string | null;
            cardIsExpired: boolean;
            messageErrorText: string | null;
            messageStatus: boolean;
            messageText: string | null;
        };
    };
    RetryError: number;
    messageErrorText: string;
    messageStatus: boolean;
    messageText: string;
    Domain: string;
    Payor: string;
}

export interface ValidateInviteMembershipPayload {
    firstName: string;
    lastName: string;
    birthDate: string;
    email: string;
}

export interface ValidateInviteMembershipResponse {
    FirstName: string;
    MiddleName: string;
    LastName: string;
    BirthDate: string;
    GenderCode: string;
    epostPatientNum: number;
    FamilyId: number;
    AccountId: number;
    PatientStatusCode: string;
    PatientStatus: string;
    CardNumber: string;
    CoverageTypeId: number;
    CoverageType: string;
    RelationshipNumber: number;
    Relationship: string;
    Payor: string;
    PayorNum: number;
    PlanAlias: string;
    GroupId?: string | number;
    GroupName: string;
    EmailAddress: string;
    PhoneSeqNum: number;
    PhoneTypeNum: number;
    PhoneType: string;
    AreaCode: string;
    PhoneNumber: string;
    Ext?: string | number;
    AddressSeqNumb: number;
    AddressTypeNum: number;
    Address1: string;
    Address2?: string;
    Address3?: string;
    City: string;
    StateCode: string;
    StateNumber: number;
    ZipCode: string;
    Zip4?: string;
    Country: string;
    CountryNumber: number;
    RecordLocation: string;
    HasUserProfile: number;
    UserName?: string;
    WebId?: number;
    IsMID: number;
    CoverageStartDate: string;
    CoverageEndDate?: string;
    ReadHIPAA?: string;
    AcceptedHIPAA?: string;
    CacheId: string;
    RegCode: number;
    RaceId?: number;
    EthnicityId?: number;
    AutoRefill?: boolean;
    PrimaryCareProvider?: string | number;
    IsMedicareMedicaid?: number;
    messageErrorText?: string;
    messageStatus: boolean;
    messageText?: string;
}

export enum TypePlan {
    // eslint-disable-next-line no-unused-vars
    Monthly = 0,
    // eslint-disable-next-line no-unused-vars
    Yearly = 1,
    // eslint-disable-next-line no-unused-vars
    Quarter = 2
}

/**
 * @deprecated Use src/state/account/account.helpers.ts:getMiddleNames instead.
 */
export const getMiddleNames = (names: []): string => {
    if (names.length < 2) {
        return '';
    } else {
        return names
            .map((name, index) => {
                return index !== 0 && index !== names.length - 1 ? name : '';
            })
            .join(' ');
    }
};

export const getMonthNum = (month: string) => {
    return (creditCardMonthOptions.map((month) => month.value).indexOf(month) + 1).toString();
};

/**
 * @deprecated Use src/state/account/account.helpers.ts:getCardExpiration instead.
 */
export const getCardExpiration = (month: string, year: string) => {
    return `${getMonthNum(month)?.toString().padStart(2, '0')}/${year.toString()}`;
};

/**
 * @deprecated Use src/util/payments.ts:detectCardType instead.
 */
export const detectCardType = (cardNumber: string) => {
    const re = {
        visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
        mastercard: /^5[1-5][0-9]{14}$/,
        discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        amex: /^3[47][0-9]{13}$/
    };

    for (const key in re) {
        if (re[key].test(parseInt(cardNumber))) {
            const cardKeys = Object.keys(re);
            return { key, index: (cardKeys.indexOf(key) + 1).toString() };
        }
    }
};

/**
 * @deprecated Use src/state/account/account.helpers.ts:getTypePlanFromString instead.
 */
export function getTypePlanFromString(typePlanString: string): number {
    switch (typePlanString) {
        case 'Monthly':
            return TypePlan.Monthly;
        case 'Yearly':
            return TypePlan.Yearly;
        case 'Quarter':
            return TypePlan.Quarter;
        default:
            return TypePlan.Monthly;
    }
}

export function formatAccountFinalizeNotInsurancedData(
    formValues: any,
    responseErrorPayload: any
): accountFinalizeNotInsuranced {
    const nameOnCard = formValues?.nameOnCard.split(' ');

    const birthDate = formValues?.dobYear
        ? `${formValues?.dobYear}-${formValues?.dobMonth}-${formValues?.dobDay.toString().padStart(2, '0')}`
        : '';

    return {
        PlanAlias: 'BRD02',
        CacheId: responseErrorPayload?.CacheId || '',
        CacheIdMembership: responseErrorPayload?.CacheIdMembership || '',
        PatientRegistration: {
            FirstName: formValues?.firstName,
            MiddleName: responseErrorPayload?.PatientRegistration?.MiddleName || '',
            LastName: formValues?.lastName,
            BirthDate: birthDate,
            GenderCode: formValues?.gender,
            epostPatientNum: responseErrorPayload?.PatientRegistration?.epostPatientNum || -1,
            FamilyId: responseErrorPayload?.PatientRegistration?.FamilyId || -1,
            AccountId: responseErrorPayload?.PatientRegistration?.AccountId || -1,
            PatientStatusCode: responseErrorPayload?.PatientRegistration?.PatientStatusCode || null,
            PatientStatus: responseErrorPayload?.PatientRegistration?.PatientStatus || null,
            CardNumber: responseErrorPayload?.PatientRegistration?.CardNumber || null,
            CoverageTypeId: responseErrorPayload?.PatientRegistration?.CoverageTypeId || -1,
            CoverageType: responseErrorPayload?.PatientRegistration?.CoverageType || null,
            RelationshipNumber: responseErrorPayload?.PatientRegistration?.RelationshipNumber || -1,
            Relationship: responseErrorPayload?.PatientRegistration?.Relationship || null,
            Payor: responseErrorPayload?.PatientRegistration?.Payor || null,
            PayorNum: responseErrorPayload?.PatientRegistration?.PayorNum || null,
            PlanAlias: responseErrorPayload?.PatientRegistration?.PlanAlias || null,
            GroupId: responseErrorPayload?.PatientRegistration?.GroupId || null,
            GroupName: responseErrorPayload?.PatientRegistration?.GroupName || null,
            EmailAddress: formValues?.email,
            PhoneSeqNum: responseErrorPayload?.PatientRegistration?.PhoneSeqNum || -1,
            PhoneTypeNum: 1,
            PhoneType: responseErrorPayload?.PatientRegistration?.PhoneType || null,
            AreaCode: responseErrorPayload?.PatientRegistration?.AreaCode || null,
            PhoneNumber: formValues?.phoneNumber,
            Ext: responseErrorPayload?.PatientRegistration?.Ext || null,
            AddressSeqNumb: responseErrorPayload?.PatientRegistration?.AddressSeqNumb || -1,
            AddressTypeNum: 1,
            Address1: formValues?.address1,
            Address2: formValues?.address2,
            Address3: responseErrorPayload?.PatientRegistration?.Address3 || null,
            City: formValues?.city,
            StateCode: formValues?.state,
            StateNumber: responseErrorPayload?.PatientRegistration?.StateNumber || null,
            ZipCode: formValues?.zipcode,
            Zip4: formValues?.zip4,
            Country: responseErrorPayload?.PatientRegistration?.Country || null,

            CountryNumber: formValues?.state === 'PR' ? 3 : 1,
            RecordLocation: responseErrorPayload?.PatientRegistration?.RecordLocation || null,
            HasUserProfile: responseErrorPayload?.PatientRegistration?.HasUserProfile || -1,
            UserName: responseErrorPayload?.PatientRegistration?.UserName || null,
            WebId: responseErrorPayload?.PatientRegistration?.WebId || null,
            IsMID: responseErrorPayload?.PatientRegistration?.IsMID || 0,
            CoverageStartDate: responseErrorPayload?.PatientRegistration?.CoverageStartDate || null,
            CoverageEndDate: responseErrorPayload?.PatientRegistration?.CoverageEndDate || null,
            ReadHIPAA: responseErrorPayload?.PatientRegistration?.ReadHIPAA || null,
            AcceptedHIPAA: responseErrorPayload?.PatientRegistration?.AcceptedHIPAA || null,
            CacheId: responseErrorPayload?.PatientRegistration?.CacheId || null,
            RegCode: responseErrorPayload?.PatientRegistration?.RegCode || null,
            RaceId: formValues?.race,
            EthnicityId: formValues?.ethnicity,
            AutoRefill: formValues?.autoRefill,
            PrimaryCareProvider: formValues?.careProvider,
            IsMedicareMedicaid: false,
            messageErrorText: responseErrorPayload?.PatientRegistration?.messageErrorText || null,
            messageStatus: responseErrorPayload?.PatientRegistration?.messageStatus || true,
            messageText: responseErrorPayload?.PatientRegistration?.messageText || null
        },
        AccountSignup: {
            Email: formValues?.email,
            UserName: formValues?.username,
            Password: formValues?.password
        },
        MembershipAssociation: {
            PlanId: formValues?.membershipPlanId,
            PromoCode: formValues?.promoCode,
            IsAnotheBillingAddress: formValues?.alternateBillingAddress || false,
            IsPaymentFSAorHSA: formValues?.fsaPaymentMethod || false,
            IsAuthorizePayment: formValues?.isAuthorizePayment || false,
            TypePlan: getTypePlanFromString(formValues?.typePlan),
            AnotheAddress: {
                active: true,
                address1: formValues?.anotherAddress?.address1 || null,
                address2: formValues?.anotherAddress?.address2 || null,
                address3: responseErrorPayload?.MembershipAssociation?.AnotheAddress.address3 || null,
                addressNote: responseErrorPayload?.MembershipAssociation?.AnotheAddress.addressNote || null,
                addressSeqNum: responseErrorPayload?.MembershipAssociation?.AnotheAddress.addressSeqNum || -1,
                addressTypeDesc: responseErrorPayload?.MembershipAssociation?.AnotheAddress.addressTypeDesc || null,
                addressTypeNum: 1,
                city: formValues?.anotherAddress?.city || null,
                country: 'USA',
                defaultBill: true,
                defaultPhoneNumber:
                    responseErrorPayload?.MembershipAssociation?.AnotheAddress.defaultPhoneNumber || null,
                defaultPhoneSeq: responseErrorPayload?.MembershipAssociation?.AnotheAddress.defaultPhoneSeq || null,
                defaultShip: false,
                endDate: responseErrorPayload?.MembershipAssociation?.AnotheAddress.endDate || null,
                epostPatientNum: responseErrorPayload?.MembershipAssociation?.AnotheAddress.epostPatientNum || null,
                startDate: responseErrorPayload?.MembershipAssociation?.AnotheAddress.startDate || null,
                state: formValues?.anotherAddress?.state || null,
                stateNum: responseErrorPayload?.MembershipAssociation?.AnotheAddress.stateNum || null,
                zipcode: formValues?.anotherAddress?.zipcode || null,
                zipcodeFour: responseErrorPayload?.MembershipAssociation?.AnotheAddress.zipcodeFour || null,
                currentShipping: true
            },
            PaymentCard: {
                fsaCard: formValues?.fsaPaymentMethod || false,
                cardActive: true,
                cardFirstName: `${nameOnCard[0]}`,
                cardLastName: `${nameOnCard[nameOnCard.length - 1]}`,
                cardMiddleName: `${getMiddleNames(nameOnCard)}`,
                defaultCard: true,
                epostPatientNum: null,
                forceApproval: true,
                cardMonthNum: `${getMonthNum(formValues.expMonth)}`,
                cardMonthDesc: '',
                cardNumber: formValues?.pciToken,
                cardSeqNum: '-1',
                secureCardNumber: `${getCreditCardEnding(formValues.cardNumber, '**************')}`,
                cardTypeNum: `${detectCardType(formValues.cardNumber)?.index || ''}`,
                cardType: `${detectCardType(formValues.cardNumber)?.key || ''}`,
                cardYear: formValues.expYear.toString(),
                pciToken: formValues?.pciToken,
                cardExpiration: getCardExpiration(formValues.expMonth, formValues.expYear),
                cardName: formValues?.nameOnCard,
                cardIsExpired: true,
                messageErrorText: '',
                messageStatus: true,
                messageText: '',
                spendingLimit: '',
                securityCode: ''
            }
        },
        RetryError: responseErrorPayload?.RetryError || 0,
        messageErrorText: '',
        messageStatus: true,
        messageText: '',
        Payor: REACT_APP_QNACLIENTFILTER,
        Domain: REGISTRATION_DOMAIN
    };
}
