import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ModalComponentProps } from 'components/modal/modal.props';

import { noop } from 'util/function';

const initialState: ModalComponentProps = {
    show: false,
    title: undefined,
    subtitle: undefined,
    variation: 'small',
    sideView: undefined,
    badge: undefined,
    content: undefined,
    isCloseable: true,
    isCentered: false,
    customDialogClassName: '',
    continueButtonLabel: 'Save',
    cancelButtonLabel: 'Cancel',
    hasTitleNavigation: false,
    footerLayout: 'horizontal',
    hasCustomContent: false,
    hasDefaultFooter: true,
    backdrop: true,
    hasDefaultTitle: true,
    onNavigate: () => noop,
    onClose: () => noop,
    onCancel: () => noop,
    onContinue: () => noop,
    isLoading: false,
    modalStep: 0,
    openedModals: []
};

const modalComponentSlice = createSlice({
    name: 'modal-component',
    initialState,
    reducers: {
        openModalComponent(state, action: PayloadAction<Partial<ModalComponentProps>>) {
            return {
                ...state,
                ...action.payload,
                show: true,
                isLoading: false,
                openedModals: [...state.openedModals, action.payload]
            };
        },
        closeModalComponent(state) {
            return { ...state, show: false, isLoading: false, openedModals: state.openedModals.slice(0, -1) };
        },
        setModalLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        setModalStep(state, action: PayloadAction<Partial<ModalComponentProps>>) {
            return { ...state, modalStep: action.payload.modalStep, show: true, isLoading: false };
        }
    }
});

export const { openModalComponent, closeModalComponent, setModalLoading, setModalStep } = modalComponentSlice.actions;
export default modalComponentSlice.reducer;
