import { createSelector } from '@reduxjs/toolkit';

import { getCurrentCardOrderBillship, getCurrentCartAddress, getCurrentCartCard } from 'state/cart/cart.helpers';
import { getPatientCart } from 'state/cart/cart.helpers';
import { drugSelector, drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import { RootState } from 'state/store';

import { AddressPayload } from 'types/account';
import { CartObject, CartOrderRx, CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { DependentsPricingData, FamilyAutoRefillData } from 'types/family-account';
import { OrderBillShip } from 'types/order-prescription';
import { PlansObjectPayload } from 'types/plans';
import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';

import { DEFAULT_SHIPPING_ID, getCartObject } from 'util/cart';
import {
    mapEasyRefillFamilyAutoRefillData,
    mapEasyRefillFamilyMembers,
    mapEasyRefillFamilyPricingData
} from 'util/easy-refill';
import { getBasicPatientData } from 'util/family-account';
import { mapRxPrescriptionCards } from 'util/prescription';

import { transformCartPayloadToEasyRefill } from './easy-refill.helpers';
import { EasyRefillState } from './easy-refill.reducer';

export const easyRefillSelector = (state: RootState) => state.easyRefillReducer;

export const easyRefillCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.cart || [];
});

export const easyRefillEpostPatientNumSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.epostPatientNum;
});
// User Verification
export const easyRefillUserVerificationFailedSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.userVerified !== 'UserVerified';
    }
);

export const isLoadingCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return !!easyRefill.isLoadingCart;
});

export const isLoadingPrescriptionsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.isLoadingPrescriptions;
});

export const easyRefillIsLoadingHealthConditionsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.isLoadingHealthConditions;
    }
);

export const easyRefillUserVerifiedSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.userVerified === 'UserVerified';
});

export const easyRefillSecurityTokenSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.securityToken;
});

export const easyRefillSecretKeyHashSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.secretKeyHash;
});

export const easyRefillErrorMessageSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.error.messageText ?? '';
});

export const easyRefillUserBearerTokenSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.bearerToken;
});

export const easyRefillFirstNameSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.firstName;
});

// Patient Data

export const easyRefillPatientAddressSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    let addr = null;

    const easyRefillAddressTypeNum1 = easyRefill.addresses.some(
        (x) => ((x.defaultShip ?? false) || x.addressTypeNum === '1') && (x.active ?? true)
    )
        ? easyRefill.addresses.find(
              (x) => ((x.defaultShip ?? false) || x.addressTypeNum === '1') && (x.active ?? true)
          ) || null
        : easyRefill.addresses.find((x) => x.active ?? true) || null;

    if (easyRefill.addresses.filter((x) => x.active ?? false).length === 1) {
        addr = easyRefill.addresses.find((x) => x.active ?? false) || null;
    } else if (easyRefill.addresses.some((x) => x.addressTypeNum === '5' && (x.active ?? false))) {
        const addrs = easyRefill.addresses.filter((x) => x.addressTypeNum === '5' && (x.active ?? false));

        const outAddrs = addrs.filter((a) => {
            const sd = new Date(a.startDate || '');
            const ed = new Date(a.endDate || '');

            return (
                isNaN(sd.getTime()) ||
                isNaN(ed.getTime()) ||
                (sd <= new Date() && ed >= new Date(new Date().setDate(new Date().getDate() - 1)))
            );
        });

        if (outAddrs.length > 0) {
            addr = outAddrs[outAddrs.length - 1];
        } else addr = easyRefillAddressTypeNum1;
    } else addr = easyRefillAddressTypeNum1;

    return addr;
});

export const easyRefillIsCaregiverSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.isCaregiver;
});

export const easyRefillPlanNumberSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.epostPlanNum;
});

export const easyRefillPlanAliasSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.planAlias;
});

export const easyRefillAdjudicatedRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.adjudicatedRxes;
});

export const easyRefillRxsLoadedSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.prescriptionsLoaded;
});

export const easyRefillAccountHasInsuranceSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.planAlias !== 'BRD01' && easyRefill.planAlias !== 'BRD02';
    }
);

export const easyRefillPatientAutoRefillSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.patientAutoRefill;
});

// AutoRefill

export const easyRefillEligibleRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    const eligibleRxs = easyRefill.autoRefillFlags.map((rx) => rx.rxNumber);
    return easyRefill.rxResults.filter((rx) => eligibleRxs.includes(rx.rxNumber));
});

export const easyRefillEligibleRxsCaregiverSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const eligibleRxs = easyRefill.familyDependents
            .flatMap((item) => item.autoRefillFlags)
            .filter((item) => !!item)
            .map((rx) => rx.rxNumber);
        return easyRefill.familyDependents
            .flatMap((item) => item.rxResults)
            .filter((item) => !!item)
            .filter((rx) => eligibleRxs.includes(rx.rxNumber));
    }
);

export const easyRefillBusySelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.isBusy
);

export const easyRefillRemoveCartBtnDisabled = createSelector(
    easyRefillSelector,
    (medicineCabinet) => medicineCabinet.isRemoveDisabled
);

export const autoRefillFlagsSelector = createSelector(easyRefillSelector, (autoRefill) => autoRefill.autoRefillFlags);

export const easyRefillAutoRefillStatusSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.autoRefill;
});

// Address

export const easyRefillLoadingAddressSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingAddress;
});

export const easyRefillAddressesSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.addresses;
});

export const easyRefillStateAddOrUpdateAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.addressToAddOrUpdate;
    }
);

export const easyRefillPatientBillAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const addresses = easyRefill.addresses;
        return addresses.find((address) => address.defaultBill === true);
    }
);

export const easyRefillPatientDefaultAddressSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        const addresses = easyRefill.addresses;
        return addresses.find((address) => address.defaultShip === true);
    }
);

export const easyRefillPatientShipAddressZipCodeSelector = createSelector(
    easyRefillPatientDefaultAddressSelector,
    (address) => address?.zipcode
);

export const easyRefillAccountIsCaliforniaUserSelector = createSelector(
    easyRefillPatientDefaultAddressSelector,
    (easyRefillDefaultAddress) => {
        if (!easyRefillDefaultAddress) {
            return false;
        }
        return easyRefillDefaultAddress.state === 'CA';
    }
);

// Payment Cards

export const easyRefillLoadingPaymentCardSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.loadingPaymentCard;
    }
);

export const easyRefillPaymentCardsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.paymentCards.filter((card) => {
        return !card.cardIsExpired;
    });
});

export const easyRefillDefaultPaymentCardSelector = createSelector(
    [easyRefillPaymentCardsSelector],
    (paymentCards: CreditCardPayload[]) => paymentCards.find((paymentCard) => paymentCard.defaultCard === true)
);

export const easyRefillSelectedCardSelector = createSelector(
    [easyRefillSelector, easyRefillDefaultPaymentCardSelector],
    (easyRefill: EasyRefillState, defaultPaymentCard: CreditCardPayload | undefined) => {
        const cartPayment = easyRefill.cart?.[0]?.Order?.orderBillShip?.paymentCardSeqNum;

        if (cartPayment) {
            const selectedCard = easyRefill?.paymentCards.find((card) => card.cardSeqNum.toString() === cartPayment);

            return selectedCard;
        }

        return defaultPaymentCard;
    }
);

export const easyRefillPaymetricDetailsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.paymetricDetails
);

export const easyRefillRxsToRefillSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.refillOrder.rxsToRefill;
});

export const easyRefillOrderNumberSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.orderNumber;
});

export const easyRefillOrderInvoiceNumberSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        let cartObject: any = easyRefill.cart;

        if (easyRefill.isCaregiver) {
            cartObject = cartObject?.filter((item: any) => {
                return item.type === 'Caregiver';
            });
        }

        cartObject = cartObject?.map((cart: any) => cart.order.orderHeader);

        return cartObject ? cartObject[0]?.orderInvoiceNumber : null;
    }
);

export const easyRefillLoadingCartSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingCart;
});

// RXs Data

export const easyRefillLoadingRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.loadingPrescriptions;
});

export const easyRefillRxsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.rxResults;
});

export const easyRefillRxsToOrderSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    const rxs = easyRefill.refillOrder.rxsToRefill;
    return easyRefill.rxResults.filter((rxResult) => rxs.includes(rxResult.rxNumber));
});

export const easyRefillHealthConditionsSelector = createSelector(
    easyRefillSelector,
    ({ healthConditions }: EasyRefillState) => ({
        healthConditions
    })
);

export const easyRefillAllergiesSelector = createSelector(
    easyRefillSelector,
    (healthConditions) => healthConditions.healthConditions.allergies
);

export const easyRefillFamilyDependentsSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.familyDependents;
});

export const easyRefillFamilyPlansMapSelector = createSelector(easyRefillSelector, (easyRefill: EasyRefillState) => {
    return easyRefill.familyDependents.reduce((acc, item) => {
        return {
            ...acc,
            [item.epostPatientNum]: item.planAlias
        };
    }, {});
});

export const easyRefillFamilyDependentsPrescriptionsSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => {
        return easyRefill.familyDependents.flatMap((item) => item.rxResults).filter((item) => !!item);
    }
);

export const easyRefillCartItemsSelector = createSelector(easyRefillSelector, (state) =>
    state.cart?.flatMap((cart) => cart.Order.refillRxs).filter((item) => !!item)
);

export const easyRefillOrderAddressSelector = createSelector(
    [easyRefillCartSelector, easyRefillAddressesSelector],
    (carts, addresses) => {
        if (!carts || !addresses) return undefined;
        return getCurrentCartAddress(carts, addresses);
    }
);

export const easyRefillOrderBillShipSelector = createSelector([easyRefillCartSelector], (carts) =>
    getCurrentCardOrderBillship(carts)
);

export const easyRefillOrderBillShipMethodSelector = createSelector(
    [easyRefillOrderBillShipSelector],
    (orderBillShip) => {
        return orderBillShip?.shipMethodId || DEFAULT_SHIPPING_ID;
    }
);

export const easyRefillToggleBusySelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.isToggleBusy
);

export const easyRefillPlansSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.patientPlans
);

export const easyRefillCartZipCodeSelector = createSelector(
    [easyRefillBusySelector, easyRefillOrderAddressSelector],
    (cartIsBusy, orderBillShip) => {
        if (cartIsBusy) return;
        return orderBillShip?.zipcode;
    }
);

export const easyRefillFamilyMembersSelector = createSelector(
    [easyRefillFamilyDependentsSelector],
    (easyRefillFamilyDependents) => {
        if (easyRefillFamilyDependents.length > 0) {
            const familyMembers = mapEasyRefillFamilyMembers(easyRefillFamilyDependents, true);
            return familyMembers;
        } else {
            return null;
        }
    }
);

export const easyFamilyMembersBasicDataSelector = createSelector(
    [easyRefillFamilyDependentsSelector],
    (easyRefillFamilyDependents) => {
        return easyRefillFamilyDependents.map((patient) => getBasicPatientData(patient));
    }
);

export const easyRefillFamilyMembersPricingDataSelector = createSelector(
    [
        easyRefillFamilyDependentsSelector,
        easyRefillPlanAliasSelector,
        easyRefillPatientShipAddressZipCodeSelector,
        easyRefillEpostPatientNumSelector
    ],
    (easyRefillFamilyDependents, planAlias, mainUserZipCode, epostPatientNum) =>
        mapEasyRefillFamilyPricingData({
            members: easyRefillFamilyDependents,
            mainUserPlanAlias: planAlias,
            mainUserEpostPatientNum: epostPatientNum,
            mainUserZipCode
            // TODO: For single users we are not yet receiving the
            // membership type id, this will be worked in another ticket
        })
);

export const easyRefillIsMembershipSelector = createSelector(easyRefillPlanAliasSelector, (planAlias) => {
    //  TODO: Consider the replace this with patientHasMembership function in this validation, when we have the
    // plan type available for single users in easy refill.
    return planAlias === BIRDI_PLANS.BRD_02;
});

export const easyRefillActiveTabSelector = createSelector(easyRefillSelector, (easyRefill) => {
    return easyRefill.activeTab;
});

export const easyRefillPrescriptionsCardsSelector = createSelector(
    [
        easyRefillFamilyDependentsSelector,
        easyRefillRxsSelector,
        easyRefillFamilyMembersPricingDataSelector,
        easyRefillCartItemsSelector,
        easyRefillCartZipCodeSelector,
        easyRefillPatientShipAddressZipCodeSelector,
        easyRefillActiveTabSelector,
        easyRefillIsCaregiverSelector,
        easyRefillEpostPatientNumSelector,
        drugSelector
    ],
    (
        familyData,
        allRxs,
        familyPricingData,
        cartItems,
        cartZipCode,
        mainUserZipCode,
        activeTab,
        isCaregiver,
        epostPatientNum,
        { drugDiscountPrices }
    ) => {
        let prescriptionCards: RxDetails[] = [];

        if (isCaregiver) {
            const activeTabData = familyData.find((dependent) => dependent.epostPatientNum === activeTab);
            if (!!activeTabData && activeTabData?.rxResults.length > 0) {
                prescriptionCards = activeTabData.rxResults;
            }
        } else {
            prescriptionCards = allRxs.map((prescription) => ({ ...prescription, epostPatientNum: epostPatientNum }));
        }

        return mapRxPrescriptionCards(
            prescriptionCards,
            familyPricingData,
            drugDiscountPrices,
            cartItems,
            null,
            mainUserZipCode,
            cartZipCode
        );
    }
);

export const easyRefillFamilyMembersEligibilitySelector = createSelector(
    [easyRefillFamilyDependentsSelector],
    (familyData) => {
        return mapEasyRefillFamilyAutoRefillData(familyData);
    }
);

export const easyRefillCartProcessedSelector = createSelector(
    [
        easyRefillCartSelector,
        easyRefillPlansSelector,
        easyRefillFamilyMembersPricingDataSelector,
        easyRefillFamilyMembersEligibilitySelector,
        drugsWithDiscountSelector,
        easyRefillOrderAddressSelector,
        easyRefillOrderBillShipSelector,
        easyRefillSelectedCardSelector
    ],
    (
        carts: CartPayload[] | undefined,
        familyPlanDetails: PlansObjectPayload[],
        familyPricingData: DependentsPricingData[],
        familyAutoRefillData: FamilyAutoRefillData[],
        drugDiscountPrices: DrugWithDiscountPrice[],
        cartAddress?: AddressPayload | null,
        OrderBillShip?: OrderBillShip,
        cartPaymentMethod?: CreditCardPayload
    ) => {
        if (!carts) return;
        return getCartObject(
            carts || [],
            familyPlanDetails,
            familyPricingData,
            familyAutoRefillData,
            drugDiscountPrices,
            cartAddress,
            OrderBillShip,
            cartPaymentMethod
        );
    }
);

export const easyRefillMainCartSelector = createSelector(
    [easyRefillCartSelector, easyRefillEpostPatientNumSelector],
    (carts: CartPayload[] | undefined, mainUserEpostPatientNum: string) => {
        const mainCart = getPatientCart(carts, mainUserEpostPatientNum);
        if (!mainCart) return;
        return transformCartPayloadToEasyRefill(mainCart);
    }
);

export const easyRefillCartProcessedRxsSelector = createSelector(
    [easyRefillCartProcessedSelector],
    (cart: CartObject | undefined) => cart?.patients.flatMap((patient) => patient.cartRxs) || []
);

export const easyRefillCartTotalSelector = createSelector(
    [easyRefillCartProcessedSelector],
    (cart: CartObject | undefined) => cart?.total || 0
);

export const easyRefillCartSubtotalSelector = createSelector(
    [easyRefillCartProcessedSelector],
    (cart: CartObject | undefined) => cart?.subTotal || 0
);

export const easyRefillAllRxsSelector = createSelector(
    [easyRefillIsCaregiverSelector, easyRefillRxsSelector, easyRefillFamilyDependentsPrescriptionsSelector],
    (isCaregiver: boolean, prescriptions: RxDetails[], prescriptionsFamily: RxDetails[]) =>
        isCaregiver ? prescriptionsFamily : prescriptions
);

export const easyRefillOrderPaymentCardSelector = createSelector(
    [easyRefillPaymentCardsSelector, easyRefillCartSelector, easyRefillCartProcessedRxsSelector],
    (
        cards: CreditCardPayload[] | undefined,
        carts: CartPayload[] | undefined,
        cartItems: CartOrderRx[] | undefined
    ): CreditCardPayload | undefined => {
        if (cartItems?.length === 0 || !cards || !carts) return;
        return getCurrentCartCard(carts, cards);
    }
);

export const easyRefillisLoadingPlansSelector = createSelector(
    easyRefillSelector,
    (easyRefill: EasyRefillState) => easyRefill.loadingPlans
);
