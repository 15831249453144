import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ToastBox from 'ui-kit/toast-box/toast-box';

// Components & UI
import { CartShipping } from 'components/cart/cart-shipping.component';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { getCartRoutine } from 'state/cart/cart.routines';

// Cart
import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_ID } from 'util/cart';

import useCart from 'hooks/useCart';

import './shipping-method.style.scss';

const ShippingMethod: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, changeCartShippingMethod } = useCart();
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    const showShippingError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.shippingOption')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleShippingMethodSelection = (shipMethodId: string) => {
        changeCartShippingMethod({
            shipMethodId,
            onSuccess: () => {
                dispatch(getCartRoutine.trigger());
                columnSectionRef.current?.handleSaveChangesButtonClick();
            },
            onFailure: () => {
                showShippingError();
                dispatch(getCartRoutine.trigger());
                return false;
            }
        });
    };

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            bodyClassName="shipping-method__content"
            className="shipping-method"
            editModeContent={
                <CartShipping
                    handleDefaultShippingClick={() => {
                        handleShippingMethodSelection(DEFAULT_SHIPPING_ID);
                    }}
                    handleExpeditedShippingClick={() => {
                        handleShippingMethodSelection(EXPEDITED_SHIPPING_ID);
                    }}
                />
            }
            headerClassName="shipping-method__header"
            title={t('components.medicineCabinetCart.shippingMethod.title')}
        >
            <>
                <p className="shipping-method__selected">
                    {t(
                        `components.medicineCabinetCart.shippingMethod.${
                            cartData?.shipMethodId === EXPEDITED_SHIPPING_ID ? 'expeditedShipping' : 'freeShipping'
                        }`
                    )}
                </p>
                <ToastBox icon="default" variant="info">
                    {t('components.medicineCabinetCart.shippingMethod.footer')}
                </ToastBox>
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default ShippingMethod;
