import { PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { AWS_CONFIG_POOLID, AWS_CONFIG_REGION } from 'gatsby-env-variables';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';

import {
    accountAcCodeSelector,
    accountDefaultAddressZipCodeSelector,
    accountIsLoggedInSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import AccountService, { ProfileObjectPayload } from 'state/account/account.services';
import { addTransferPrescriptionMemberSelector } from 'state/add-transfer-prescription/add-transfer-prescription.selectors';
import {
    autoRefillAcCodeSelector,
    autoRefillFamilyMembersPricingDataSelector,
    autoRefillSelector,
    autoRefillZipCodeSelector
} from 'state/auto-refill/auto-refill.selectors';
import { cartItemsSelector, cartZipCodeSelector } from 'state/cart/cart.selectors';
import { setLoadingDrugPrices } from 'state/drug/drug.reducers';
import {
    drugDescriptionRoutine,
    drugDetailsLookupRoutine,
    drugDiscountPriceApiRoutine,
    drugDiscountPriceRoutine,
    drugFormLookupRoutine,
    drugListRoutine,
    drugLookupRoutine
} from 'state/drug/drug.routines';
import { drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import DrugService, {
    drugDescriptionRoutinePayload,
    drugDetailsRoutinePayload,
    drugDiscountPriceAPIRoutinePayload,
    DrugDiscountPriceResponse,
    drugDiscountPriceRoutinePayload,
    drugListRoutinePayload,
    drugLookupRoutinePayload
} from 'state/drug/drug.services';
import {
    easyRefillCartItemsSelector,
    easyRefillCartZipCodeSelector,
    easyRefillFamilyMembersPricingDataSelector,
    easyRefillPatientShipAddressZipCodeSelector,
    easyRefillPlanAliasSelector
} from 'state/easy-refill/easy-refill.selectors';
import { familyMembersPricingDataSelector } from 'state/family-profile/family-profile.selectors';

import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';
import { PRICING_ACCESS_TYPE, PRICING_API_LOCATION, PRICING_UNAUTH_AREA } from 'enums/pricing';

import { findDrugInList, getDrugLookupData, getDrugPricingPlanAlias, processDiscountPrice } from 'util/drug';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { doesPlanAllowsPricing, shouldPlanShowPrice } from 'util/pricing';
import { baseEffectHandler } from 'util/sagas/sagas';

export default function* drugSaga() {
    yield takeLatest(drugListRoutine.TRIGGER, function* (action: PayloadAction<drugListRoutinePayload>) {
        const ownerPlan: BIRDI_PLANS = yield select(accountAcCodeSelector);
        const selectedMember: string = yield select(addTransferPrescriptionMemberSelector);
        const mainUserEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
        const epostPatientNum = selectedMember || mainUserEpostPatientNum;

        const familyPricingDetails: DependentsPricingData[] = yield select(familyMembersPricingDataSelector);
        const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
        const selectedMemberPlan = getDrugPricingPlanAlias(pricingDetails);
        const planAlias = selectedMemberPlan || ownerPlan;
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugList().get,
            data: {
                clientAcCode: planAlias
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugListRoutine.success({ insuranceName: planAlias, formulary: response }));
                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugListRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugListRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugLookupRoutine.TRIGGER, function* (action: PayloadAction<drugLookupRoutinePayload>) {
        const ownerPlan: BIRDI_PLANS = yield select(accountAcCodeSelector);
        const selectedMember: string = yield select(addTransferPrescriptionMemberSelector);
        const mainUserEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
        const epostPatientNum = selectedMember || mainUserEpostPatientNum;
        const familyPricingDetails: DependentsPricingData[] = yield select(familyMembersPricingDataSelector);
        const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
        const selectedMemberPlan = getDrugPricingPlanAlias(pricingDetails);
        const planAlias = selectedMemberPlan || ownerPlan;
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now perform the drug lookup.
        yield baseEffectHandler({
            service: DrugService.drugLookup().get,
            data: {
                drugName: action.payload.drugName,
                clientAcCode: planAlias
            },
            *onResponse(response) {
                // Dispatch the success action in order to update the state of
                // the lookup component and show the returned results.
                yield put(drugLookupRoutine.success(response));
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugLookupRoutine', error.message);
                }
                // Dispatch the failure action to update the state of the lookup
                // component and hide the dropdown.
                yield put(drugLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugDetailsLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const ownerPlan: BIRDI_PLANS = yield select(accountAcCodeSelector);
        const selectedMember: string = yield select(addTransferPrescriptionMemberSelector);
        const mainUserEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
        const epostPatientNum = selectedMember || mainUserEpostPatientNum;
        const familyPricingDetails: DependentsPricingData[] = yield select(familyMembersPricingDataSelector);
        const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
        const selectedMemberPlan = getDrugPricingPlanAlias(pricingDetails);
        const planAlias = selectedMemberPlan || ownerPlan;
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug details.
        yield baseEffectHandler({
            service: DrugService.drugDetailsLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: planAlias
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugDetailsLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugDetailsLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugDetailsLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(drugFormLookupRoutine.TRIGGER, function* (action: PayloadAction<drugDetailsRoutinePayload>) {
        const ownerPlan: BIRDI_PLANS = yield select(accountAcCodeSelector);
        const selectedMember: string = yield select(addTransferPrescriptionMemberSelector);
        const mainUserEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
        const epostPatientNum = selectedMember || mainUserEpostPatientNum;
        const familyPricingDetails: DependentsPricingData[] = yield select(familyMembersPricingDataSelector);
        const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
        const selectedMemberPlan = getDrugPricingPlanAlias(pricingDetails);
        const planAlias = selectedMemberPlan || ownerPlan;
        // First, get AWS credentials.
        yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

        // Now lookup the drug forms.
        yield baseEffectHandler({
            service: DrugService.drugFormLookup().get,
            data: {
                drugName: action.payload.drugName,
                gpi: action.payload.gpi,
                clientAcCode: planAlias
            },
            *onResponse(response) {
                // Dispatch the success action in order to add the drug
                // details to state and update the drug lookup status.
                yield put(drugFormLookupRoutine.success(response));

                const { onSuccess } = action.payload;
                if (onSuccess) onSuccess(response.dosageForms);
            },
            *onError(error) {
                if (error.response?.data?.message) {
                    // This is an attempt to catch errors passed back from the API.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.response.data.message);
                } else if (error.message) {
                    // This is an attempt to catch basic network errors.
                    TrackError('drug.sagas.ts', 'drugFormLookupRoutine', error.message);
                }
                // Dispatch the failure action to set the lookup status back to
                // IDLE.
                yield put(drugFormLookupRoutine.failure());
                const { onFailure } = action.payload;
                if (onFailure) onFailure();
            }
        });
    });
    yield takeLatest(
        drugDiscountPriceRoutine.TRIGGER,
        function* (action: PayloadAction<drugDiscountPriceRoutinePayload>) {
            // This delay is needed to ensure that cognito information
            // on the first request have been loaded and prevent
            // overlapped requests
            yield delay(500);
            const { prescriptions, location, unAuthArea, onFailure, onSuccess } = action.payload;
            const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);
            const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
            const drugDiscountPriceHistory: DrugWithDiscountPrice[] = yield select(drugsWithDiscountSelector);
            // Selected epostPatientNum in transfer Rx
            const selectedMember: string | undefined = yield select(addTransferPrescriptionMemberSelector);

            // Defining where the user is coming from and
            // from where we are going to get their data (flow).
            const accessType = isLoggedIn
                ? PRICING_ACCESS_TYPE.LOGGED_IN
                : unAuthArea === PRICING_UNAUTH_AREA.AUTO_REFILL
                ? PRICING_ACCESS_TYPE.AUTO_REFILL
                : PRICING_ACCESS_TYPE.EASY_REFILL;

            // We will get data dynamically depending on the flow
            const selectors = {
                logged: {
                    cartItems: cartItemsSelector,
                    cartZipCode: cartZipCodeSelector,
                    familyPricingDetails: familyMembersPricingDataSelector,
                    mainUserZipCode: accountDefaultAddressZipCodeSelector,
                    mainUserPlan: accountAcCodeSelector
                },
                easyRefill: {
                    cartItems: easyRefillCartItemsSelector,
                    cartZipCode: easyRefillCartZipCodeSelector,
                    familyPricingDetails: easyRefillFamilyMembersPricingDataSelector,
                    mainUserZipCode: easyRefillPatientShipAddressZipCodeSelector,
                    mainUserPlan: easyRefillPlanAliasSelector
                },
                autoRefill: {
                    // The cart doesn't exist in easy refill so we mock the
                    // selector to return an empty array.
                    cartItems: createSelector(autoRefillSelector, () => {
                        return [] as RefillRxs[];
                    }),
                    cartZipCode: autoRefillZipCodeSelector,
                    familyPricingDetails: autoRefillFamilyMembersPricingDataSelector,
                    mainUserZipCode: autoRefillZipCodeSelector,
                    mainUserPlan: autoRefillAcCodeSelector
                }
            };

            // Get information from dynamic selectors
            const familyPricingDetails: DependentsPricingData[] = yield select(
                selectors[accessType].familyPricingDetails
            );
            const cartZipCode: string | null = yield select(selectors[accessType].cartZipCode);
            const mainUserZipCode: string | null = yield select(selectors[accessType].mainUserZipCode);
            const currentCartItems: RefillRxs[] = yield select(selectors[accessType].cartItems);

            // Mapping of the prescriptions to get the drug pricing.
            const drugsForPricing: DrugWithDiscountPrice[] = (prescriptions || [])
                .map((item: RxDetails) => {
                    const mappedItem = {
                        ...getDrugLookupData({
                            prescription: item,
                            familyPricingDetails,
                            selectedMember,
                            mainUserZipCode,
                            cartZipCode: currentCartItems.length > 0 ? cartZipCode : undefined,
                            cartItems: currentCartItems
                        }),
                        ...{
                            memberNumber: isLoggedIn ? profileObject.cardholderID : '',
                            location
                        }
                    };

                    if (
                        location === PRICING_API_LOCATION.CART &&
                        !doesPlanAllowsPricing(mappedItem.planAlias) &&
                        !!ALLOW_INSURED_BIRDI_PRICE
                    ) {
                        mappedItem.planAlias = BIRDI_PLANS.BRD_01;
                    }
                    const foundInHistory = findDrugInList(mappedItem, drugDiscountPriceHistory);
                    return foundInHistory ? foundInHistory : mappedItem;
                })
                // Ensure that only prescriptions with plans that allowed prices are loaded
                // and we are not trying to load prices when we are already loading the
                // drug pricing information from API
                .filter((item) => {
                    return shouldPlanShowPrice(item.planAlias);
                });

            const drugsToGetDataFromApi = drugsForPricing.filter((drug) => !drug.isLoading && !drug.price);
            const drugsToGetDataFromState = drugsForPricing.filter((drug) => drug.isLoading || !!drug.price);

            if (drugsToGetDataFromState.length > 0) {
                yield all(
                    drugsToGetDataFromState.map((item) => {
                        if (onSuccess) onSuccess(item);
                        return put(drugDiscountPriceRoutine.success());
                    })
                );
            }

            if (drugsToGetDataFromApi.length > 0) {
                yield put(
                    drugDiscountPriceApiRoutine.trigger({
                        drugs: drugsToGetDataFromApi,
                        onSuccess,
                        onFailure
                    })
                );
            }
        }
    );

    yield takeLatest(
        drugDiscountPriceApiRoutine.TRIGGER,
        function* (action: PayloadAction<drugDiscountPriceAPIRoutinePayload>) {
            const { drugs, onFailure, onSuccess } = action.payload;
            yield put(setLoadingDrugPrices(drugs));
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            // Call the drug discount price lookup for each of the drugs in parallel.
            const simultaneousRequests = 9;

            for (let i = 0; i < drugs.length; i += simultaneousRequests) {
                const rxSlice = drugs.slice(i, i + simultaneousRequests);

                yield all(
                    rxSlice.map((item) => {
                        const { rxNumber, ...data } = item;
                        // The following throws a TypeScript error ("No overload
                        // matches this call"). Not convinced that this is a legitimate
                        // issue.
                        // @ts-expect-error: Reasoning above.
                        return call(baseEffectHandler, {
                            service: DrugService.drugDiscountPriceLookup().get,
                            data,
                            *onResponse(response: DrugDiscountPriceResponse) {
                                // We map the object to be returned
                                const discountPrice: DrugWithDiscountPrice = processDiscountPrice(response, data);

                                // Return the mapped object
                                yield put(drugDiscountPriceRoutine.success({ result: discountPrice }));

                                // For OnSuccess Events return the price data
                                if (onSuccess) onSuccess(discountPrice);

                                return put(drugDiscountPriceRoutine.success());
                            },
                            *onError(error) {
                                yield put(drugDiscountPriceRoutine.failure(rxNumber));
                                if (onFailure) onFailure({ response: error });
                                // Log the error.
                                if (error.response?.data) {
                                    // This is an attempt to catch errors passed back from the API.
                                    if (error.response?.data?.status) {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.status
                                        );
                                    } else {
                                        TrackError(
                                            'drug.sagas.ts',
                                            'drugDiscountPriceRoutine',
                                            error.response.data.message
                                        );
                                    }
                                } else if (error.message) {
                                    // This is an attempt to catch basic network errors.
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error.message);
                                } else {
                                    TrackError('drug.sagas.ts', 'drugDiscountPriceRoutine', error);
                                }
                            }
                        });
                    })
                );
            }
        }
    );
    yield takeLatest(drugDescriptionRoutine.TRIGGER, function* (action: PayloadAction<drugDescriptionRoutinePayload>) {
        try {
            //CALL BEFORE ROUTINE
            yield put(drugDescriptionRoutine.request());

            // Get AWS credentials.
            yield call(AccountService.getAwsCreds(AWS_CONFIG_REGION, AWS_CONFIG_POOLID).get);

            // HANDLERS
            yield baseEffectHandler({
                service: DrugService.drugDescriptionLookup().get,
                data: action.payload,
                *onResponse(response) {
                    if (response.htmlDesc) {
                        yield put(drugDescriptionRoutine.success(response));
                        const { onSuccess } = action.payload;
                        // CALLBACK SUCCESS
                        if (onSuccess) onSuccess(response);
                    } else {
                        yield put(drugDescriptionRoutine.failure());
                        const { onFailure } = action.payload;
                        // CALLBACK ERROR
                        if (onFailure) onFailure();
                    }
                },
                *onError(error) {
                    if (error.response?.data?.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.response.data.message);
                    } else if (error.message) {
                        TrackError('drug.sagas.ts', 'drugDescriptionRoutine', error.message);
                    }
                    // CALLBACK ERROR
                    yield put(drugDescriptionRoutine.failure());
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                }
            });
        } catch (error) {
            // CALLBACK ERROR
            yield put(drugDescriptionRoutine.failure());
        }
    });
}
