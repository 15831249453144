import axios from 'axios';
import classNames from 'classnames';
import Webform, {
    useWebformElement,
    WebformCustomComponent,
    WebformElementWrapper,
    WebformObject
} from 'gatsby-drupal-webform';
import { getElementId } from 'gatsby-drupal-webform/dist/utils';
import { DRUPAL_BASE_URL } from 'gatsby-env-variables';
import { FluidObject } from 'gatsby-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'ui-kit/button/button';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { drupalFetchCSRFRoutine } from 'state/drupal/drupal.routines';
import { drupalCSRFSelector } from 'state/drupal/drupal.selectors';

import './web-form.style.scss';

export interface WebFormProps {
    backgroundColor?: string;
    eyebrowText?: string;
    titleText?: string;
    image?: FluidObject;
    webformObject?: WebformObject;
    sectionIndex: number;
}

export interface WebFormSchema {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    message: string;
}
export interface WebFormConfirmationMessageResponse {
    confirmation_message: string;
    confirmation_title: string;
    confirmation_type: string;
    confirmation_url: string;
}
export default function WebFormComponent({
    backgroundColor,
    eyebrowText,
    titleText,
    image,
    webformObject,
    sectionIndex
}: WebFormProps) {
    const baseClassName = 'webform-component';
    let backgroundTheme = null;
    let buttonVariant = null;
    switch (backgroundColor) {
        case 'light_blue': {
            backgroundTheme = 'cerulean';
            buttonVariant = 'dark';
            break;
        }
        case 'dark_blue': {
            backgroundTheme = 'dark';
            break;
        }
    }

    const backgroundClasses = classNames(baseClassName, backgroundTheme);
    const [confirmationMessage, setConfirmationMessage] = useState<WebFormConfirmationMessageResponse | undefined>(
        undefined
    );
    const csrfToken = useSelector(drupalCSRFSelector);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showSuccessModal = ({ title, message }: { title: string; message: string }) => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: <BirdiModalContent icon={'none'} title={title} body={message} />,
                ctas: [
                    {
                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const showErrorModal = ({ title, message }: { title: string; message: string }) => {
        dispatch(
            openModal({
                showClose: true,
                bodyContent: <BirdiModalContent icon={'alert'} title={title} body={message} />,
                ctas: [
                    {
                        label: t(`pages.medicineCabinet.messages.labels.gotIt`),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const WebformComponentInput: WebformCustomComponent = ({ element, error }) => {
        // todo: Maybe id attributes and settings should be generated one lever higher = less boilerplate.
        const inputText = useRef<HTMLInputElement | undefined>(null);
        const id = getElementId(element.name);
        const [inputProps, settings] = useWebformElement(element, {
            className: 'form-control',
            name: element.name,
            type: element.type,
            id
        });

        if (element.type === 'hidden') {
            return <input {...inputProps} />;
        }

        const validateInput = (): undefined | string => {
            let invalidMessage = undefined;
            if (settings.attributes.required === '1' && !inputText?.current?.value) {
                invalidMessage = settings.attributes.required_error
                    ? settings.attributes.required_error
                    : t('forms.errorMessages.requiredField', { label: element.name });
            }
            if (settings.attributes.pattern && inputText?.current?.value) {
                const regex = new RegExp(settings.attributes.pattern);
                const matches = regex.exec(inputText?.current?.value);
                if (!matches || matches.length === 0) {
                    invalidMessage = settings.attributes.pattern_error
                        ? settings.attributes.pattern_error
                        : t('forms.errorMessages.invalidField', { label: element.name });
                }
            }
            return invalidMessage;
        };

        // DRX-1307: Pass along autoComplete value from webform settings
        if (settings.attributes.autoComplete) {
            inputProps.autoComplete = settings.attributes.autoComplete;
        }

        // If a pattern is supplied, add it to the inputProps
        // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#pattern_validation
        if (settings.attributes.pattern && settings.attributes.pattern_error) {
            inputProps.pattern = settings.attributes.pattern;
            inputProps.title = settings.attributes.pattern_error;
        }

        // To ensure forms render correctly, override settings from the Drupal Webform
        settings.attributes.title_display = 'after';
        settings.attributes.placeholder = settings.attributes.placeholder
            ? settings.attributes.placeholder
            : settings.attributes.title;

        if (!error) {
            error = validateInput();
        }

        return (
            <WebformElementWrapper settings={settings} error={error} labelFor={id}>
                <input {...inputProps} ref={inputText} />
            </WebformElementWrapper>
        );
    };

    const WebformComponentTextArea: WebformCustomComponent = ({ element, error }) => {
        // todo: Maybe id attributes and settings should be generated one lever higher = less boilerplate.
        const textArea = useRef<HTMLTextAreaElement | undefined>(null);
        const id = getElementId(element.name);
        const [inputProps, settings] = useWebformElement(element, {
            className: 'form-control',
            name: element.name,
            type: element.type,
            id
        });

        if (element.type === 'hidden') {
            return <input {...inputProps} />;
        }

        const validateInput = (): undefined | string => {
            let invalidMessage = undefined;
            if (settings.attributes.required === '1' && !textArea?.current?.value) {
                invalidMessage = settings.attributes.required_error
                    ? settings.attributes.required_error
                    : t('forms.errorMessages.requiredField', { label: element.name });
            }
            if (settings.attributes.pattern && textArea?.current?.value) {
                const regex = new RegExp(settings.attributes.pattern);
                const matches = regex.exec(textArea?.current?.value);
                if (!matches || matches.length === 0) {
                    invalidMessage = settings.attributes.pattern_error
                        ? settings.attributes.pattern_error
                        : t('forms.errorMessages.invalidField', { label: element.name });
                }
            }
            return invalidMessage;
        };

        // DRX-1307: Pass along autoComplete value from webform settings
        if (settings.attributes.autoComplete) {
            inputProps.autoComplete = settings.attributes.autoComplete;
        }

        // If a pattern is supplied, add it to the inputProps
        // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#pattern_validation
        if (settings.attributes.pattern && settings.attributes.pattern_error) {
            inputProps.pattern = settings.attributes.pattern;
            inputProps.title = settings.attributes.pattern_error;
        }

        // To ensure forms render correctly, override settings from the Drupal Webform
        settings.attributes.title_display = 'after';
        settings.attributes.placeholder = settings.attributes.placeholder
            ? settings.attributes.placeholder
            : settings.attributes.title;

        if (!error) {
            error = validateInput();
        }

        return (
            <WebformElementWrapper settings={settings} error={error} labelFor={id}>
                <textarea {...inputProps} ref={textArea} />
            </WebformElementWrapper>
        );
    };

    const WebFormComponentButton: WebformCustomComponent = ({ element, error }) => {
        const [inputProps, settings] = useWebformElement(element, {
            name: element.name,
            label: element.name,
            type: element.type,
            id: element.name
        });
        inputProps.label = settings.attributes.submit__label;

        if (element.type === 'hidden') {
            return <input {...inputProps} />;
        }
        return <Button className={`md-full`} variant={buttonVariant} {...inputProps} />;
    };

    const setCSRFToken = () => {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
    };

    const clearCSRFToken = () => {
        delete axios.defaults.headers.common['X-CSRF-TOKEN'];
    };

    const validateForm = (event: any): boolean => {
        if (event.target.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = (event: any): boolean => {
        setCSRFToken();
        return true; // Allows the Webform to submit to the API
    };

    const handleSuccess = (response: any) => {
        // Clear axios csrf token
        clearCSRFToken();

        // Form should be cleared out as well.

        // There are a lot of options for confirmation_type - not sure how we want to handle all of them.
        switch (response.settings.confirmation_type) {
            case 'modal': {
                showSuccessModal({
                    title: response.settings.confirmation_title,
                    message: response.settings.confirmation_message
                });
                break;
            }
            case 'message': {
                setConfirmationMessage(response.settings);
                break;
            }
            default: {
                // KEEP UNTIL OTHER CONFIRMATION_TYPE HANDLERS ARE WRITTEN
                console.log('response.settings.confirmation_type: ' + response.settings.confirmation_type);
            }
        }
    };

    const handleError = () => {
        clearCSRFToken();
        showErrorModal({
            title: t('components.webform.errors.submit.error'),
            message: t('components.webform.errors.submit.errorMessage')
        });
    };

    useEffect(() => {
        if (csrfToken === undefined) {
            dispatch(drupalFetchCSRFRoutine.trigger());
        }
    }, []);

    return (
        <Container fluid className={backgroundClasses} id={`webform-${sectionIndex}`}>
            <Row>
                <Col sm="12" lg={{ span: 10, offset: 1 }}>
                    <Row className="align-items-center">
                        <Col
                            lg={image ? '8' : '12'}
                            className={`${baseClassName}--form-container ${image ? 'mb-5' : 'mb-0'} mb-lg-0`}
                        >
                            {eyebrowText && (
                                <div
                                    className={`${baseClassName}--eyebrow-text h5 text-uppercase text-center text-lg-left`}
                                >
                                    {eyebrowText}
                                </div>
                            )}
                            {confirmationMessage && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: confirmationMessage.confirmation_message
                                    }}
                                />
                            )}
                            {!confirmationMessage && (
                                <>
                                    {sectionIndex === 0 && titleText && (
                                        <h1 className={`${baseClassName}--title h2 text-center text-lg-left`}>
                                            {titleText}
                                        </h1>
                                    )}
                                    {sectionIndex > 0 && titleText && (
                                        <h2 className={`${baseClassName}--title text-center text-lg-left`}>
                                            {titleText}
                                        </h2>
                                    )}
                                    {webformObject && (
                                        <Webform
                                            key={`webform-${sectionIndex}`}
                                            className={`${baseClassName}--webform`}
                                            webform={webformObject}
                                            customComponents={{
                                                textfield: WebformComponentInput,
                                                email: WebformComponentInput,
                                                textarea: WebformComponentTextArea,
                                                webform_actions: WebFormComponentButton
                                            }}
                                            endpoint={`${DRUPAL_BASE_URL}react_webform_backend/submit`}
                                            onSubmit={handleSubmit}
                                            onValidate={validateForm}
                                            noValidate={true}
                                            onSuccess={handleSuccess}
                                            onError={handleError}
                                        />
                                    )}
                                </>
                            )}
                        </Col>
                        {image && (
                            <Col
                                className={`${baseClassName}--image-container`}
                                xs={{ span: 8, offset: 2 }}
                                sm={{ span: 6, offset: 3 }}
                                lg={{ span: 4, offset: 0 }}
                            >
                                <GatsbyImage image={getImage(image)} alt={''} />
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}
