// General
import React, { useCallback } from 'react';
// Hooks
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// Components
import ColumnSectionEditModeToggle from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';

import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cancelOrderLine } from 'state/cart/cart.routines';
// States
import { setMedicineCabinetIsBusy } from 'state/medicine-cabinet/medicine-cabinet.reducers';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';

// Types and Interfaces
import { CartOrderRx, CartPatient } from 'types/cart';

//Utils
import { EXPEDITED_SHIPPING_COST } from 'util/cart';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

// Styles
import './prescription-information.style.scss';
import PrescriptionItem, { PrescriptionItemProps } from './prescription-item/prescription-item.component';

// Main component interface
export interface PrescriptionInformationProps {
    isToggleDisabled?: boolean;
}

// Main component
const PrescriptionInformation: React.FC<PrescriptionInformationProps> = (props) => {
    const { isToggleDisabled = true } = props;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, prescriptionsInCart } = useCart();
    const { mainUserIsCaregiver, activeTab } = usePrescriptionFlow();

    const handlePrescriptionRemoval = useCallback(() => {
        if (!!cartData?.cartItems && cartData?.cartItems <= 1) window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [cartData]);

    const handlePrescriptionsError = useCallback(() => {
        dispatch(
            openModal({
                showClose: true,
                className: 'prescription-modal',
                bodyContent: <FailureUpdateCartModalContent area={t('modals.updateCart.areas.cart')} />,
                ctas: [
                    {
                        label: t('modals.updateCart.labels.gotIt'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        dataGALocation: 'UpdateCartError'
                    }
                ]
            })
        );
    }, [dispatch, t]);

    const handleRemovePrescription = useCallback(
        (prescriptionToRemove: CartOrderRx, patient: CartPatient): PrescriptionItemProps['onRemovePrescriptionClick'] =>
            () => {
                if (!prescriptionToRemove) {
                    return;
                }

                dispatch(setMedicineCabinetIsBusy(true));
                dispatch(
                    cancelOrderLine.trigger({
                        rxNumber: prescriptionToRemove.rxDetails.rxNumber,
                        onSuccess: () => {
                            TrackCheckoutStep({
                                stepName: 'remove',
                                step: '1',
                                carts: [patient],
                                prescriptions: prescriptionsInCart || [],
                                t: t,
                                shippingCost: patient.order?.orderHeader?.orderHighPriority
                                    ? `${EXPEDITED_SHIPPING_COST}`
                                    : '0',
                                accountHasInsurance: prescriptionToRemove.isPrimaryPlanInsurance
                            });
                            handlePrescriptionRemoval();
                            dispatch(
                                medicineCabinetGetAllPrescriptions.trigger({
                                    epostNumFamilyMember: activeTab,
                                    onSuccess: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                    },
                                    onFailure: () => {
                                        dispatch(setMedicineCabinetIsBusy(false));
                                    }
                                })
                            );
                        },
                        onFailure: () => {
                            handlePrescriptionsError();
                            dispatch(setMedicineCabinetIsBusy(false));
                        }
                    })
                );
            },
        [dispatch, prescriptionsInCart, t, activeTab, handlePrescriptionsError, handlePrescriptionRemoval]
    );

    const getPatientName = (patient: CartPatient): string => {
        return patient && patient.firstName && patient.lastName ? `${patient.firstName} ${patient.lastName}` : '';
    };

    return (
        <>
            <ColumnSectionEditModeToggle
                bodyClassName="prescription-information__content"
                className="prescription-information"
                editModeContent={<></>}
                headerClassName="prescription-information__header"
                title="Prescription Information"
                isToggleDisabled={isToggleDisabled}
            >
                {cartData && (
                    <div className="cart-review">
                        <div className="cart-review--border-bottom">
                            {cartData.patients.map((patient: CartPatient) => {
                                return patient.cartRxs?.map((item: CartOrderRx) => {
                                    return (
                                        <PrescriptionItem
                                            isLoading={false}
                                            isCaregiver={mainUserIsCaregiver}
                                            patientName={getPatientName(patient)}
                                            key={item.rxDetails.rxNumber}
                                            onRemovePrescriptionClick={handleRemovePrescription(item, patient)}
                                            prescription={item}
                                            isPlanAutoRefillEligible={patient.planEligibleForAutoRefill}
                                        />
                                    );
                                });
                            })}
                        </div>
                    </div>
                )}
            </ColumnSectionEditModeToggle>
        </>
    );
};

export default PrescriptionInformation;
