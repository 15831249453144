import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { produce } from 'immer';

import { AddressPayload } from 'types/account';
import { CartObject, CartObjectPayload, CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { Order } from 'types/order-prescription';

import { ApiStatus } from 'enums/api-status';

import { updateLocalCartState } from './cart.actions';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdateCartRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    getCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine
} from './cart.routines';

export interface CartOrderSnapshot {
    cart: Order;
    shippingAddress: AddressPayload;
    paymentCard: CreditCardPayload;
}

export interface cartState {
    error?: string;
    isBusy?: boolean;
    cart?: CartPayload[];
    orderPlaced: boolean;
    orderSnapshot?: CartOrderSnapshot;
    shipMethodId?: string | null;
    isLoadingAddressShipping?: boolean;
    isLoadingPayments?: boolean;
    isLoadingCancelPrescriptions?: boolean;
    cartApiStatus: string;
    lastOrder: CartObject | undefined;
    orderCompleteRxAutoRefillStatus: { rxNumber: string; status: boolean }[];
}

export const initialState: cartState = {
    cart: undefined,
    cartApiStatus: ApiStatus.IDLE,
    orderPlaced: false,
    orderSnapshot: undefined,
    lastOrder: undefined,
    orderCompleteRxAutoRefillStatus: []
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart(state, action) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = action.payload;
        },
        resetCart(state) {
            state.error = undefined;
            state.isBusy = false;
            state.cart = undefined;
            state.orderPlaced = false;
            state.cartApiStatus = ApiStatus.IDLE;
            state.orderCompleteRxAutoRefillStatus = [];
        },
        setCartIsBusy(state, action) {
            state.isBusy = action.payload;
        },
        setCartHistory(state, action) {
            state.lastOrder = action.payload;
        },
        setOrderCompleteAutoRefillToggle(state, action: PayloadAction<{ status: boolean; rxNumber: string }>) {
            let autoRefillEnabledRxs = [...state.orderCompleteRxAutoRefillStatus];
            const isTracked = autoRefillEnabledRxs.some((rx) => rx.rxNumber === action.payload.rxNumber);
            if (isTracked) {
                autoRefillEnabledRxs.map((rx) => {
                    const prescription = { ...rx };
                    if (prescription.rxNumber === action.payload.rxNumber) {
                        prescription.status = action.payload.status;
                    }
                    return prescription;
                });
            } else {
                autoRefillEnabledRxs = [
                    ...autoRefillEnabledRxs,
                    { rxNumber: action.payload.rxNumber, status: action.payload.status }
                ];
            }
            state.orderCompleteRxAutoRefillStatus = autoRefillEnabledRxs;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(getCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.cartApiStatus = ApiStatus.LOADING;
            })
        );
        addCase(getCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartObjectPayload>) =>
            produce(state, (draftState) => {
                if (payload.isCaregiver) {
                    draftState.cart = payload.cart;
                } else {
                    const { refillRxs, orderHeader, ...params } = payload.cart[0].Order;
                    const { BirdiOrderNumber, FirstName, LastName } = payload.cart[0];
                    draftState.cart = [
                        {
                            EpostPatientNum: payload.epostPatientNum, // We will use this parameter to search the cart.
                            Order: {
                                orderHeader: {
                                    ...orderHeader
                                },
                                refillRxs: refillRxs,
                                ...params
                            },
                            BirdiOrderNumber,
                            FirstName,
                            LastName
                        }
                    ];
                }
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isBusy = false;
                draftState.cartApiStatus = ApiStatus.SUCCESS;
                draftState.isLoadingPayments = false;
                draftState.isLoadingAddressShipping = false;
                draftState.isLoadingCancelPrescriptions = false;
            })
        );
        addCase(getCartRoutine.FAILURE, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isBusy = false;
                draftState.cartApiStatus = ApiStatus.ERROR;
                draftState.isLoadingPayments = false;
                draftState.isLoadingAddressShipping = false;
                draftState.isLoadingCancelPrescriptions = false;
            })
        );

        /**
         * Cart Start Cart Reducers
         */
        addCase(startCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(startCartRoutine.SUCCESS, (state, { payload }: PayloadAction<CartPayload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(startCartRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.cart = undefined;
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Update Cart Reducers
         */
        addCase(cartUpdateCartRoutine.TRIGGER, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                // nothing yet
                draftState.isBusy = true;
            })
        );
        addCase(cartUpdateCartRoutine.SUCCESS, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                draftState.cart = [{ Order: payload }];
                draftState.isBusy = false;
            })
        );
        addCase(cartUpdateCartRoutine.FAILURE, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Update Refill Lines Reducers
         */
        addCase(updateRefillLinesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(updateRefillLinesRoutine.SUCCESS, (state, { payload }: PayloadAction<CartPayload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(updateRefillLinesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        /**
         * Cart Order Line Reducers
         */
        addCase(cancelOrderLine.TRIGGER, (state, { payload }: PayloadAction<CartPayload[]>) =>
            produce(state, (draftState) => {
                draftState.isLoadingCancelPrescriptions = true;
                draftState.isBusy = true;
            })
        );
        addCase(cancelOrderLine.SUCCESS, (state, { payload }: PayloadAction<CartPayload[]>) =>
            produce(state, (draftState) => {
                draftState.cart = payload;
                draftState.isBusy = false;
            })
        );
        addCase(cancelOrderLine.FAILURE, (state, { payload }: PayloadAction<Order>) => {
            // Do nothing, and leave the cart in the same state.
            produce(state, (draftState) => {
                draftState.isBusy = false;
            });
        });

        /**
         * Cart Update Shipping Reducers
         */
        addCase(
            cartUpdateShippingRoutine.TRIGGER,
            (state, { payload }: PayloadAction<{ Order: Order; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.isLoadingAddressShipping = true;
                    draftState.isBusy = true;
                })
        );
        addCase(
            cartUpdateShippingRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ Order: Order; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.cart = state.cart;
                    draftState.isBusy = false;
                    draftState.shipMethodId = state.shipMethodId;
                })
        );
        addCase(cartUpdateShippingRoutine.FAILURE, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
                draftState.cart = state.cart;
            })
        );

        /**
         * Cart Update Payment Reducers
         */
        addCase(cartUpdatePaymentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.isLoadingPayments = true;
            })
        );
        addCase(
            cartUpdatePaymentRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ Order: Order; epostPatientNum: string }>) =>
                produce(state, (draftState) => {
                    draftState.cart = state.cart;
                    draftState.isBusy = false;
                    draftState.isLoadingPayments = false;
                })
        );
        addCase(cartUpdatePaymentRoutine.FAILURE, (state, { payload }: PayloadAction<Order>) =>
            produce(state, (draftState) => {
                draftState.cart = state.cart;
                draftState.isBusy = false;
                draftState.isLoadingPayments = false;
            })
        );
        addCase(updateLocalCartState, (state, { payload }: PayloadAction<{ shipMethodId: string }>) =>
            produce(state, (draftState) => {
                draftState.shipMethodId = payload.shipMethodId;
            })
        );
        /*
         * Complete Cart Routine
         */
        addCase(
            cartCompleteOrderRoutine.TRIGGER,
            (state, { payload }: PayloadAction<{ cart: CartPayload[]; orderPlaced: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.isBusy = true;
                })
        );
        addCase(
            cartCompleteOrderRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ cart: CartPayload[]; orderPlaced: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.orderPlaced = payload.orderPlaced;
                    draftState.cart = payload.cart;
                })
        );
        addCase(
            cartCompleteOrderRoutine.FAILURE,
            (state, { payload }: PayloadAction<{ cart: CartPayload[]; orderPlaced: boolean }>) =>
                produce(state, (draftState) => {
                    draftState.isBusy = false;
                })
        );

        addCase(cartUpdateExpeditedShippingRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddressShipping = true;
            })
        );
        addCase(cartUpdateExpeditedShippingRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddressShipping = false;
            })
        );
        addCase(cartUpdateExpeditedShippingRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingAddressShipping = false;
            })
        );
    }
});

export const { setCart, resetCart, setCartIsBusy, setCartHistory, setOrderCompleteAutoRefillToggle } =
    cartSlice.actions;

export default cartSlice.reducer;
