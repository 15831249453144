import { PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
    accountDefaultAddressSelector,
    accountDefaultCreditCardSelector,
    accountIsLoggedInSelector,
    accountProfilEPostPatientNumSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import {
    cancelOrderLine,
    cartCompleteOrderRoutine,
    cartUpdateExpeditedShippingRoutine,
    cartUpdatePaymentRoutine,
    cartUpdateShippingRoutine,
    getCartRoutine,
    startCartRoutine,
    updateRefillLinesRoutine
} from 'state/cart/cart.routines';
import { cartProcessedSelector, cartSelector } from 'state/cart/cart.selectors';
import cartService from 'state/cart/cart.services';

import { AddressPayload } from 'types/account';
import { CancelOrderLinePayload, CartObject, CartObjectPayload, CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { OrderBillShip, RefillRxs } from 'types/order-prescription';
import { RxDetails } from 'types/prescription';

import { RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';

import { DEFAULT_SHIPPING_ID } from 'util/cart';
import { TrackError } from 'util/google_optimize/optimize_helper';
import { baseEffectHandler } from 'util/sagas/sagas';

import { medicineCabinetPrescriptionsSelector } from '../medicine-cabinet/medicine-cabinet.selectors';
import { updateLocalCartState } from './cart.actions';
import { createOrder, createRxObject, getPatientCart } from './cart.helpers';
import { setCartHistory } from './cart.reducers';

export default function* cartSaga() {
    yield takeLatest(
        getCartRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                onSuccess?: (data: any) => void;
                onFailure?: (data: any) => void;
            }>
        ) {
            try {
                const { onSuccess, onFailure } = action.payload || {};
                const isLoggedIn: boolean = yield select(accountIsLoggedInSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const profileObject: Partial<ProfileObjectPayload> = yield select(accountProfileSelector);
                const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);

                if (isLoggedIn) {
                    yield baseEffectHandler<CartObjectPayload>({
                        service: isCaregiver ? cartService.startCart().getAllV2 : cartService.startCart().getAll,
                        isAuthenticatedService: true,
                        isLoggedIn,
                        *onResponse(data: CartPayload[]) {
                            const shipMethodId =
                                data.find((cart) => cart?.Order?.orderBillShip?.shipMethodId)?.Order?.orderBillShip
                                    ?.shipMethodId || DEFAULT_SHIPPING_ID;

                            const cart: CartPayload[] = [...data].map((patient) => ({
                                ...patient,
                                FirstName:
                                    data.length === 1 && !patient.FirstName
                                        ? profileObject.patientFirstName || ''
                                        : patient.FirstName,
                                LastName:
                                    data.length === 1 && !patient.LastName
                                        ? profileObject.patientLastName || ''
                                        : patient.LastName
                            }));

                            const result = { cart, isCaregiver, epostPatientNum };

                            yield put(
                                getCartRoutine.success({
                                    ...result,
                                    onSuccess: action?.payload?.onSuccess,
                                    onFailure: action?.payload?.onFailure
                                })
                            );
                            yield put(updateLocalCartState({ shipMethodId }));
                            if (onSuccess) onSuccess(result);
                        },
                        *onError(data) {
                            if (onFailure) onFailure(data);
                            yield put(getCartRoutine.failure(data));
                        }
                    });
                }
            } catch (e: Error | unknown) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(getCartRoutine.failure(e as any));
            }
        }
    );

    yield takeLatest(
        startCartRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumber: string;
                epostPatientNum: string;
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                // Selectors
                const profileObject: ProfileObjectPayload = yield select(accountProfileSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                const cartObjects: CartPayload[] = yield select(cartSelector);
                const accountEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
                const defaultAddress: AddressPayload = yield select(accountDefaultAddressSelector);
                const defaultCreditCard: CreditCardPayload | null | undefined = yield select(
                    accountDefaultCreditCardSelector
                );
                const prescriptions: RxDetails[] = yield select(medicineCabinetPrescriptionsSelector);
                const cartProcessed: CartObject = yield select(cartProcessedSelector);

                // Variables
                const { rxNumber, epostPatientNum, onFailure, onSuccess } = action.payload;
                const patientCart = getPatientCart(cartObjects, epostPatientNum);
                const orderDate = new Date().toISOString();
                const rxsToOrder: RefillRxs[] = [];

                let currentPrescription = prescriptions?.find((obj) => {
                    return obj.rxNumber;
                });

                if (currentPrescription) {
                    currentPrescription = {
                        ...currentPrescription,
                        webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE
                    };
                }

                const rxObject = createRxObject(rxNumber, currentPrescription);

                rxsToOrder.push(rxObject);
                patientCart?.Order.refillRxs.forEach((refill) => {
                    rxsToOrder.push(refill);
                });

                const updatedCartObject = produce(patientCart, (draftState) => {
                    if (!draftState || !patientCart) return;

                    // When updating, consider the easy-refill (gatsby/src/state/easy-refill/easy-refill.sagas.ts:834)
                    let orderBillShip: OrderBillShip = {
                        ...draftState.Order.orderBillShip
                    };

                    const patientShipAddressSeq = patientCart.Order.orderBillShip.patientBillAddressSeq
                        ? patientCart.Order.orderBillShip.patientBillAddressSeq
                        : defaultAddress?.addressSeqNum;

                    const shipMethodId = cartProcessed?.shipMethodId
                        ? cartProcessed?.shipMethodId
                        : DEFAULT_SHIPPING_ID;

                    const paymentCardSeqNum = patientCart.Order.orderBillShip.paymentCardSeqNum
                        ? patientCart.Order.orderBillShip.paymentCardSeqNum
                        : defaultCreditCard?.cardSeqNum;

                    orderBillShip = {
                        ...orderBillShip,
                        ...{
                            ordShipDate: orderDate,
                            orderPaymentOwner: profileObject?.epostPatientNum,
                            paymentMethodId: '2',
                            shipMethodId,
                            orderNum: null,
                            patientBillAddressSeq: patientShipAddressSeq,
                            patientShipAddressSeq,
                            paymentCardSeqNum
                        }
                    };

                    draftState.Order = createOrder(draftState, patientCart, orderBillShip, rxsToOrder, orderDate);
                });

                const payload = { epostPatientNum: accountEpostPatientNum, updatedCartObject };

                yield baseEffectHandler({
                    service: isCaregiver ? cartService.startCart().postV2 : cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: payload,
                    *onResponse(data) {
                        const index = cartObjects?.findIndex((cart) => cart.EpostPatientNum === epostPatientNum);
                        const updatedCart = cartObjects.map((cart) => ({ ...cart }));
                        if (index !== -1) {
                            updatedCart[index] = { ...data };
                        }
                        yield put(getCartRoutine.trigger());
                        if (onSuccess) onSuccess(updatedCart);
                        yield put(startCartRoutine.success(updatedCart));
                    },
                    *onError(data) {
                        if (onFailure) onFailure(data);
                        yield put(startCartRoutine.failure(data));
                    }
                });
            } catch (e: Error | unknown) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(startCartRoutine.failure(undefined));
                TrackError('cart.sagas.ts', 'startCartRoutine', e as Error);
            }
        }
    );

    yield takeLatest(
        updateRefillLinesRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                rxNumber: string;
                epostPatientNum: string;
                onSuccess: (data: any) => void;
                onFailure: (data: any) => void;
            }>
        ) {
            try {
                const { rxNumber, epostPatientNum, onFailure, onSuccess } = action.payload;

                const cartObjects: CartPayload[] = yield select(cartSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const prescriptionsObject: RxDetails[] = yield select(medicineCabinetPrescriptionsSelector);
                const accountEpostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
                const cartProcessed: CartObject = yield select(cartProcessedSelector);

                let currentPrescription = prescriptionsObject?.find((obj) => {
                    return obj.rxNumber;
                });

                if (currentPrescription) {
                    currentPrescription = {
                        ...currentPrescription,
                        webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.NOT_ELIGIBLE
                    };
                }

                const cart = getPatientCart(cartObjects, epostPatientNum);

                const rxObjects: RefillRxs[] = [];
                const newObject: RefillRxs = createRxObject(rxNumber, currentPrescription);
                rxObjects.push(newObject);

                const updatedCartObject = produce(cart, (draftState) => {
                    if (draftState) {
                        rxObjects.forEach((newObject) => {
                            draftState.Order.refillRxs.push(newObject);
                        });

                        const orderBillShip: OrderBillShip = {
                            ...draftState.Order.orderBillShip,
                            ...{
                                shipMethodId: cartProcessed.shipMethodId,
                                patientBillAddressSeq: cartProcessed.address?.addressSeqNum || null,
                                patientShipAddressSeq: cartProcessed.address?.addressSeqNum || null
                            }
                        };

                        draftState.Order.orderBillShip = orderBillShip;
                    }
                });

                const payload = { epostPatientNum: accountEpostPatientNum, updatedCartObject };

                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                yield baseEffectHandler({
                    service: isCaregiver ? cartService.startCart().postV2 : cartService.startCart().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: payload,
                    *onResponse(data) {
                        const index = cartObjects?.findIndex((cart) => cart.EpostPatientNum == epostPatientNum);
                        const updatedCart: CartPayload[] = cartObjects.map((cart) => ({ ...cart }));
                        if (index !== -1) updatedCart[index] = { ...data };
                        yield put(getCartRoutine.trigger());
                        if (onSuccess) onSuccess(updatedCart);
                        yield put(updateRefillLinesRoutine.success(updatedCart));
                    },
                    *onError(data) {
                        console.log(data);
                        if (onFailure) onFailure(data);
                        yield put(updateRefillLinesRoutine.failure(data));
                    }
                });
            } catch (e: Error | unknown) {
                const { onFailure } = action.payload;
                if (onFailure) onFailure(e);
                yield put(updateRefillLinesRoutine.failure());
                TrackError('cart.sagas.ts', 'updateRefillLinesRoutine', e as Error);
            }
        }
    );

    yield takeLatest(cancelOrderLine.TRIGGER, function* (action: PayloadAction<any>) {
        try {
            const {
                payload: { rxNumber, onSuccess }
            } = action;

            const cartObjects: CartPayload[] = yield select(cartSelector);
            const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);

            const cartObject = cartObjects.find((cart) =>
                cart.Order.refillRxs.find((refills) => refills.rxNumber === rxNumber)
            );
            const cartItems: RefillRxs[] | undefined = cartObject?.Order.refillRxs;

            const currentPrescription = cartItems?.find((obj: any) => {
                return obj.rxNumber === rxNumber;
            });
            let cancelObject: CancelOrderLinePayload = {
                rxNumber: rxNumber,
                lineId: currentPrescription?.epostRxScriptId as string,
                orderNum: cartObject?.Order.orderHeader.orderNum as string
            };

            if (isCaregiver) cancelObject = { ...cancelObject, epostNumPatient: cartObject?.EpostPatientNum };

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            yield baseEffectHandler<CancelOrderLinePayload>({
                service: isCaregiver ? cartService.cancelOrder().postV2 : cartService.cancelOrder().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cancelObject,
                *onResponse(data) {
                    const index = cartObjects?.findIndex(
                        (cart) => cart.EpostPatientNum === cartObject?.EpostPatientNum
                    );
                    const updatedCart = cartObjects.map((cart) => ({ ...cart }));
                    if (index !== -1)
                        updatedCart[index] = {
                            ...updatedCart[index],
                            Order: {
                                ...data,
                                refillRxs: updatedCart[index].Order.refillRxs.filter(
                                    (refill) => refill.epostRxScriptId !== cancelObject.lineId
                                )
                            }
                        };

                    yield put(getCartRoutine.trigger());
                    yield put(cancelOrderLine.success(updatedCart));
                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cancelOrderLine.failure(data));
                }
            });
        } catch (e: Error | unknown) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cancelOrderLine.failure());
            TrackError('cart.sagas.ts', 'cancelOrderLine', e as Error);
        }
    });

    yield takeLatest(
        cartCompleteOrderRoutine.TRIGGER,
        function* (
            action: PayloadAction<{
                lineItems?: RefillRxs[];
                onSuccess?: (data: unknown) => void;
                onFailure?: (data: unknown) => void;
            }>
        ) {
            const {
                payload: { onSuccess, onFailure, lineItems }
            } = action;
            try {
                const orderObject: CartPayload[] = yield select(cartSelector);
                const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
                const isCaregiver: boolean = yield select(accountProfilIsCaregiverSelector);
                const cartProcessed: CartObject = yield select(cartProcessedSelector);

                if (!cartProcessed.address || !cartProcessed.paymentMethod)
                    throw new Error('Cart data is not complete');

                yield put(setCartHistory(cartProcessed));

                const updatedCartObject = produce(orderObject, (draftState) => {
                    if (draftState) {
                        draftState = draftState.map((orderCart: CartPayload) => {
                            const cartItem: CartPayload = { ...orderCart };
                            cartItem.Order.orderBillShip = {
                                ...cartItem.Order.orderBillShip,
                                ...{
                                    patientBillAddressSeq:
                                        orderCart.Order.orderBillShip.patientBillAddressSeq ??
                                        String(cartProcessed.address?.addressSeqNum),
                                    patientShipAddressSeq:
                                        orderCart.Order.orderBillShip.patientShipAddressSeq ??
                                        String(cartProcessed.address?.addressSeqNum),
                                    paymentCardSeqNum: String(
                                        orderCart.Order.orderBillShip.paymentCardSeqNum ??
                                            cartProcessed.paymentMethod.paymentCardSeqNum
                                    ),
                                    paymentMethodId:
                                        orderCart.Order.orderBillShip.paymentMethodId === '6' &&
                                        orderCart.Order.orderBillShip.paymentCardSeqNum
                                            ? '2'
                                            : orderCart.Order.orderBillShip.paymentMethodId,
                                    shipMethodId: cartProcessed.shipMethodId
                                }
                            };

                            if (lineItems !== undefined) {
                                const cleanRefillRxs: RefillRxs[] = [];

                                orderCart.Order.refillRxs.forEach((rx: RefillRxs) => {
                                    const currentPrescription: RefillRxs | undefined = lineItems.find(
                                        (lineItem: RefillRxs) => {
                                            return rx.rxNumber === lineItem.rxNumber;
                                        }
                                    );
                                    if (currentPrescription) {
                                        // Exclude extra attributes
                                        const cleanRefillRx: RefillRxs & { [key: string]: any } = rx;
                                        Object.keys(rx).forEach(function (key) {
                                            cleanRefillRx[key] = currentPrescription[key];
                                        });
                                        cleanRefillRxs.push(cleanRefillRx);
                                    } else {
                                        cleanRefillRxs.push(rx);
                                    }
                                });
                                cartItem.Order.refillRxs = cleanRefillRxs;
                            }
                            return cartItem;
                        });
                    }
                });

                yield baseEffectHandler<CartPayload[]>({
                    service: isCaregiver ? cartService.completeOrder().postV2 : cartService.completeOrder().post,
                    isAuthenticatedService: true,
                    isLoggedIn,
                    data: updatedCartObject,
                    *onResponse(data) {
                        if (onSuccess) onSuccess(data);
                        yield put(cartCompleteOrderRoutine.success({ cart: data, orderPlaced: true }));
                    },
                    *onError(data) {
                        yield put(cartCompleteOrderRoutine.failure({ cart: data, orderPlaced: false }));
                        if (onFailure) onFailure(data);
                    }
                });
            } catch (e: Error | unknown) {
                yield put(cartCompleteOrderRoutine.failure());
                if (onFailure) onFailure(e);
                TrackError('cart.sagas.ts', 'cartCompleteOrderRoutine', e as Error);
            }
        }
    );

    yield takeLatest(cartUpdateShippingRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: CartPayload[] = yield select(cartSelector);
            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.patientBillAddressSeq !== null)
                : getPatientCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip = action.payload;
                }
            });

            yield baseEffectHandler({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse() {
                    const { onSuccess } = action.payload;
                    yield put(getCartRoutine.trigger());

                    yield put(
                        cartUpdateShippingRoutine.success({
                            Order: cartObjectPayload?.Order,
                            epostPatientNum
                        })
                    );
                    yield put(
                        updateLocalCartState({ shipMethodId: action.payload.shipMethodId || DEFAULT_SHIPPING_ID })
                    );

                    if (onSuccess) onSuccess();
                },
                *onError(data) {
                    const { onFailure } = action.payload;
                    if (onFailure) onFailure();
                    yield put(cartUpdateShippingRoutine.failure(data));
                }
            });
        } catch (e: Error | unknown) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdateShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateShippingRoutine', e as Error);
        }
    });

    yield takeLatest(cartUpdateExpeditedShippingRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        const { orderHighPriority, shipMethodId, onSuccess, onFailure } = action.payload;
        try {
            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: CartPayload[] = yield select(cartSelector);

            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.shipMethodId !== null)
                : getPatientCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip.shipMethodId = shipMethodId;
                    draftCartObject.Order.orderHeader.orderHighPriority = orderHighPriority;
                }
            });

            yield put(updateLocalCartState({ shipMethodId }));
            yield baseEffectHandler({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse() {
                    if (onSuccess) onSuccess();
                    yield put(getCartRoutine.trigger());
                    yield put(cartUpdateExpeditedShippingRoutine.success({ ...cartObjectPayload, shipMethodId }));
                },
                *onError(data) {
                    if (onFailure) onFailure();
                    yield put(cartUpdateExpeditedShippingRoutine.failure(data));
                }
            });
        } catch (e: Error | unknown) {
            if (onFailure) onFailure();
            yield put(cartUpdateExpeditedShippingRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdateExpeditedShippingRoutine', e as Error);
        }
    });

    yield takeLatest(cartUpdatePaymentRoutine.TRIGGER, function* (action: PayloadAction<OrderBillShip>) {
        try {
            const { onSuccess, onFailure } = action.payload;

            const isLoggedIn: boolean | undefined = yield select(accountIsLoggedInSelector);
            const epostPatientNum: string = yield select(accountProfilEPostPatientNumSelector);
            const cartsObject: CartPayload[] = yield select(cartSelector);
            const cartObject = cartsObject?.find((cart) => cart?.Order.orderBillShip.paymentCardSeqNum !== null)
                ? cartsObject?.find((cart) => cart?.Order.orderBillShip.paymentCardSeqNum !== null)
                : getPatientCart(cartsObject, epostPatientNum);

            const cartObjectPayload = produce(cartObject, (draftCartObject) => {
                if (draftCartObject) {
                    draftCartObject.Order.orderBillShip = action.payload;
                }
            });

            yield baseEffectHandler({
                service: cartService.updateCart().post,
                isAuthenticatedService: true,
                isLoggedIn,
                data: cartObjectPayload?.Order,
                *onResponse(data) {
                    yield put(getCartRoutine.trigger());
                    if (data.status === 204) {
                        if (onSuccess) onSuccess();
                        yield put(
                            cartUpdatePaymentRoutine.success({ Order: cartObjectPayload?.Order, epostPatientNum })
                        );
                    }
                },
                *onError(data) {
                    if (onFailure) onFailure();
                    yield put(cartUpdatePaymentRoutine.failure(data));
                }
            });
        } catch (e: Error | unknown) {
            const { onFailure } = action.payload;
            if (onFailure) onFailure();
            yield put(cartUpdatePaymentRoutine.failure());
            TrackError('cart.sagas.ts', 'cartUpdatePaymentRoutine', e as Error);
        }
    });
}
