import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { AddressPayload } from 'types/account';
import { CartPayload } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import {
    AdjudicatedRxes,
    AuthenticateUserResponse,
    CreateOrUpdateAddressRequest,
    EasyRefillCart,
    EasyRefillCartOrder,
    EasyRefillCartPayload,
    EasyRefillPaymetricDetailsPayload,
    EasyRefillResponse,
    GetEasyRefillAddressDataResponse,
    GetEasyRefillPatientDataResponse,
    GetEasyRefillPatientFamilyDataResponse,
    GetEasyRefillPaymentCardDataResponse,
    VerifyUserResponse
} from 'types/easy-refill';
import { Allergies, HealthConditions, MedicalConditions } from 'types/health-profile';
import { PlansObjectPayload } from 'types/plans';
import { RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';
import { RX_ORDER_STATUS, RX_STATUS_PAYLOAD, RX_WEB_ELIGIBILITY_STATUS } from 'enums/prescription';

import { setEasyRefillCartLoading } from './easy-refill.actions';
import { hasRxInFamily } from './easy-refill.helpers';
import {
    easyRefillAddAddressesRoutine,
    easyRefillAddRxToRefillRoutine,
    easyRefillAuthenticateUserRoutine,
    easyRefillCancelOrderLineRoutine,
    easyRefillCancelOrderRoutine,
    easyRefillFetchHealthConditionsRoutine,
    easyRefillGetCartRoutine,
    easyRefillGetPatientAddressesRoutine,
    easyRefillGetPatientDataRoutine,
    easyRefillGetPatientPaymentCardsRoutine,
    easyRefillGetPatientPlansRoutine,
    easyRefillGetSecretKeyHashRoutine,
    easyRefillPaymetricDetailsRoutine,
    easyRefillRemoveRxToRefillRoutine,
    easyRefillStartOrderRoutine,
    easyRefillSubmitOrderRoutine,
    easyRefillToggleAutoRefillRxStatusRoutine,
    easyRefillUpdateAddressesRoutine,
    easyRefillUpdateAllergiesRoutine,
    easyRefillUpdateCartPaymentRoutine,
    easyRefillUpdateMedicalConditionsRoutine,
    easyRefillUpdatePaymentCardsRoutine,
    easyRefillUpdateShippingMethodRoutine,
    easyRefillUpdateShippingRoutine,
    easyRefillVerifyUserRoutine
} from './easy-refill.routines';

export const defaultMedicalConditions = {
    None: false,
    Asthma: false,
    COPD: false,
    Cholesterol: false,
    Diabetes: false,
    Glaucoma: false,
    Heart: false,
    Hypertension: false,
    Kidney: false,
    Stroke: false,
    Thyroid: false,
    Other: '',
    TemporaryOther: '',
    ePostPatientNum: ''
};
export const defaultAllergies = {
    Tetracyclines: false,
    Penicillin: false,
    Cephalosporins: false,
    Ampicillin: false,
    Sulfa: false,
    Erythromycin: false,
    Aspirin: false,
    Codeine: false,
    NSAIDs: false,
    Quinolones: false,
    Other: '',
    None: false,
    TemporaryOther: '',
    ePostPatientNum: ''
};

export interface AutoRefillFlag {
    rxNumber: string;
    autoRefillEligible: boolean;
    autoRefillEnabled: boolean;
    planAutoRefillEligible: boolean;
}

export interface EasyRefillState {
    userVerified: 'UserNotVerified' | 'UserVerified' | 'MaxAttemptsReached' | 'TokenValid' | 'TokenExpired' | '';
    securityToken: string;
    secretKeyHash: string;
    bearerToken: string;
    error: {
        messageText?: string;
    };
    loadingPrescriptions: boolean;
    prescriptionsLoaded: boolean;
    firstName: string;
    lastName: string;
    rxResults: RxDetails[];
    autoRefillFlags: AutoRefillFlag[];
    epostPlanNum: string;
    planAlias: BIRDI_PLANS;
    loadingAddress: boolean;
    loadingCart: boolean;
    addresses: AddressPayload[];
    addressToAddOrUpdate?: AddressPayload;
    loadingPaymentCard: boolean;
    paymentCards: CreditCardPayload[];
    refillOrder: EasyRefillCartOrder;
    adjudicatedRxes: AdjudicatedRxes;
    orderInvoiceNumber: string;
    emailSent: boolean;
    isBusy: boolean;
    epostPatientNum: string;
    paymetricDetails?: EasyRefillPaymetricDetailsPayload | undefined;
    healthConditions: HealthConditions;
    isCaregiver: boolean;
    familyDependents: GetEasyRefillPatientDataResponse[];
    // DRX-2008: Create a initial state for the cart.
    cart?: CartPayload[];
    orderPlaced: boolean;
    orderNumber: string;
    patientPlans: PlansObjectPayload[];
    isRemoveDisabled: boolean;
    isToggleBusy?: boolean;
    isFamilyMembersWithRx: boolean;
    patientAutoRefill: boolean;
    autoRefill: boolean;
    isLoadingHealthConditions?: boolean;
    isLoadingPrescriptions?: boolean;
    isLoadingCart?: boolean;
    activeTab: string | null;
    loadingPlans: boolean;
}

const initialState: EasyRefillState = {
    userVerified: '',
    securityToken: '',
    secretKeyHash: '',
    bearerToken: '',
    error: {
        messageText: undefined
    },
    loadingPrescriptions: false,
    prescriptionsLoaded: false,
    firstName: '',
    lastName: '',
    rxResults: [],
    autoRefillFlags: [],
    epostPlanNum: '',
    planAlias: BIRDI_PLANS.NONE,
    loadingAddress: false,
    loadingCart: false,
    addresses: [],
    addressToAddOrUpdate: undefined,
    loadingPaymentCard: false,
    paymentCards: [],
    refillOrder: {
        rxsToRefill: [],
        expeditedShipping: false,
        addressSeqNum: '',
        paymentCardSeqNum: ''
    },
    adjudicatedRxes: {},
    orderInvoiceNumber: '',
    emailSent: false,
    isBusy: false,
    epostPatientNum: '',
    healthConditions: {
        medicalConditions: defaultMedicalConditions,
        allergies: defaultAllergies
    },
    isCaregiver: false,
    familyDependents: [],
    cart: undefined,
    orderPlaced: false,
    orderNumber: '',
    patientPlans: [],
    isRemoveDisabled: false,
    isToggleBusy: false,
    isFamilyMembersWithRx: false,
    patientAutoRefill: false,
    autoRefill: false,
    isLoadingHealthConditions: false,
    isLoadingCart: false,
    activeTab: '',
    loadingPlans: false
};

const easyRefillSlice = createSlice({
    name: 'easy-refill',
    initialState,
    reducers: {
        setSecurityToken: (state, action: PayloadAction<{ securityToken: string }>) => {
            state.securityToken = action.payload.securityToken;
        },
        resetBearerToken: (state) => {
            state.bearerToken = initialState.bearerToken;
        },
        setActiveTab: (state, { payload }: PayloadAction<string | null>) => {
            state.activeTab = payload;
        }
    },
    extraReducers: ({ addCase }) => {
        /**
         * Easy Refill reducers
         */
        // Verify User
        addCase(easyRefillVerifyUserRoutine.SUCCESS, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.userVerified = payload.verificationStatus;
                draftState.epostPatientNum = payload.epostPatientNum;
            })
        );
        addCase(easyRefillVerifyUserRoutine.FAILURE, (state, { payload }: PayloadAction<VerifyUserResponse>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload.messageText;
            })
        );
        // Getting user secret key hash for authentication
        addCase(easyRefillGetSecretKeyHashRoutine.SUCCESS, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.secretKeyHash = payload;
            })
        );
        addCase(easyRefillGetSecretKeyHashRoutine.FAILURE, (state, { payload }: PayloadAction<string>) =>
            produce(state, (draftState) => {
                draftState.error.messageText = payload;
            })
        );
        // authenticate user
        addCase(
            easyRefillAuthenticateUserRoutine.SUCCESS,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = payload.bearerToken;
                    draftState.isCaregiver = payload.isCaregiver;
                })
        );
        addCase(
            easyRefillAuthenticateUserRoutine.FAILURE,
            (state, { payload }: PayloadAction<AuthenticateUserResponse>) =>
                produce(state, (draftState) => {
                    draftState.bearerToken = initialState.bearerToken;
                    draftState.error.messageText = payload.messageText;
                })
        );
        // Fetching Patient data
        addCase(
            easyRefillGetPatientDataRoutine.TRIGGER,
            (state, { payload }: PayloadAction<{ onFailure?: () => void; withoutLoading?: boolean }>) => {
                return produce(state, (draftState) => {
                    if (!payload.withoutLoading) {
                        draftState.loadingPrescriptions = true;
                    }
                    draftState.isToggleBusy = true;
                    draftState.isBusy = true;
                    draftState.isLoadingPrescriptions = true;
                });
            }
        );
        addCase(
            easyRefillGetPatientDataRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillPatientFamilyDataResponse>) =>
                produce(state, (draftState) => {
                    const patientData = state.isCaregiver
                        ? payload.data.find((patient) => patient.epostPatientNum === state.epostPatientNum)
                        : payload.data[0];

                    draftState.familyDependents = state.isCaregiver ? payload.data : [];
                    draftState.loadingPrescriptions = false;
                    draftState.autoRefillFlags = patientData?.autoRefillFlags || [];
                    draftState.rxResults = patientData?.rxResults || [];
                    draftState.firstName = patientData?.firstName || '';
                    draftState.lastName = patientData?.lastName || '';
                    draftState.epostPlanNum = patientData?.epostPlanNum || '';
                    draftState.planAlias = patientData?.planAlias || BIRDI_PLANS.NONE;
                    draftState.patientAutoRefill = patientData?.patientAutoRefill || false;
                    draftState.autoRefill = patientData?.autoRefill || false;
                    draftState.prescriptionsLoaded = true;
                    draftState.isToggleBusy = false;
                    draftState.isLoadingPrescriptions = false;
                    draftState.isBusy = false;
                    draftState.activeTab = patientData?.epostPatientNum || '';

                    if (state.isCaregiver) {
                        // DRX-2420: Only when the patient is a Caregiver helper would be called.
                        draftState.isFamilyMembersWithRx = hasRxInFamily(payload.data);
                    }
                })
        );
        addCase(easyRefillGetPatientDataRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPrescriptions = false;
                draftState.rxResults = [];
                draftState.isToggleBusy = false;
                draftState.isLoadingPrescriptions = false;
                draftState.isBusy = false;
            })
        );

        /**
         * Easy Refill Addresses Routine Reducers
         */

        addCase(easyRefillGetPatientAddressesRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(
            easyRefillGetPatientAddressesRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillAddressDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = false;
                    const filteredAddresses = payload.addresses.filter((address) => address.active === true);
                    draftState.addresses = filteredAddresses;
                })
        );
        addCase(easyRefillGetPatientAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
                draftState.addresses = [];
            })
        );

        addCase(
            easyRefillAddAddressesRoutine.TRIGGER,
            (state, { payload }: PayloadAction<CreateOrUpdateAddressRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = true;
                    draftState.addressToAddOrUpdate = payload;
                })
        );
        addCase(easyRefillAddAddressesRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );
        addCase(easyRefillAddAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        addCase(
            easyRefillUpdateAddressesRoutine.TRIGGER,
            (state, { payload }: PayloadAction<CreateOrUpdateAddressRequest>) =>
                produce(state, (draftState) => {
                    draftState.loadingAddress = true;
                    draftState.addressToAddOrUpdate = payload;
                })
        );
        addCase(easyRefillUpdateAddressesRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );
        addCase(easyRefillUpdateAddressesRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        /**
         * Easy Refill Payment Cards Routine Reducers
         */

        addCase(easyRefillGetPatientPaymentCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = true;
            })
        );
        addCase(
            easyRefillGetPatientPaymentCardsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<GetEasyRefillPaymentCardDataResponse>) =>
                produce(state, (draftState) => {
                    draftState.loadingPaymentCard = false;

                    const creditCards: CreditCardPayload[] =
                        Array.isArray(payload.paymentCards) && payload.paymentCards.length > 0
                            ? payload.paymentCards
                            : [];

                    const sortedCreditCards = [...creditCards].sort((cardA, cardB) => {
                        return (cardB.defaultCard === true ? 1 : 0) - (cardA.defaultCard === true ? 1 : 0);
                    });

                    draftState.paymentCards = sortedCreditCards;
                })
        );
        addCase(easyRefillGetPatientPaymentCardsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPaymentCard = false;
            })
        );

        addCase(easyRefillPaymetricDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.paymetricDetails = payload;
            })
        );

        addCase(easyRefillSubmitOrderRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCart = true;
            })
        );
        addCase(
            easyRefillSubmitOrderRoutine.SUCCESS,
            (
                state,
                {
                    payload
                }: PayloadAction<{ cart: EasyRefillCartPayload[]; orderPlaced: boolean; isCaregiver: boolean }>
            ) =>
                produce(state, (draftState) => {
                    const orderNumber = payload.isCaregiver
                        ? payload.cart[0].birdiOrderNumber
                        : payload.cart[0].order.orderHeader.orderNum;

                    draftState.orderPlaced = payload.orderPlaced;
                    draftState.orderNumber = orderNumber || '';
                    draftState.isLoadingCart = false;
                })
        );
        addCase(easyRefillSubmitOrderRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingCart = false;
            })
        );

        addCase(easyRefillCancelOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<EasyRefillResponse>) =>
            produce(state, (draftState) => {
                draftState.adjudicatedRxes = {};
                draftState.rxResults = [];
                draftState.refillOrder.rxsToRefill = [];
                draftState.isLoadingPrescriptions = true;
            })
        );
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        addCase(easyRefillCancelOrderRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isLoadingPrescriptions = false;
            })
        );
        addCase(easyRefillRemoveRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
        addCase(easyRefillAddRxToRefillRoutine.SUCCESS, (state, { payload }: PayloadAction<string[]>) =>
            produce(state, (draftState) => {
                draftState.refillOrder.rxsToRefill = payload;
            })
        );
        addCase(easyRefillToggleAutoRefillRxStatusRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(
            easyRefillToggleAutoRefillRxStatusRoutine.SUCCESS,
            (state, { payload }: PayloadAction<{ rxNumber: string; autoRefillEnabled: boolean; isRenew: boolean }>) =>
                produce(state, (draftState) => {
                    const autoRefillFlags = draftState.autoRefillFlags;
                    const rxResults = draftState.rxResults;
                    const familyDependents = draftState.familyDependents;

                    const rxIndex = autoRefillFlags?.findIndex((rx) => rx.rxNumber === payload.rxNumber);

                    draftState.rxResults = rxResults?.map((rx) => {
                        if (rx.rxNumber === payload.rxNumber) {
                            return {
                                ...rx,
                                consentExpiration: payload.isRenew ? '' : rx.consentExpiration
                            };
                        } else {
                            return rx;
                        }
                    });

                    const fdIndex = familyDependents.findIndex(
                        (fd) => fd.rxResults && fd.rxResults.some((rx) => rx.rxNumber === payload.rxNumber)
                    );

                    if (fdIndex !== -1) {
                        const findIndex = familyDependents[fdIndex].rxResults.findIndex(
                            (rx) => rx.rxNumber === payload.rxNumber
                        );
                        if (findIndex !== -1) {
                            Object.assign(familyDependents[fdIndex].rxResults[findIndex], {
                                consentExpiration: payload.isRenew
                                    ? ''
                                    : familyDependents[fdIndex].rxResults[findIndex].consentExpiration
                            });
                        }
                    }

                    draftState.autoRefillFlags = autoRefillFlags?.map((autoRefillFlag, index) => {
                        if (index !== rxIndex) {
                            return autoRefillFlag;
                        }

                        return { ...autoRefillFlag, autoRefillEnabled: payload.autoRefillEnabled };
                    });
                    draftState.isBusy = false;
                })
        );
        addCase(easyRefillToggleAutoRefillRxStatusRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
        /**
         * Health Conditions
         */
        addCase(
            easyRefillUpdateMedicalConditionsRoutine.SUCCESS,
            (state, { payload }: PayloadAction<MedicalConditions>) =>
                produce(state, (draftState) => {
                    if (draftState.healthConditions) {
                        draftState.healthConditions.medicalConditions.TemporaryOther = payload.TemporaryOther;
                    } else {
                        draftState.healthConditions = {
                            allergies: state.healthConditions?.allergies || defaultAllergies,
                            medicalConditions: {
                                ...payload,
                                TemporaryOther: state.healthConditions?.medicalConditions.TemporaryOther
                            }
                        };
                    }
                })
        );
        addCase(easyRefillUpdateAllergiesRoutine.SUCCESS, (state, { payload }: PayloadAction<Allergies>) =>
            produce(state, (draftState) => {
                if (draftState.healthConditions) {
                    draftState.healthConditions.allergies.TemporaryOther = payload.TemporaryOther;
                } else {
                    draftState.healthConditions = {
                        allergies: {
                            ...payload,
                            TemporaryOther: state.healthConditions?.allergies.TemporaryOther
                        },
                        medicalConditions: state.healthConditions?.medicalConditions || defaultMedicalConditions
                    };
                }
            })
        );

        addCase(easyRefillFetchHealthConditionsRoutine.TRIGGER, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.isLoadingHealthConditions = true;
            })
        );
        addCase(easyRefillFetchHealthConditionsRoutine.SUCCESS, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.healthConditions = {
                    medicalConditions: {
                        ...payload.medicalConditions,
                        TemporaryOther:
                            payload.medicalConditions.TemporaryOther ||
                            state.healthConditions.medicalConditions.TemporaryOther,
                        ePostPatientNum: payload.medicalConditions.EPostPatientNum as string
                    },
                    allergies: {
                        ...payload.allergies,
                        TemporaryOther:
                            payload.allergies.TemporaryOther || state.healthConditions.allergies.TemporaryOther,
                        ePostPatientNum: payload.medicalConditions.EPostPatientNum as string
                    }
                };
                draftState.isLoadingHealthConditions = false;
            })
        );
        addCase(easyRefillFetchHealthConditionsRoutine.FAILURE, (state, { payload }: PayloadAction<HealthConditions>) =>
            produce(state, (draftState) => {
                draftState.isLoadingHealthConditions = false;
            })
        );
        /**
         * Get Cart Reducers
         */
        addCase(easyRefillGetCartRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = true;
            })
        );

        addCase(easyRefillGetCartRoutine.SUCCESS, (state, { payload }: PayloadAction<EasyRefillCart>) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.isRemoveDisabled = false;
                draftState.loadingAddress = false;

                if (draftState.isCaregiver) {
                    draftState.cart = [...payload.cart];
                } else {
                    draftState.cart = payload.cart.map((cart: CartPayload) => ({
                        ...cart,
                        EpostPatientNum: payload.epostPatientNum
                    }));
                }
            })
        );
        addCase(easyRefillGetCartRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.cart = undefined;
                draftState.orderPlaced = initialState.orderPlaced;
                draftState.isRemoveDisabled = false;
                draftState.loadingAddress = false;
            })
        );

        /**
         * Start Order Reducers
         */
        addCase(easyRefillStartOrderRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
                draftState.isRemoveDisabled = true;
            })
        );
        addCase(easyRefillStartOrderRoutine.SUCCESS, (state, { payload }: PayloadAction<CartPayload[]>) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );
        addCase(easyRefillStartOrderRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
                draftState.isRemoveDisabled = false;
            })
        );

        /**
         * Update shipping address
         */
        addCase(easyRefillUpdateShippingRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(easyRefillUpdateShippingRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        addCase(easyRefillUpdateShippingMethodRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = true;
            })
        );
        addCase(easyRefillUpdateShippingMethodRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingAddress = false;
            })
        );

        /**
         * Get Patient Plans
         */
        addCase(easyRefillGetPatientPlansRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingPlans = true;
            })
        );
        addCase(easyRefillGetPatientPlansRoutine.SUCCESS, (state, { payload }: PayloadAction<PlansObjectPayload[]>) =>
            produce(state, (draftState) => {
                draftState.patientPlans = payload;
                draftState.loadingPlans = false;
            })
        );
        addCase(easyRefillGetPatientPlansRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.patientPlans = [];
                draftState.loadingPlans = true;
            })
        );

        /**
         * Remove Cart Item
         */
        addCase(easyRefillCancelOrderLineRoutine.TRIGGER, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.isBusy = true;
            })
        );
        addCase(easyRefillCancelOrderLineRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) => {
            return produce(state, (draftState) => {
                draftState.rxResults = draftState.rxResults.map((rx) => {
                    if (rx.rxNumber === payload.rxNumber) {
                        return {
                            ...rx,
                            inOrderCart: false,
                            orderStatus: RX_ORDER_STATUS.NEW_RX,
                            webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE,
                            rxStatus: RX_STATUS_PAYLOAD.PROFILED
                        };
                    }
                    return rx;
                });
                draftState.familyDependents = draftState.familyDependents.map((dependent) => {
                    const dependentRxResults = dependent.rxResults.map((rx) => {
                        if (rx.rxNumber === payload.rxNumber) {
                            return {
                                ...rx,
                                inOrderCart: false,
                                orderStatus: RX_ORDER_STATUS.NEW_RX,
                                webEligibilityStatus: RX_WEB_ELIGIBILITY_STATUS.ELIGIBLE,
                                rxStatus: RX_STATUS_PAYLOAD.PROFILED
                            };
                        }
                        return rx;
                    });

                    return {
                        ...dependent,
                        rxResults: dependentRxResults
                    };
                });
            });
        });

        addCase(easyRefillCancelOrderLineRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isBusy = false;
            })
        );

        addCase(easyRefillUpdateCartPaymentRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = true;
                draftState.isLoadingCart = true;
            })
        );

        addCase(easyRefillUpdateCartPaymentRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.isLoadingCart = false;
            })
        );

        addCase(easyRefillUpdatePaymentCardsRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = true;
                draftState.isLoadingCart = true;
            })
        );

        addCase(easyRefillUpdatePaymentCardsRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.isLoadingCart = false;
            })
        );

        addCase(easyRefillUpdatePaymentCardsRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.loadingCart = false;
                draftState.isLoadingCart = false;
            })
        );
        addCase(setEasyRefillCartLoading, (state, { payload }: PayloadAction<{ isLoadingCart: boolean }>) =>
            produce(state, (draftState) => {
                draftState.isLoadingCart = payload.isLoadingCart;
            })
        );
    }
});

export const easyRefillActions = easyRefillSlice.actions;

export default easyRefillSlice.reducer;
