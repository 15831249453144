import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import VisibilityIcon from 'ui-kit/icons/visibility-icon/visibility-icon';
import { PasswordTextProps } from 'ui-kit/password-text/password-text.props';
import Text from 'ui-kit/text/text';

const PasswordText = ({ showErrorsText = false, type, ...rest }: PasswordTextProps) => {
    const [showText, setShowText] = useState(false);
    const { t } = useTranslation();

    return (
        <div className={`password-text`}>
            <Text
                type={showText ? 'text' : type}
                showErrorsText={showErrorsText}
                autoComplete="current-password"
                {...rest}
                icon={
                    <VisibilityIcon
                        toggled={showText}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: '16px',
                            right: '20px'
                        }}
                        imageAltText={t('components.passwordText.reveal')}
                        onClick={() => setShowText(!showText)}
                        ariaLabel={{
                            checked: t('components.passwordText.revealPassword.checked'),
                            unchecked: t('components.passwordText.revealPassword.unchecked')
                        }}
                    />
                }
            />
        </div>
    );
};

export default PasswordText;
