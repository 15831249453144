import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';

import { AddressPayload } from 'types/account';

import useCart from 'hooks/useCart';

const useProfile = () => {
    const { t } = useTranslation();
    const { cartData } = useCart();

    const getAddressCards = useCallback(
        (addresses: AddressPayload[]) =>
            addresses.map((address) => {
                return {
                    addressSeqNum: address.addressSeqNum,
                    defaultAddress: address.defaultShip,
                    isChecked: address.addressSeqNum === cartData?.address?.addressSeqNum,
                    address1: address.address1,
                    address2: address.address2,
                    city: address.city,
                    country: address.country,
                    state: address.state,
                    zipcode: address.zipcode,
                    zipcodeFour: address.zipcodeFour,
                    defaultAddressLabel: t('shipping.shipToThisAddressLabel'),
                    addressType: address.addressTypeDesc,
                    isProfile: false
                } as AddressCardProps;
            }),
        [cartData]
    );

    return { getAddressCards };
};

export default useProfile;
