import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

// Reducers
import accountReducer from 'state/account/account.reducers';
import accountSaga from 'state/account/account.sagas';
import addTransferPrescriptionReducer from 'state/add-transfer-prescription/add-transfer-prescription.reducers';
import addTransferPrescriptionSaga from 'state/add-transfer-prescription/add-transfer-prescription.sagas';
import birdiModalReducer from 'state/birdi-modal/birdi-modal.reducers';
import cacheReducer from 'state/cache/cache.reducers';
import cartReducer from 'state/cart/cart.reducers';
import cartSaga from 'state/cart/cart.sagas';
import drugReducer from 'state/drug/drug.reducers';
import drugSaga from 'state/drug/drug.sagas';
import drupalReducer from 'state/drupal/drupal.reducers';
import medicineCabinetReducer from 'state/medicine-cabinet/medicine-cabinet.reducers';
import medicineCabinetSaga from 'state/medicine-cabinet/medicine-cabinet.sagas';
import uspsReducer from 'state/usps/usps.reducers';
import uspsSaga from 'state/usps/usps.sagas';

import autoRefillReducer from './auto-refill/auto-refill.reducer';
import autoRefillSaga from './auto-refill/auto-refill.sagas';
import chatbotReducer from './chatbot/chatbot.reducers';
import discountCardReducers from './discount-card/discount-card.reducers';
import discountCardSaga from './discount-card/discount-card.sagas';
import drupalSaga from './drupal/drupal.sagas';
import easyRefillReducer from './easy-refill/easy-refill.reducer';
import easyRefillSaga from './easy-refill/easy-refill.sagas';
import familyAccountReducer from './family-account/family-account.reducer';
import familyAccountSaga from './family-account/family-account.sagas';
import familyProfileReducer from './family-profile/family-profile.reducers';
import familyProfileSaga from './family-profile/family-profile.sagas';
import joinMembershipPlanReducer from './join-membership-plan/join-membership-plan.reducer';
import joinMembershipPlanSaga from './join-membership-plan/join-membership-plan.sagas';
import medicalConditionsReducer from './medical-conditions/medical-conditions.reducers';
import medicalConditionsSaga from './medical-conditions/medical-conditions.sagas';
import membershipRegistrationReducer from './membership-registration/membership-registration.reducer';
import membershipRegistrationSaga from './membership-registration/membership-registration.sagas';
import membershipReducer from './membership/membership.reducers';
import membershipSaga from './membership/membership.sagas';
import modalComponentReducer from './modal/modal.reducer';
import paymentsReducers from './payments/payments.reducers';
import paymentsSaga from './payments/payments.sagas';
import pharmacyReducer from './pharmacy/pharmacy.reducer';
import PharmacySaga from './pharmacy/pharmacy.sagas';
import physicianReducer from './physician/physician.reducer';
import physicianSaga from './physician/physician.sagas';
import registrationReducer from './registration/registration.reducer';
import registrationSaga from './registration/registration.sagas';
import uploadPhotoReducer from './upload-photo/upload-photo.reducers';

const sagaMiddleware = createSagaMiddleware();

const sagas = [
    accountSaga,
    medicineCabinetSaga,
    uspsSaga,
    addTransferPrescriptionSaga,
    cartSaga,
    drugSaga,
    drupalSaga,
    physicianSaga,
    PharmacySaga,
    easyRefillSaga,
    autoRefillSaga,
    familyAccountSaga,
    familyProfileSaga,
    joinMembershipPlanSaga,
    membershipRegistrationSaga,
    membershipSaga,
    medicalConditionsSaga,
    discountCardSaga,
    paymentsSaga,
    registrationSaga
];
const reducers = combineReducers({
    accountReducer,
    birdiModalReducer,
    medicineCabinetReducer,
    uspsReducer,
    addTransferPrescriptionReducer,
    cacheReducer,
    cartReducer,
    drugReducer,
    drupalReducer,
    physicianReducer,
    pharmacyReducer,
    easyRefillReducer,
    autoRefillReducer,
    familyAccountReducer,
    joinMembershipPlanReducer,
    familyProfileReducer,
    uploadPhotoReducer,
    chatbotReducer,
    membershipRegistrationReducer,
    membershipReducer,
    medicalConditionsReducer,
    modalComponentReducer,
    discountCardReducers,
    paymentsReducers,
    registrationReducer
});

const rootReducer = (state: any, action: any) => {
    if (['ACCOUNT/LOGOUT/TRIGGER', 'ACCOUNT/LOGOUT'].includes(action.type)) {
        state = undefined;
    }
    return reducers(state, action);
};

const createStore = () => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: [sagaMiddleware]
    });
    sagas.map(sagaMiddleware.run);
    return store;
};

export type RootState = ReturnType<typeof rootReducer>;
export default createStore;
