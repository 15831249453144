import { useLocation } from '@reach/router';
import { ENABLE_CHATBOT_LIST_PATH, ENABLE_CHATBOT_PATH } from 'gatsby-env-variables';

import storageHelper from 'util/storageHelper';
import { matchArrayItemsWithWildCard } from 'util/string';

import { IS_BASIC_AUTH_ENABLED } from 'hoc/withBasicAuth';

/**
 * A hook that determines whether the chatbot should be visible based on the current page's path.
 *
 * If ENABLE_CHATBOT_PATH is true, the chatbot will be visible on paths specified in
 * ENABLE_CHATBOT_LIST_PATH. If ENABLE_CHATBOT_PATH is false, the chatbot will be visible on all
 * paths except those specified in ENABLE_CHATBOT_LIST_PATH.
 *
 * The ENABLE_CHATBOT_LIST_PATH is a newline-separated list of wildcard paths. The '<front>'
 * special path can be used to refer to the root path ('/').
 *
 * @returns {boolean} true if the chatbot should be visible, false otherwise
 */
const useIsChatbotVisible = () => {
    const location = useLocation();
    const isFrontendAuthenticated = storageHelper.cookies.getBasicAuthCookie();

    // DRX-4210: Hide chatbot if frontend protection auth is showed.
    if (!isFrontendAuthenticated && IS_BASIC_AUTH_ENABLED) {
        return false;
    }

    // Prevent to break if the options isn't already filled in the CMS.
    if (!ENABLE_CHATBOT_LIST_PATH || !ENABLE_CHATBOT_PATH) {
        return true;
    }

    const isListItemsVisible = !ENABLE_CHATBOT_PATH;
    const paths: string[] = ENABLE_CHATBOT_LIST_PATH.split(/\r?\n/);

    if (!paths.length) {
        return isListItemsVisible;
    }

    if (location.pathname === '/' && paths.includes('<front>')) {
        return isListItemsVisible;
    }

    if (matchArrayItemsWithWildCard(paths, location.pathname)) {
        return isListItemsVisible;
    }

    return !isListItemsVisible;
};

export default useIsChatbotVisible;
