import React, { useEffect, useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// UI Kit & components
import Button from 'ui-kit/button/button';
import { ButtonProps, ButtonVariantProps } from 'ui-kit/button/button.props';
import ToastBox from 'ui-kit/toast-box/toast-box';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { PaymentAddNew } from 'components/payment-add-new/payment-add-new';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Pages
import { FailureUpdateCartModalContent } from 'pages/secure/cart/intra-page-items/_cart-update-modal-item';
import UpdateProfileModalContent, {
    FailureUpdateProfileModalContent
} from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import { accountGetAllCreditCardsRoutine, accountUpdateCreditCardRoutine } from 'state/account/account.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartUpdatePaymentRoutine, getCartRoutine } from 'state/cart/cart.routines';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';

// Types
import { CreditCardPayload } from 'types/credit-card';
import { OrderBillShip } from 'types/order-prescription';
import { PaymentCardProps } from 'types/payment';

// Utils
import { noop } from 'util/function';
import { getCreditCardEnding } from 'util/payments';

import useCart from 'hooks/useCart';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';
// Hooks
import useWindowDimensions from 'hooks/useWindowDimensions';

// Payment
import PaymentItem, { PaymentItemProps } from './payment-item/payment-item.component';
import './payment-method.style.scss';
import SelectedPayment from './selected-payment/selected-payment.component';

export interface PaymentMethodsProps extends Pick<PaymentItemProps, 'showSetDefaultLink' | 'showSelectCardRadioInput'> {
    buttonVariant?: typeof ButtonVariantProps;
    paymentRequiredMessage?: string;
    selectedCardSeqNum?: string;
    onCardSelectionChange?: (newSelectedCard: CreditCardPayload) => void;
    onDefaultCardChanged?: (newDefaultCard: CreditCardPayload) => void;
}

interface UpdatePrimaryCreditCardOptions {
    showPrimaryPaymentUpdatedModal?: boolean;
}

const PaymentMethod: React.FC<PaymentMethodsProps> = (props) => {
    const {
        buttonVariant = 'primary',
        onDefaultCardChanged = noop,
        paymentRequiredMessage,
        showSelectCardRadioInput = false,
        showSetDefaultLink = false
    } = props;

    // Hooks
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const { cartData } = useCart();
    const { mainUserIsCaregiver, cartIsBusy, mainUserCreditCards } = usePrescriptionFlow();

    // Refs
    const isInnerWidthViewportRef = React.useRef<boolean>(false);

    // Local Consts
    const orderBillShip = useMemo(() => cartData?.orderAddress, [cartData]);
    const paymentData = useMemo(() => mainUserCreditCards || [], [mainUserCreditCards]);
    const hasPaymentData = paymentData.length > 0;
    const hasOnePaymentMethod = paymentData?.length === 1;
    const hasMoreThanOnePaymentMethod = paymentData?.length > 1;
    const addFirstPaymentToTheOrder = useMemo(
        () => !cartData?.paymentMethod && hasOnePaymentMethod && !mainUserIsCaregiver,
        [cartData, hasOnePaymentMethod, mainUserIsCaregiver]
    );

    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);

    const handleCardSelectionChange = React.useCallback<NonNullable<PaymentMethodsProps['onCardSelectionChange']>>(
        (newSelectedCard) => {
            if (!newSelectedCard || !orderBillShip) return;

            const updatedOrderBillShip: OrderBillShip = {
                ...orderBillShip,
                paymentCardSeqNum: newSelectedCard.cardSeqNum.toString(),
                dependentPaymentSeqNum: mainUserIsCaregiver
                    ? orderBillShip.dependentPaymentSeqNum
                    : newSelectedCard.cardSeqNum.toString()
            };

            columnSectionRef?.current?.handleSaveChangesButtonClick();

            dispatch(
                cartUpdatePaymentRoutine.trigger({
                    ...updatedOrderBillShip,
                    onSuccess: () =>
                        dispatch(
                            getCartRoutine.trigger({
                                onSuccess: () => {
                                    if (hasMoreThanOnePaymentMethod) {
                                        dispatch(
                                            openModal({
                                                showClose: true,
                                                className: 'prescription-modal',
                                                bodyContent: (
                                                    <BirdiModalContent
                                                        icon={'success'}
                                                        title={t('modals.paymentMethods.success.title')}
                                                        body={t('modals.paymentMethods.success.description')}
                                                    />
                                                ),
                                                ctas: [
                                                    {
                                                        label: t('modals.paymentMethods.success.cta'),
                                                        variant: 'primary',
                                                        onClick: () => {
                                                            dispatch(closeModal({}));
                                                        },
                                                        dataGALocation: 'ReviewOrderUpdateCart'
                                                    }
                                                ]
                                            })
                                        );
                                    }
                                }
                            })
                        ),
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                className: 'prescription-modal',
                                bodyContent: (
                                    <FailureUpdateCartModalContent area={t('modals.updateCart.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateCart.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        },
                                        dataGALocation: 'ReviewOrderUpdateCartError'
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, orderBillShip, t]
    );

    const handleCardSelected = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSelectCardRadioInputChange'] =>
            () => {
                handleCardSelectionChange(card);
            },
        [handleCardSelectionChange]
    );

    const handleUpdatePrimaryCreditCard = React.useCallback(
        (newPrimaryCard: CreditCardPayload, opts: UpdatePrimaryCreditCardOptions = {}) => {
            const { showPrimaryPaymentUpdatedModal = false } = opts;

            if (!newPrimaryCard) {
                return;
            }

            dispatch(
                accountUpdateCreditCardRoutine.trigger({
                    ...newPrimaryCard,
                    onSuccess: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: showPrimaryPaymentUpdatedModal ? (
                                    <BirdiModalContent
                                        icon={'success'}
                                        title={t('modals.updateProfile.title')}
                                        body={t('modals.primaryPaymentMethodUpdated.body', {
                                            paymentMethod: `<strong>${t('pages.profile.payment.cardTypeAndNum', {
                                                cardNumber: getCreditCardEnding(newPrimaryCard.secureCardNumber),
                                                cardType: newPrimaryCard?.cardType
                                            })}</strong>`
                                        })}
                                    />
                                ) : (
                                    <UpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                onClose: () => {
                                    dispatch(accountGetAllCreditCardsRoutine.trigger());
                                },
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                            dispatch(accountGetAllCreditCardsRoutine.trigger());
                                        }
                                    }
                                ]
                            })
                        );

                        onDefaultCardChanged(newPrimaryCard);
                    },
                    onFailure: () => {
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent area={t('modals.updateProfile.areas.payment')} />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        },
        [dispatch, onDefaultCardChanged, t]
    );

    const handleAddNewPaymentClick = React.useCallback<NonNullable<ButtonProps['onClick']>>(() => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasModalHeader: false,
                isCloseable: true,
                isCentered: true,
                hasCustomContent: true,
                title: undefined,
                sideView: undefined,
                badge: undefined,
                variation: 'small',
                content: <PaymentAddNew mode="account" currentFlow="medicine-cabinet" />,
                customDialogClassName: 'payment-method-v2-modal cart-payment-modal',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    }, [dispatch]);

    const handleSetDefaultClick = React.useCallback(
        (card: CreditCardPayload): PaymentCardProps['onSetDefaultClick'] =>
            () => {
                handleUpdatePrimaryCreditCard(card);
            },
        [handleUpdatePrimaryCreditCard]
    );

    // DRX-3353: This ticket adds validation for invitee users and new insured users.
    // These users can create and place an order without a registered payment method.
    // The validation checks if the current cart lacks a cardSeqNum. When the user adds
    // a new payment method, the order will be updated to select the new payment method.
    useEffect(() => {
        if (addFirstPaymentToTheOrder) {
            handleCardSelectionChange(paymentData[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addFirstPaymentToTheOrder]);

    useEffect(() => {
        if (!hasPaymentData) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
    }, [dispatch, hasPaymentData]);

    useEffect(() => {
        if (width < 1200) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    return (
        <ColumnSectionEditModeToggle
            ref={columnSectionRef}
            className="payment-method"
            headerClassName="payment-method__header"
            title={t('components.medicineCabinetCart.paymentMethod.title')}
            isToggleDisabled={!hasPaymentData}
            editModeContent={
                <section>
                    {paymentData.length > 0 ? (
                        paymentData
                            .filter((filterCard) => filterCard.cardActive && filterCard.cardIsExpired !== true)
                            .map((card, index) => (
                                <React.Fragment key={`payment-card-${card.secureCardNumber}-${index}`}>
                                    <PaymentItem
                                        index={index}
                                        cardHolder={card.cardName}
                                        cardType={card.cardType}
                                        endingDigits={getCreditCardEnding(card.secureCardNumber)}
                                        expiryMonth={card.cardMonthNum.toString()}
                                        expiryYear={card.cardYear.slice(-2)}
                                        isDefaultCard={Boolean(card.defaultCard)}
                                        isSelectCardRadioInputChecked={
                                            card.cardSeqNum === cartData?.paymentMethod?.cardSeqNum
                                        }
                                        key={`payment-card-${card.secureCardNumber}-${index}`}
                                        onSelectCardRadioInputChange={handleCardSelected(card)}
                                        onSetDefaultClick={handleSetDefaultClick(card)}
                                        showSelectCardRadioInput={showSelectCardRadioInput}
                                        showSetDefaultLink={showSetDefaultLink}
                                        isInnerWidthViewport={isInnerWidthViewportRef}
                                        isLoading={cartIsBusy}
                                    />

                                    {index === paymentData.length - 1 && (
                                        <Button
                                            disabled={cartIsBusy}
                                            plusIcon
                                            IconType="secondary"
                                            className={`${buttonVariant ? ' text-cerulean sm-full' : 'p-0'}`}
                                            dataGAFormName="Payments"
                                            label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                                            onClick={handleAddNewPaymentClick}
                                            type="button"
                                            variant={buttonVariant}
                                        />
                                    )}
                                </React.Fragment>
                            ))
                    ) : (
                        <div className="empty-section">
                            {paymentRequiredMessage && (
                                <>
                                    <ToastBox variant="warning" icon="warning">
                                        <Trans i18nKey={paymentRequiredMessage} />
                                    </ToastBox>
                                    <Button
                                        plusIcon
                                        IconType="secondary"
                                        className={`${buttonVariant ? ' text-cerulean sm-full' : 'p-0'}`}
                                        dataGAFormName="Payments"
                                        label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                                        onClick={handleAddNewPaymentClick}
                                        type="button"
                                        variant={buttonVariant}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </section>
            }
        >
            <>
                <SelectedPayment
                    isInnerWidthViewport={isInnerWidthViewportRef}
                    selectedPaymentMethod={cartData?.paymentMethod}
                    creditCardsData={paymentData}
                />
                {!hasPaymentData && (
                    <Button
                        plusIcon
                        IconType="secondary"
                        className="payment-method__add-payment"
                        dataGAFormName="Payments"
                        label={t('components.medicineCabinetCart.paymentMethod.addPaymentMethod')}
                        onClick={handleAddNewPaymentClick}
                        type="button"
                        variant={buttonVariant}
                    />
                )}
            </>
        </ColumnSectionEditModeToggle>
    );
};

export default PaymentMethod;
