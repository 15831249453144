import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { ModalComponentContent, ModalComponentFooter, ModalComponentHeader } from 'components/modal/modal.component';
import { PaymentAddNew } from 'components/payment-add-new/payment-add-new';
import PaymentMethods from 'components/payment-methods/payment-methods.component';

// Pages
import { FailureUpdateProfileModalContent } from 'pages/secure/profile/intra-page-items/_profile-update-modal.item';

// State
import { accountGetAllCreditCardsRoutine } from 'state/account/account.routines';
import { accountCreditCardsSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { membershipUpdateDefaultPaymentMethodRoutine } from 'state/membership/membership.routines';
import { closeModalComponent, setModalStep } from 'state/modal/modal.reducer';
import { modalComponentStepSelector } from 'state/modal/modal.selector';
import { paymentsActions } from 'state/payments/payments.reducers';

// Types
import { CreditCardPayload } from 'types/credit-card';

import { ChangePaymentMethodProps } from './change-payment-method.props';
// Styles
import './change-payment-method.style.scss';

const ChangePaymentMethod = ({
    isModal = false,
    currentFlow,
    selectedPayment,
    modalSubtitle,
    isMultipleStep
}: ChangePaymentMethodProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<CreditCardPayload | undefined>(
        selectedPayment || undefined
    );

    const containerClasses = classNames('payment-container', { 'with-padding': isMultipleStep });

    const allPaymentData = useSelector(accountCreditCardsSelector);
    const currentModalStep = useSelector(modalComponentStepSelector);

    const isRequestVisit = currentFlow === 'request-visit';
    const isMembershipSettings = currentFlow === 'manage-membership';
    const isPaymentHistory = currentFlow === 'payment-history';

    const defaultCreditCard = useMemo(() => {
        return Array.isArray(allPaymentData) && allPaymentData.length > 0 && (isRequestVisit || isPaymentHistory)
            ? allPaymentData.find((card) => card.defaultCard)
            : allPaymentData?.find((card) => card.defaultMembershipPayment);
    }, [allPaymentData, isRequestVisit, isPaymentHistory]);

    const sortOrder = useMemo(() => {
        return isRequestVisit || isPaymentHistory ? false : true;
    }, [currentFlow]);

    useEffect(() => {
        if (allPaymentData === undefined) {
            dispatch(accountGetAllCreditCardsRoutine.trigger());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isMembershipSettings) setSelectedPaymentMethod(defaultCreditCard);
    }, []);

    const handleSubmitPaymentChange = () => {
        setIsBusy(true);
        if (isPaymentHistory) {
            dispatch(paymentsActions.setSelectedPaymentMethod(selectedPaymentMethod as CreditCardPayload));
            handleSuccessModal({ closeSuccessModal: true, resetModalStep: true });
        } else if (isRequestVisit) {
            dispatch(paymentsActions.setSelectedPaymentMethod(selectedPaymentMethod as CreditCardPayload));
            dispatch(closeModalComponent());
            handleSuccessModal({ closeSuccessModal: true });
        } else if (isMembershipSettings) {
            if (selectedPaymentMethod === defaultCreditCard) {
                dispatch(closeModalComponent());
                return;
            }
            dispatch(
                membershipUpdateDefaultPaymentMethodRoutine.trigger({
                    data: {
                        ...selectedPaymentMethod,
                        defaultMembershipPayment: true
                    },
                    onSuccess: () => {
                        dispatch(closeModalComponent());
                        setIsBusy(false);
                        handleSuccessModal({ closeSuccessModal: true });
                    },
                    onFailure: () => {
                        setIsBusy(false);
                        dispatch(
                            openModal({
                                showClose: true,
                                type: 'danger',
                                size: 'lg',
                                headerContent: (
                                    <BirdiModalHeaderDanger icon="alert" headerText={t('modals.updateProfile.error')} />
                                ),
                                bodyContent: (
                                    <FailureUpdateProfileModalContent
                                        content={t(
                                            'pages.profile.membership.manageMembership.modals.paymentMethodChangeError'
                                        )}
                                        area=""
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t('modals.updateProfile.labels.gotIt'),
                                        variant: 'primary',
                                        onClick: () => dispatch(closeModalComponent())
                                    }
                                ]
                            })
                        );
                    }
                })
            );
        } else {
            dispatch(paymentsActions.setSelectedPaymentMethod(selectedPaymentMethod as CreditCardPayload));
            dispatch(closeModalComponent());
        }
    };

    const handlePaymentMethodSelection = (selectedPaymentMethod: CreditCardPayload) => {
        setSelectedPaymentMethod(selectedPaymentMethod);
    };

    const handleOnNavigate = () => {
        dispatch(setModalStep({ modalStep: 1 }));
    };

    const handleSuccessModal = ({
        closeSuccessModal = false,
        resetModalStep = false,
        closeParentModal = false
    }: {
        closeSuccessModal?: boolean;
        resetModalStep?: boolean;
        closeParentModal?: boolean;
    }) => {
        dispatch(
            openModal({
                showClose: true,
                isModalCentered: true,
                bodyContent: (
                    <BirdiModalContent
                        icon="default"
                        title={t('modals.paymentMethods.success.title')}
                        body={t('modals.paymentMethods.success.description')}
                    />
                ),
                ctas: [
                    {
                        label: t('modals.paymentMethods.success.cta'),
                        variant: 'primary',
                        onClick: () => {
                            if (closeSuccessModal) dispatch(closeModal({}));
                            if (resetModalStep) dispatch(setModalStep({ modalStep: 0 }));
                            if (closeParentModal) dispatch(closeModalComponent());
                        }
                    }
                ],
                onClose: () => {
                    if (closeSuccessModal) dispatch(closeModal({}));
                    if (resetModalStep) dispatch(setModalStep({ modalStep: 0 }));
                    if (closeParentModal) dispatch(closeModalComponent());
                }
            })
        );
    };

    const getModalTitle = () => {
        switch (currentModalStep) {
            case 1:
                return t('pages.profile.membership.manageMembership.modals.changePaymentTitle');
            case 2:
                return t('pages.profile.membership.manageMembership.addNewPayment');
            default:
                return t('pages.profile.membership.manageMembership.modals.changePaymentTitle');
        }
    };

    const getModalContent = () => {
        switch (currentModalStep) {
            case 1:
                return (
                    <div className={containerClasses}>
                        {modalSubtitle && <h3 className="title">{modalSubtitle}</h3>}
                        {allPaymentData && (
                            <PaymentMethods
                                isModal
                                showLabel
                                showSelectCardRadioInput
                                withNewPaymentButton
                                paymentData={allPaymentData}
                                isProfile={false}
                                onCardSelectionChange={handlePaymentMethodSelection}
                                selectedCardSeqNum={selectedPaymentMethod?.cardSeqNum || defaultCreditCard?.cardSeqNum}
                                hasMembershipFilters={sortOrder}
                            />
                        )}
                    </div>
                );
            case 2:
                return <PaymentAddNew mode="account" isOnModal currentFlow={currentFlow} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {isModal ? (
                <ModalComponentContent>
                    {isMultipleStep && (
                        <ModalComponentHeader
                            hasDefaultTitle
                            isCloseable
                            hasTitleNavigation={currentModalStep !== 1}
                            title={getModalTitle()}
                            onNavigate={() => handleOnNavigate()}
                            onClose={() => {
                                dispatch(closeModalComponent());
                            }}
                        />
                    )}

                    {getModalContent()}

                    {currentModalStep === 1 && (
                        <ModalComponentFooter
                            cancelButtonLabel={t('modals.addNewPaymentMethod.buttons.cancel')}
                            onCancel={() => dispatch(closeModalComponent())}
                            continueButtonLabel={t('modals.addNewPaymentMethod.buttons.select')}
                            onContinue={handleSubmitPaymentChange}
                            isCTABusy={isBusy}
                            isCTADisabled={isBusy}
                        />
                    )}
                </ModalComponentContent>
            ) : (
                <div className="payment-container">
                    {allPaymentData && (
                        <PaymentMethods
                            showLabel
                            showSelectCardRadioInput
                            withNewPaymentButton
                            paymentData={allPaymentData}
                            onCardSelectionChange={handlePaymentMethodSelection}
                            selectedCardSeqNum={selectedPaymentMethod?.cardSeqNum || defaultCreditCard?.cardSeqNum}
                            hasMembershipFilters={sortOrder}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default ChangePaymentMethod;
