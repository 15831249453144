import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import { ResourcesFileListProps } from './resources-file-list.props';
import './resources-file-list.style.scss';

export default function ResoucesFileList({
    backgroundColor,
    eyebrowText,
    title,
    bodyUpperText,
    bodyText,
    icons,
    downloads,
    sectionIndex,
    fileListAlignment = 'left'
}: ResourcesFileListProps) {
    return (
        <Container fluid className={`resources-file-list ${backgroundColor ?? ''}`}>
            <Row className="justify-content-center">
                <Col xs={12} md={12} lg={8}>
                    <div className="resources-header">
                        <div className="h5 eyebrow-text">{eyebrowText}</div>
                        {sectionIndex === 0 && <h1 className="title">{title}</h1>}
                        {sectionIndex > 0 && <h2 className="h1 title">{title}</h2>}
                        <div className="spacer" />
                    </div>
                </Col>
            </Row>
            {bodyUpperText && (
                <Row className="body-text justify-content-center">
                    <Col>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(bodyUpperText, {
                                    allowedAttributes: {
                                        a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                        img: ['src', 'alt'],
                                        '*': ['class']
                                    }
                                })
                            }}
                        />
                    </Col>
                </Row>
            )}
            <Row className="justify-content-center">
                <Col lg={9}>
                    <Row className="resource-icons row-cols-1 row-cols-md-3 justify-content-center">
                        {React.Children.map(icons, (icon, index) => (
                            <>{icons && Array.isArray(icons) && icon}</>
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row className="body-text justify-content-center">
                <Col>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(bodyText, {
                                allowedAttributes: {
                                    a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </Col>
            </Row>
            {React.Children.count(downloads) > 0 && (
                <>
                    <Row className="rule justify-content-center">
                        <Col xs={12} lg={10}>
                            <div className="horiz-rule" />
                        </Col>
                    </Row>
                    <Row className="downloads justify-content-center">
                        <Col xs={12} lg={10} className={`resource-download-alignment__${fileListAlignment}`}>
                            {React.Children.map(downloads, (download, index) => (
                                <>{downloads && Array.isArray(downloads) && download}</>
                            ))}
                        </Col>
                    </Row>
                </>
            )}
        </Container>
    );
}
