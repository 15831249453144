import { useMemo } from 'react';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { renderHealthConditions } from 'components/health-profile/health-profile.component';

import {
    medicalConditionsAddOrUpdateRoutine,
    medicalConditionsAllergiesAddOrUpdateRoutine
} from 'state/medical-conditions/medical-conditions.routines';

import useHealthConditions from 'hooks/useHealthConditions';

import { HealthConditionsModalContentProps } from './health-profile.props';

export const HealthConditionsModalContentV2 = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    ePostPatientNum
}: HealthConditionsModalContentProps) => {
    const { getHealthConditionsByPatientNum } = useHealthConditions();
    const healthConditions = useMemo(
        () => getHealthConditionsByPatientNum(ePostPatientNum),
        [ePostPatientNum, getHealthConditionsByPatientNum]
    );

    if (!healthConditions) {
        return <></>;
    }

    const { conditionChoices, existingConditions, existingFreeformConditions } = healthConditions;

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'healthConditionsModalContent',
                action: medicalConditionsAddOrUpdateRoutine,
                conditionChoices,
                conditionType: 'condition',
                ePostPatientNum,
                existingConditions,
                existingFreeformConditions,
                freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};

export const AllergiesModalContentV2 = ({
    icon,
    title,
    subTitle,
    freeformConditionsLabel,
    submitLabel,
    onUpdateHealthConditions,
    ePostPatientNum
}: HealthConditionsModalContentProps) => {
    const { getHealthConditionsByPatientNum } = useHealthConditions();
    const healthConditions = useMemo(
        () => getHealthConditionsByPatientNum(ePostPatientNum),
        [ePostPatientNum, getHealthConditionsByPatientNum]
    );

    if (!healthConditions) {
        return <></>;
    }

    const { allergyChoices, existingAllergies, existingFreeformAllergies } = healthConditions;

    return (
        <BirdiModalContent
            icon={icon ?? 'none'}
            title={title}
            subTitle={subTitle}
            body={renderHealthConditions({
                isInModal: true,
                formName: 'allergiesModalContent',
                action: medicalConditionsAllergiesAddOrUpdateRoutine,
                conditionChoices: allergyChoices,
                conditionType: 'allergy',
                ePostPatientNum,
                existingConditions: existingAllergies,
                existingFreeformConditions: existingFreeformAllergies,
                freeformConditionsLabel: freeformConditionsLabel,
                onUpdateHealthConditions,
                submitLabel
            })}
        />
    );
};
