import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { autoRefillSelector } from 'state/auto-refill/auto-refill.selectors';
import { easyRefillSelector } from 'state/easy-refill/easy-refill.selectors';
import { medicalConditionsFetchHealthConditionsForPatientRoutine } from 'state/medical-conditions/medical-conditions.routines';
import { healthConditionsByPatientNumberSelector } from 'state/medical-conditions/medical-conditions.selector';

import { HealthConditionsByPatientNumber, HealthConditionsMapped } from 'types/health-profile';
import { Selector } from 'types/saga-handler';

import { ApiStatus } from 'enums/api-status';
import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

import { formatConditionsChoices, reduceExistingConditions } from 'util/health-conditions';

import useCart from './useCart';
import usePrescriptionFlow from './usePrescriptionFlow';

const useHealthConditions = (flow?: RX_AVAILABLE_FLOWS) => {
    const healthConditionsByPatientSelector: Record<RX_AVAILABLE_FLOWS, Selector<HealthConditionsByPatientNumber>> = {
        [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: healthConditionsByPatientNumberSelector,
        [RX_AVAILABLE_FLOWS.EASY_REFILL]: createSelector(easyRefillSelector, () => {
            return {} as HealthConditionsByPatientNumber;
        }),
        [RX_AVAILABLE_FLOWS.AUTO_REFILL]: createSelector(autoRefillSelector, () => {
            return {} as HealthConditionsByPatientNumber;
        })
    };

    const dispatch = useDispatch();
    const currentFlow = useMemo(() => flow || RX_AVAILABLE_FLOWS.MEDICINE_CABINET, [flow]);
    const { patientsInCart } = useCart();
    const { mainUserIsCaregiver, mainUserEpostPatientNum, familyMembersBasicData } = usePrescriptionFlow();

    const healthConditionsByPatient: HealthConditionsByPatientNumber = useSelector(
        healthConditionsByPatientSelector[currentFlow]
    );

    const healthConditionsMapped: HealthConditionsMapped[] = useMemo(() => {
        return Object.keys(healthConditionsByPatient).map((ePostPatientNum: string) => {
            const patientConditions = healthConditionsByPatient[ePostPatientNum];
            const patientAllergies = patientConditions.allergies;
            const patientMedicalConditions = patientConditions.medicalConditions;
            const isLoadingAllergies = patientConditions.allergiesApiStatus === ApiStatus.LOADING;
            const isLoadingMedicalConditions = patientConditions.medicalConditionsApiStatus === ApiStatus.LOADING;

            const PatientBasicData = familyMembersBasicData.find(
                (patient) => patient.epostPatientNum === ePostPatientNum
            );

            // allergies
            const existingAllergies = patientAllergies
                ? reduceExistingConditions({ conditions: patientAllergies })
                : [];
            const existingFreeformAllergies = patientAllergies ? patientAllergies.Other : '';
            const tempExistingFreeformAllergies = patientAllergies ? patientAllergies.TemporaryOther : '';

            // Medical conditions
            const existingConditions = patientMedicalConditions
                ? reduceExistingConditions({ conditions: patientMedicalConditions })
                : [];
            const existingFreeformConditions = patientMedicalConditions ? patientMedicalConditions.Other : '';
            const tempExistingFreeformConditions = patientMedicalConditions
                ? patientMedicalConditions.TemporaryOther
                : '';
            const userHasNotSubmittedAllergies =
                existingAllergies.length === 0 &&
                (!existingFreeformAllergies || existingFreeformAllergies.length === 0) &&
                (!tempExistingFreeformAllergies || tempExistingFreeformAllergies.length === 0);
            const userHasNotSubmittedConditions =
                existingConditions.length === 0 &&
                (!existingFreeformConditions || existingFreeformConditions.length === 0) &&
                (!tempExistingFreeformConditions || tempExistingFreeformConditions.length === 0);

            return {
                allergyChoices: patientAllergies ? formatConditionsChoices(patientAllergies) : [],
                existingAllergies,
                existingFreeformAllergies,
                conditionChoices: patientMedicalConditions ? formatConditionsChoices(patientMedicalConditions) : [],
                existingConditions,
                existingFreeformConditions,
                userHasNotSubmittedAllergies,
                userHasNotSubmittedConditions,
                isLoadingAllergies,
                isLoadingMedicalConditions,
                ePostPatientNum,
                isMissingHealthConditions: userHasNotSubmittedAllergies || userHasNotSubmittedConditions,
                firstName: PatientBasicData?.firstName,
                lastName: PatientBasicData?.lastName
            };
        });
    }, [healthConditionsByPatient, familyMembersBasicData]);

    const getHealthConditionsByPatientNum = useCallback(
        (ePostPatientNum?: string): HealthConditionsMapped | undefined => {
            if (!ePostPatientNum) return;
            return healthConditionsMapped.find(
                (healthConditions) => healthConditions.ePostPatientNum === ePostPatientNum
            );
        },
        [healthConditionsMapped]
    );

    const getMultipleHealthConditionsByPatientNum = useCallback(
        (ePostPatientNums: string[]): HealthConditionsMapped[] => {
            if (ePostPatientNums.length === 0) return [];
            return healthConditionsMapped.filter((healthConditions) =>
                ePostPatientNums.includes(healthConditions.ePostPatientNum || '')
            );
        },
        [healthConditionsMapped]
    );

    const cartPatientsHealthConditions: HealthConditionsMapped[] = useMemo(() => {
        const patientsInCartEpost: string[] = patientsInCart.map((patient) => patient.epostPatientNum);
        const cartPatientsHealthConditions = getMultipleHealthConditionsByPatientNum(patientsInCartEpost);
        const caregiverHealthConditions = getHealthConditionsByPatientNum(mainUserEpostPatientNum);

        if (!caregiverHealthConditions && !!mainUserEpostPatientNum) {
            dispatch(
                medicalConditionsFetchHealthConditionsForPatientRoutine.trigger({
                    ePostPatientNumber: mainUserEpostPatientNum
                })
            );
        }

        if (!mainUserIsCaregiver || !caregiverHealthConditions) return cartPatientsHealthConditions;

        const healthConditionsIncludeCaregiver = cartPatientsHealthConditions.some(
            (patient) => patient.ePostPatientNum === mainUserEpostPatientNum
        );

        const patientBasicData = familyMembersBasicData.find(
            (patient) => patient.epostPatientNum === mainUserEpostPatientNum
        );

        if (!healthConditionsIncludeCaregiver)
            return [
                ...[
                    {
                        ...caregiverHealthConditions,
                        firstName: patientBasicData?.firstName,
                        lastName: patientBasicData?.lastName
                    }
                ],
                ...cartPatientsHealthConditions
            ];
        return cartPatientsHealthConditions;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        mainUserEpostPatientNum,
        mainUserIsCaregiver,
        patientsInCart,
        getMultipleHealthConditionsByPatientNum,
        getHealthConditionsByPatientNum
    ]);

    const isAnyCartPatientMissingHealthConditions = useMemo(
        () => cartPatientsHealthConditions.some((patient) => patient.isMissingHealthConditions),
        [cartPatientsHealthConditions]
    );

    return {
        healthConditionsByPatient,
        allPatientsHealthConditions: healthConditionsMapped,
        cartPatientsHealthConditions,
        isAnyCartPatientMissingHealthConditions,
        getHealthConditionsByPatientNum
    };
};

export default useHealthConditions;
