import { GATSBY_API_BASE_URL } from 'gatsby-env-variables';

import axiosClient from 'util/axiosClient';
import { camelToPascalKeys, pascalToCamelKeys, transformError } from 'util/service.helper';

import {
    InsuredRegistrationRequest,
    InsuredRegistrationResponse,
    RegistrationRequest,
    RegistrationResponse
} from './registration.types';

export default class RegistrationService {
    static CreateWebAccount() {
        return {
            post: async (payload: RegistrationRequest): Promise<RegistrationResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Registration/CreateWebAccount`,
                    camelToPascalKeys(payload)
                );

                const { messageStatus } = response.data;
                if (!messageStatus) {
                    throw transformError(response.data);
                }

                return pascalToCamelKeys(response.data);
            }
        };
    }

    static ContinueRegistration() {
        return {
            get: async (): Promise<RegistrationResponse> => {
                const response = await axiosClient.get(`${GATSBY_API_BASE_URL}/api/Registration/ContinueRegistration`);
                return pascalToCamelKeys(response.data);
            }
        };
    }

    static MinimalFinalizeRegistration() {
        return {
            post: async (payload: RegistrationRequest): Promise<RegistrationResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Registration/Minimal/Finalize`,
                    camelToPascalKeys(payload)
                );

                const { messageStatus } = response.data;
                if (!messageStatus) {
                    throw transformError(response.data);
                }

                return pascalToCamelKeys(response.data);
            }
        };
    }

    static InsuredSecondAttemptRegistration() {
        return {
            post: async (payload: InsuredRegistrationRequest): Promise<InsuredRegistrationResponse> => {
                const response = await axiosClient.post(
                    `${GATSBY_API_BASE_URL}/api/Registration/SendMailInsurance`,
                    camelToPascalKeys(payload)
                );

                const { messageStatus } = response.data;
                if (!messageStatus) {
                    throw transformError(response.data);
                }

                return pascalToCamelKeys(response.data);
            }
        };
    }
}
