import React, { KeyboardEvent } from 'react';

export interface WithOnKeyDownProps<P> {
    onClick?: ((e: P) => void) | (() => void);
    onKeyDown?: (e: KeyboardEvent<HTMLDivElement | HTMLOrSVGElement>, onClickInternal?: (props: P) => void) => void;
}

enum KEYBOARD_KEYS {
    ENTER = 'Enter',
    SPACE = ' '
}

const withOnKeyDown = <P extends object>(Component: React.ComponentType<P>) =>
    class WithOnKeyDown extends React.Component<P & WithOnKeyDownProps<P>> {
        handleOnKeyDown = (e: KeyboardEvent<HTMLDivElement>, onClickInternal?: (props: P) => void) => {
            const { onClick } = this.props;

            if ((e.key && e.key === KEYBOARD_KEYS.ENTER) || e.key === KEYBOARD_KEYS.SPACE) {
                if (onClick) onClick(this.props as P);
                if (onClickInternal) onClickInternal(this.props as P);
            }
        };
        render() {
            return <Component {...(this.props as P)} onKeyDown={this.handleOnKeyDown} />;
        }
    };

export default withOnKeyDown;
