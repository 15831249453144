import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

// UI Kit
import Button from 'ui-kit/button/button';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { HealthConditionPills } from 'components/health-conditions/health-conditions.component';
import {
    AllergiesModalContentV2,
    HealthConditionsModalContentV2
} from 'components/health-profile-v2/health-profile.component';
import { HealthProfileBubbleUpdateEvent } from 'components/health-profile/health-profile.props';
import ColumnSectionEditModeToggle, {
    ColumnSectionEditModeToggleRef
} from 'components/sidebar-column/column-section-toggle/column-section-toggle.component';

// Modal
import { HealthConditionsWarningModalContent } from 'pages/secure/profile/health-profile';

// States
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { medicalConditionsFetchHealthConditionsForPatientRoutine } from 'state/medical-conditions/medical-conditions.routines';

import { getPhoneNumber } from 'util/globalVariables';

import useCart from 'hooks/useCart';
import useHealthConditions from 'hooks/useHealthConditions';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './health-profile.style.scss';

// TODO: move this component outside of /medicine-cabinet-cart folder and delete unused duplicates
const HealthProfile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { currentFlow } = usePrescriptionFlow();
    const [hasFetchedConditions, setHasFetchedConditions] = useState<boolean>(false);
    const columnSectionRef = useRef<ColumnSectionEditModeToggleRef>(null);
    const { patientsInCart } = useCart();
    const { cartPatientsHealthConditions, isAnyCartPatientMissingHealthConditions } = useHealthConditions(currentFlow);

    const isToggleOpen = hasFetchedConditions && isAnyCartPatientMissingHealthConditions;

    const handleUpdateConditionsClick = useCallback(
        ({ action, update }: HealthProfileBubbleUpdateEvent) => {
            if (!update.ePostPatientNum) return;

            const healthConditions = cartPatientsHealthConditions.find(
                (patientHealthConditions) => patientHealthConditions.ePostPatientNum === update.ePostPatientNum
            );
            if (!healthConditions) return;

            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    className: 'modal-health-profile-warning',
                    headerContent: <BirdiModalHeaderDanger headerText={t('modals.healthConditions.title')} />,
                    bodyContent: <HealthConditionsWarningModalContent translation={t} />,
                    ctas: [
                        {
                            label: t('modals.healthConditions.submit'),
                            variant: 'primary',
                            onClick: () => {
                                dispatch(closeModal({}));
                                dispatch(
                                    action({
                                        ...update,
                                        currentFlow
                                    })
                                );
                            },
                            dataGALocation: 'HealthProfileWarning'
                        }
                    ]
                })
            );
        },
        [dispatch, currentFlow, cartPatientsHealthConditions, t]
    );

    const handleToggleHealthConditionsClick = useCallback(
        (epostPatientNumber?: string) => () => {
            if (!epostPatientNumber) return;
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: (
                        <HealthConditionsModalContentV2
                            title={t('modals.prescription.addHealthCondition.title')}
                            subTitle={t('modals.prescription.addAllergy.subTitle', {
                                phoneNumber: getPhoneNumber({})
                            })}
                            onUpdateHealthConditions={handleUpdateConditionsClick}
                            submitLabel={t('modals.prescription.addHealthCondition.submit')}
                            ePostPatientNum={epostPatientNumber}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, handleUpdateConditionsClick, t]
    );

    const handleToggleAllergiesClick = useCallback(
        (ePostPatientNum?: string) => () => {
            if (!ePostPatientNum) return;
            dispatch(
                openModal({
                    showClose: true,
                    className: 'prescription-modal',
                    bodyContent: (
                        <AllergiesModalContentV2
                            title={t('modals.prescription.addAllergy.title')}
                            subTitle={t('modals.prescription.addAllergy.subTitle', {
                                phoneNumber: getPhoneNumber({})
                            })}
                            onUpdateHealthConditions={handleUpdateConditionsClick}
                            freeformConditionsLabel={t('components.healthConditions.labels.freeformAllergiesLabel')}
                            submitLabel={t('modals.prescription.addAllergy.submit')}
                            ePostPatientNum={ePostPatientNum}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, handleUpdateConditionsClick, t]
    );

    // Fetch health conditions
    useEffect(() => {
        patientsInCart.forEach((user) => {
            const healthConditions = cartPatientsHealthConditions.find(
                (patientHealthConditions) => patientHealthConditions.ePostPatientNum === user.epostPatientNum
            );

            if (!healthConditions) {
                dispatch(
                    medicalConditionsFetchHealthConditionsForPatientRoutine.trigger({
                        ePostPatientNumber: user.epostPatientNum,
                        currentFlow
                    })
                );
            }
        });

        setHasFetchedConditions(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientsInCart]);

    const healthProfileContent = useMemo(() => {
        return cartPatientsHealthConditions.map((patient) => {
            if (!patient) return null;

            const {
                userHasNotSubmittedConditions,
                userHasNotSubmittedAllergies,
                existingConditions,
                existingAllergies,
                isLoadingAllergies,
                isLoadingMedicalConditions
            } = patient;

            return (
                <div key={patient.ePostPatientNum} className="health-profile">
                    {cartPatientsHealthConditions.length > 1 && (
                        <div className="health-profile__patient-name">
                            {patient.firstName} {patient.lastName}
                        </div>
                    )}
                    {!isLoadingAllergies &&
                    !isLoadingMedicalConditions &&
                    (userHasNotSubmittedConditions || userHasNotSubmittedAllergies) ? (
                        <ToastBox variant="warning" icon="warning">
                            <Trans i18nKey={'components.medicineCabinetCart.healthProfile.emptyHealthConditions'} />
                        </ToastBox>
                    ) : (
                        <div className="health-profile__intro">
                            {t('components.medicineCabinetCart.healthProfile.intro')}
                        </div>
                    )}
                    <div className="health-profile__conditions">
                        <h5 className="health-profile__conditions-title">
                            {t('components.medicineCabinetCart.healthProfile.healthConditions')}
                        </h5>

                        <div className="health-profile__conditions-actions">
                            <Button
                                plusIcon
                                IconType="secondary"
                                className="column-section__button btn-bold"
                                variant="text-blue-light"
                                label={t('components.medicineCabinetCart.healthProfile.addHealthConditions')}
                                type="button"
                                onClick={handleToggleHealthConditionsClick(patient?.ePostPatientNum)}
                            />
                        </div>

                        {isLoadingMedicalConditions && (
                            <div className="health-profile__conditions-details">
                                <SpinnerInline />
                            </div>
                        )}

                        {!userHasNotSubmittedConditions && (
                            <div className="health-profile__conditions-details">
                                <HealthConditionPills conditions={existingConditions} />
                            </div>
                        )}
                    </div>
                    <div className="health-profile__conditions">
                        <h5 className="health-profile__conditions-title">
                            {t('components.medicineCabinetCart.healthProfile.allergies')}
                        </h5>

                        <div className="health-profile__conditions-actions">
                            <Button
                                plusIcon
                                IconType="secondary"
                                className="column-section__button btn-bold"
                                variant="text-blue-light"
                                label={t('components.medicineCabinetCart.healthProfile.addAllergies')}
                                type="button"
                                onClick={handleToggleAllergiesClick(patient?.ePostPatientNum)}
                            />
                        </div>

                        {isLoadingAllergies && (
                            <div className="health-profile__conditions-details">
                                <SpinnerInline />
                            </div>
                        )}

                        {!userHasNotSubmittedAllergies && (
                            <div className="health-profile__conditions-details">
                                <HealthConditionPills conditions={existingAllergies} />
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }, [cartPatientsHealthConditions, t, handleToggleAllergiesClick, handleToggleHealthConditionsClick]);

    return (
        <ColumnSectionEditModeToggle
            chevronButton
            ref={columnSectionRef}
            bodyClassName="health-profile__content"
            className=""
            editModeContent={healthProfileContent}
            headerClassName="health-profile__header"
            title={t('components.medicineCabinetCart.healthProfile.title')}
            isToggleOpen={isToggleOpen}
        />
    );
};

export default HealthProfile;
