import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
// Bootstrap
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

// UI kit
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import CloseIcon from 'ui-kit/icons/close-icon/close-icon';
import Link from 'ui-kit/link/link';
import CoBrandingLogo from 'ui-kit/logo/co-branding-logo/co-branding-logo';
import Logo from 'ui-kit/logo/logo';
import PillLogo from 'ui-kit/logo/pill-logo/pill-logo';
import MobileToggle from 'ui-kit/mobile-toggle/mobile-toggle';
import 'ui-kit/utils/hidden-chatbot/hidden-chatbot.style.scss';
import 'ui-kit/utils/scroll-lock/scroll-lock.style.scss';

// Components
import {
    IntraPageMenuItemContentValue,
    IntraPageMenuItemProps
} from 'components/navigation/intra-page-menu/intra-page-menu.props';
import { MenuProps, NavPath } from 'components/navigation/navigation.props';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

import { CoBrandingFileQuery } from '../desktop-menu/desktop-menu.component';
import './mobile-menu.style.scss';

const MobileMenu = ({
    isScrolled,
    userName,
    profileMenu,
    isLoggedIn,
    cartItemsTotal,
    rightMenuPaths = [],
    leftMenuPaths = []
}: MenuProps) => {
    const { t } = useTranslation();
    const globalLink = useGlobalLink();
    const { trackFormAbandonment } = globalLink;
    const [cartTotal, setCartTotal] = useState(cartItemsTotal);
    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('');
    const [rightNavPaths, setRightNavPaths] = useState<NavPath[]>([]);
    const [leftNavPaths, setLeftNavPaths] = useState<NavPath[]>([]);
    const getCoBrandingDataQuery = CoBrandingFileQuery();

    const onSelectTab = (id: string) => {
        setActiveTab(id);
    };

    const findJoinCta = () => {
        const regex = /join\s+birdi/;
        return rightMenuPaths.find((f) => regex.test(f.label.toLowerCase()));
    };

    const joinCta = findJoinCta();

    const openMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        document.body.classList.toggle('hidden-chatbot');
        setIsMobileMenuVisible(true);
    };

    const closeMobileMenuVisibility = () => {
        document.body.classList.remove('scroll-lock');
        document.body.classList.remove('hidden-chatbot');
        setIsMobileMenuVisible(false);
    };

    const onNavigateMenuMobileVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        document.body.classList.remove('hidden-chatbot');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    const getMobileNavPaths = useCallback((paths: NavPath[]) => {
        const isMobilePath = (path: NavPath) => path.isMobile || path.isMobile === null || path.isMobile === undefined;
        return paths
            .filter((path) => isMobilePath(path))
            .map((path) => {
                const navPath = { ...path };
                if (navPath.children) {
                    navPath.children = [...navPath.children].filter((p) => isMobilePath(p));
                }
                return navPath;
            });
    }, []);

    useEffect(() => {
        setRightNavPaths(getMobileNavPaths(rightMenuPaths));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rightMenuPaths, isLoggedIn]);

    useEffect(() => {
        setLeftNavPaths(getMobileNavPaths(leftMenuPaths));
        if (leftMenuPaths.length > 0 && leftMenuPaths[0].id) {
            onSelectTab(leftMenuPaths[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [leftMenuPaths, isLoggedIn]);

    useEffect(() => {
        setCartTotal(cartItemsTotal);
    }, [cartItemsTotal]);
    return (
        <Container
            fluid
            className={`mobile-menu d-lg-none justify-content-center ${
                isMobileMenuVisible ? 'menu-open' : 'menu-closed'
            }`}
        >
            <Row className="w-100">
                <Col className="d-flex justify-content-start align-items-center">
                    <div className="mobile-menu-header">
                        {rightNavPaths && rightNavPaths?.length > 0 && (
                            <MobileToggle
                                variant="primary"
                                onClick={() => openMobileMenuVisibility()}
                                style={{ height: '2.625rem', width: '2.625rem' }}
                            />
                        )}
                        <div className="mobile-menu-header--branding-logos">
                            {getCoBrandingDataQuery && (
                                <CoBrandingLogo
                                    nodes={getCoBrandingDataQuery?.nodes}
                                    linkClassName="mobile-menu-header--coBranding-logo"
                                />
                            )}

                            <Link
                                onClick={trackFormAbandonment}
                                to={isLoggedIn ? '/secure/medicine-cabinet' : '/'}
                                label={
                                    <div className="mobile-menu-header--logo">
                                        <div className="co-brand-logo d-flex"></div>
                                        {isScrolled ? <PillLogo variant="primary" /> : <Logo />}
                                    </div>
                                }
                                variant="site"
                                dataGALocation="HeaderLogo"
                                ariaLabel="Birdi Homepage"
                            />
                        </div>
                    </div>
                    {!isLoggedIn && joinCta && (
                        <div className="mobile-menu-header-cta">
                            <Link
                                className={Boolean(joinCta?.isButton) === true ? 'w-100' : ''}
                                to={joinCta?.link?.url || ''}
                                label={joinCta?.label || ''}
                                variant={Boolean(joinCta?.isButton) === true ? 'nav-button' : 'nav'}
                                dataGALocation="MobileHeaderNav"
                            />
                        </div>
                    )}
                </Col>
            </Row>
            <div className={`menu ${profileMenu ? `isAuthenticated` : `isUnauthenticated`}`}>
                <div className="menu-header">
                    <div className="menu-header--content">
                        <div className="menu-header--close">
                            <CloseIcon
                                onClick={() => closeMobileMenuVisibility()}
                                style={{ height: '2.125rem', width: '2.125rem' }}
                                ariaLabel={t('menus.icons.closeMobileMenu')}
                            />
                        </div>
                        <div className="menu-header--logo">
                            <div className="co-brand-logo d-flex"></div>
                            <Logo variant="light" />
                        </div>
                    </div>
                    {!isLoggedIn && joinCta && (
                        <div className="menu-header--cta">
                            <Link
                                className={Boolean(joinCta?.isButton) === true ? 'w-100' : ''}
                                onClick={() => onNavigateMenuMobileVisibility()}
                                to={joinCta?.link?.url || ''}
                                label={joinCta?.label || ''}
                                variant={Boolean(joinCta?.isButton) === true ? 'nav-button' : 'nav'}
                                dataGALocation="MobileHeaderNav"
                            />
                        </div>
                    )}
                </div>

                <div className={`menu-links ${profileMenu ? `isAuthenticated` : `isUnauthenticated`}`}>
                    {leftNavPaths && leftNavPaths.length > 0 && !isLoggedIn && (
                        <div className="w-100">
                            <ul className="menu-tabs">
                                {leftNavPaths.map(({ id, label }) => (
                                    <li
                                        key={`mobile-menu-tabs-${id}`}
                                        className={`menu-tabs-item ${activeTab === id && 'menu-tabs--active'}`}
                                    >
                                        <Button
                                            type="button"
                                            onClick={() => onSelectTab(id)}
                                            label={label}
                                            dataGALocation="MobileHeaderTab"
                                        />
                                    </li>
                                ))}
                            </ul>
                            <div className="menu-content">
                                {leftNavPaths.map(({ id, children }) => (
                                    <div
                                        key={`mobile-menu-tab-content-${id}`}
                                        className={`menu-tabs-content-item ${
                                            activeTab === id ? 'menu-tabs-content-item--active' : ''
                                        }`}
                                    >
                                        {children?.map(({ id, label, isButton, link }) => (
                                            <React.Fragment key={`mobile-menu-tab-content-child-${id}`}>
                                                {link && link.url && (
                                                    <Link
                                                        className={Boolean(isButton) === true ? 'w-100' : ''}
                                                        to={link?.url}
                                                        label={label}
                                                        variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                                        dataGALocation="MobileHeaderNav"
                                                        onClick={() => {
                                                            trackFormAbandonment();
                                                            onNavigateMenuMobileVisibility();
                                                        }}
                                                    />
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className={`menu-content menu-content-secondary`}>
                        {rightNavPaths.map(({ link, label, isButton }: NavPath) => (
                            <React.Fragment key={`site-mobile-nav-${link?.url}-${label}`}>
                                {link && (
                                    <Link
                                        className={Boolean(isButton) === true ? 'w-100' : ''}
                                        onClick={() => onNavigateMenuMobileVisibility()}
                                        to={link?.url}
                                        label={label}
                                        variant={Boolean(isButton) === true ? 'nav-button' : 'nav'}
                                        dataGALocation="MobileHeaderNav"
                                    />
                                )}
                            </React.Fragment>
                        ))}

                        {profileMenu && (
                            <>
                                <Link
                                    onClick={() => onNavigateMenuMobileVisibility()}
                                    to={'/secure/cart/review/'}
                                    label={
                                        <>
                                            <CartIcon
                                                className={'position-absolute border-cathams-blue'}
                                                itemCount={cartTotal}
                                                style={{ left: '0' }}
                                            />
                                            <span>{t('menus.cart.title')}</span>
                                        </>
                                    }
                                    variant={'nav'}
                                    dataGALocation="HeaderNav"
                                    className="btn btn-outline-smoke text-center w-100"
                                />

                                <div className="menu-links-profile">
                                    <div className="user-name">{userName}</div>
                                    {profileMenu.map(
                                        ({
                                            key,
                                            value,
                                            label
                                        }: IntraPageMenuItemProps<IntraPageMenuItemContentValue>) =>
                                            value && (
                                                <Link
                                                    onClick={() => onNavigateMenuMobileVisibility()}
                                                    key={`site-mobile-nav-profile-${key}`}
                                                    to={value.toString()}
                                                    label={label}
                                                    variant="nav"
                                                    dataGALocation="MobileHeaderNav"
                                                />
                                            )
                                    )}

                                    <Link
                                        onClick={trackFormAbandonment}
                                        to="/sign-out"
                                        label={t('menus.intraPage.profile.signOut')}
                                        variant="nav"
                                        dataGALocation="MobileHeaderNav"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default MobileMenu;
