import { FormikHelpers } from 'formik';
import { ENABLE_BIRDI_SELECT } from 'gatsby-env-variables';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PriceEligibleRx, setPriceEligibleRxs } from 'state/drug/drug.reducers';
import { drugDescriptionRoutine, drugDiscountPriceRoutine } from 'state/drug/drug.routines';
import { drugPriceEligibleRxsSelector, drugsWithDiscountSelector } from 'state/drug/drug.selectors';
import { DrugDescriptionObjectPayload } from 'state/drug/drug.services';

import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { PrescriptionCardProps, PrescriptionObjectPayload, PrescriptionTypes, RxDetails } from 'types/prescription';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';
import { PRICING_API_LOCATION, PRICING_UNAUTH_AREA } from 'enums/pricing';

import { findDrugInList, getDrugPricingPlanAlias, getDrugPricingZipCode } from 'util/drug';
import { isRxListEqual } from 'util/prescription';
import { areRxsInList, getRxDrugCode } from 'util/prescription';

import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

interface getPriceParameters {
    values: Partial<PrescriptionTypes>;
    actions?: FormikHelpers<Partial<PrescriptionTypes>>;
    handlePriceSuccess?: (...args: any[]) => void;
    handleDescriptionSuccess?: (...args: any[]) => void;
    handleIsBirdiSelect?: (...args: any[]) => void;
    handleIsBrd02?: (...args: any[]) => void;
}

const useBirdiPrice = () => {
    const dispatch = useDispatch();
    const drugDiscountPrices = useSelector(drugsWithDiscountSelector);
    const {
        currentFlow,
        familyPricingData,
        cartZipCode,
        cartItems,
        mainUserZipCode,
        isDataLoaded,
        mainUserHasMembership
    } = usePrescriptionFlow();
    const priceEligiblePrescriptions = useSelector(drugPriceEligibleRxsSelector);

    const setPriceEligiblePrescriptions = useCallback(
        (
            prescriptions: PrescriptionObjectPayload[],
            location: PRICING_API_LOCATION = PRICING_API_LOCATION.MEDICINE_CABINET
        ) => {
            const mappedStoredPrescriptions: PrescriptionObjectPayload[] = priceEligiblePrescriptions.map(
                (rx: PriceEligibleRx) => rx.prescription
            );
            if (
                prescriptions.length > 0 &&
                !isRxListEqual(prescriptions, mappedStoredPrescriptions) &&
                !areRxsInList(prescriptions, mappedStoredPrescriptions)
            ) {
                dispatch(
                    setPriceEligibleRxs(
                        prescriptions.map((prescription) => ({
                            prescription,
                            location
                        }))
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [priceEligiblePrescriptions]
    );

    const getPatientPricingData = useCallback(
        (epostPatientNum: string) =>
            familyPricingData.find((dependent) => dependent.epostPatientNum === epostPatientNum),
        [familyPricingData]
    );

    const getPricingZipCode = useCallback(
        (epostPatientNum: string, cartZipCode?: string) =>
            getDrugPricingZipCode(getPatientPricingData(epostPatientNum), mainUserZipCode, cartItems, cartZipCode),
        [getPatientPricingData, cartItems, mainUserZipCode]
    );

    const getPricingPlanAlias = useCallback(
        (epostPatientNum: string) => getDrugPricingPlanAlias(getPatientPricingData(epostPatientNum)),
        [getPatientPricingData]
    );

    // Find if the price has been already gotten for a certain Drug
    const findPrescriptionBirdiPrice = useCallback(
        (prescription: Partial<RxDetails> | PrescriptionObjectPayload | undefined, cartZipCode?: string) => {
            if (!prescription) return;
            const drugCode = getRxDrugCode(prescription as RxDetails);
            if (!drugCode || !prescription.epostPatientNum) return;
            const drugLookupData: DrugWithDiscountPrice = {
                drugCode: prescription.dispensedProductNumber,
                quantity: prescription.fillQuantity,
                planAlias: getPricingPlanAlias(prescription.epostPatientNum),
                zipCode: getPricingZipCode(prescription.epostPatientNum, cartZipCode),
                rxNumber: prescription.rxNumber
            };
            return findDrugInList(drugLookupData, drugDiscountPrices);
        },
        [getPricingZipCode, getPricingPlanAlias, drugDiscountPrices]
    );

    const getPrescriptionPrice = useCallback(
        ({
            values,
            actions,
            handlePriceSuccess,
            handleDescriptionSuccess,
            handleIsBirdiSelect,
            handleIsBrd02
        }: getPriceParameters) => {
            // Convert values into a RxDetails object, since that is what it expects
            const prescription: Partial<RxDetails> = {
                dispensedProductNumber: values.ndc,
                fillQuantity: values.qty,
                fillDaysSupply: '',
                epostPatientNum: values.dependentEpostPatientNum
            };

            if (!prescription.dispensedProductNumber) return;
            const priceFound: DrugWithDiscountPrice | undefined = findPrescriptionBirdiPrice(prescription);

            const onSuccess = (drugPriceResponse: DrugWithDiscountPrice) => {
                // Update the submitting state of the prescription info form
                // in order to reset the loading state of the submit button.
                actions && actions.setSubmitting(false);

                const isBirdiSelect = ENABLE_BIRDI_SELECT ? drugPriceResponse.isBirdiSelect : false;

                // need to provide successHandler to set showPrice, SelectedDrug and scrallTo
                handlePriceSuccess && handlePriceSuccess(drugPriceResponse.price);
                handleIsBirdiSelect && handleIsBirdiSelect(isBirdiSelect);
                handleIsBrd02 && handleIsBrd02(mainUserHasMembership);
            };

            if (!priceFound) {
                // Fetch the drug price.
                dispatch(
                    drugDiscountPriceRoutine.trigger({
                        prescriptions: [prescription],
                        onSuccess,
                        onFailure: () => {
                            // On failure, we still scroll down, but we show an error message.
                            actions && actions.setSubmitting(false);
                            handlePriceSuccess && handlePriceSuccess();
                        }
                    })
                );
            } else {
                onSuccess(priceFound);
            }

            // Fetch the drug description.
            if (values.gpi) {
                dispatch(
                    drugDescriptionRoutine.trigger({
                        gpi: values.gpi,
                        onSuccess: (response: DrugDescriptionObjectPayload) => {
                            handleDescriptionSuccess && handleDescriptionSuccess(response);
                        },
                        onFailure: () => {
                            handleDescriptionSuccess && handleDescriptionSuccess();
                        }
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [findPrescriptionBirdiPrice, cartZipCode, mainUserHasMembership]
    );

    // Function that triggers the APIs to get the prices of a list of Rxs
    const getPrescriptionListPrices = useCallback(
        ({
            eligiblePrescriptions,
            onSuccess
        }: {
            eligiblePrescriptions: PriceEligibleRx[];
            onSuccess?: (...args: any[]) => void;
        }) => {
            const unauthAreaValue: Record<RX_AVAILABLE_FLOWS, PRICING_UNAUTH_AREA | undefined> = {
                [RX_AVAILABLE_FLOWS.EASY_REFILL]: PRICING_UNAUTH_AREA.EASY_REFILL,
                [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: undefined,
                [RX_AVAILABLE_FLOWS.AUTO_REFILL]: PRICING_UNAUTH_AREA.AUTO_REFILL
            };

            const filteredPrescriptions = eligiblePrescriptions.filter(
                (rx) => !findPrescriptionBirdiPrice(rx.prescription, cartZipCode)
            );

            const location = filteredPrescriptions?.[0]?.location;

            // Ensure that family pricing data has been loaded and there are rxs to load
            if (filteredPrescriptions.length > 0 && familyPricingData.length > 0) {
                dispatch(
                    drugDiscountPriceRoutine.trigger({
                        prescriptions: filteredPrescriptions.map((rx) => rx.prescription),
                        location: location ?? PRICING_API_LOCATION.MEDICINE_CABINET,
                        unAuthArea: unauthAreaValue[currentFlow],
                        onSuccess
                    })
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [drugDiscountPrices, familyPricingData, cartZipCode, findPrescriptionBirdiPrice]
    );

    // Function that will help us to debug the price that will be rendered for an Rx cart
    const debugRxPrice = useCallback(
        (prescription?: PrescriptionCardProps) => {
            if (!prescription) return;

            console.group(`___Debugging Rx price___`);
            console.log('History of rx prices api calls:', drugDiscountPrices);
            console.log(
                `Cart zip code (cartZipCode): ${
                    cartZipCode && cartItems && cartItems?.length > 0 ? cartZipCode : 'No zip code, the cart is empty'
                }`
            );

            console.group(`Drug data`);
            console.log(`Prescription number (rxNumber): ${prescription.fullRxItem.rxNumber}`);
            console.log(`Quantity (fillQuantity): ${prescription.birdiPrice.drugPricingResponse?.quantity}`);
            console.log(`Drug code (drugCode): ${prescription.birdiPrice.drugPricingResponse?.drugCode}`);
            console.log(`Product name (dispensedProductName): ${prescription.fullRxItem.dispensedProductName}`);
            console.log(`isBirdiSelect: ${prescription.fullRxItem.isBirdiSelect}`);
            console.log(`ENABLE_BIRDI_SELECT: ${ENABLE_BIRDI_SELECT}`);

            console.groupEnd();

            console.group(`Patient data`);
            console.log(`Patient's epostPatientNum: ${prescription.fullRxItem.epostPatientNum}`);
            console.log(`Patient's plan alias: ${prescription.birdiPrice.pricingData?.planAlias}`);
            console.log(`Plan type: ${prescription.birdiPrice.pricingData?.planType}`);
            console.log(`Is on demand plan: ${prescription.birdiPrice.pricingData?.isOnDemandPlan}`);
            console.log(`Patient's zip code: ${prescription.birdiPrice.pricingData?.zipCode}`);
            console.groupEnd();

            console.group(`Pricing data`);
            console.log(`Plan alias: ${getPricingPlanAlias(prescription.fullRxItem.epostPatientNum)}`);
            console.log('Zip code: ', getPricingZipCode(prescription.fullRxItem.epostPatientNum, cartZipCode));
            console.log(`Price found in History:`, findPrescriptionBirdiPrice(prescription.fullRxItem, cartZipCode));
            console.groupEnd();
            console.groupEnd();
        },
        [drugDiscountPrices, cartItems, cartZipCode, getPricingPlanAlias, getPricingZipCode, findPrescriptionBirdiPrice]
    );

    // Function that will help us to debug the price that will be rendered for an Rx cart
    const debugTransferRxPrice = useCallback(
        (prescription: PrescriptionTypes | null | undefined) => {
            if (prescription && prescription.dependentEpostPatientNum) {
                const patientPricingData = getPatientPricingData(prescription.dependentEpostPatientNum);

                // Convert values into a RxDetails object, since that is what it expects
                const transferRx: Partial<RxDetails> = {
                    dispensedProductNumber: prescription.ndc,
                    fillQuantity: prescription.qty,
                    fillDaysSupply: '',
                    epostPatientNum: prescription.dependentEpostPatientNum
                };

                if (!transferRx.epostPatientNum || !transferRx.dispensedProductNumber) return;

                const priceFound: DrugWithDiscountPrice | undefined = findPrescriptionBirdiPrice(transferRx);

                console.group(`___Debugging Transfer Rx price___`);
                console.log('History of rx prices api calls:', drugDiscountPrices);

                console.group(`Drug data`);
                console.log(`Quantity (qty): ${prescription.qty}`);
                console.log(`Drug code (ndc): ${prescription.ndc}`);
                console.log(`Product name (drugDisplayName): ${prescription.drugDisplayName}`);

                console.groupEnd();

                console.group(`Patient data`);
                console.log(`Patient's epostPatientNum: ${patientPricingData?.epostPatientNum}`);
                console.log(`Patient's plan alias: ${patientPricingData?.planAlias}`);
                console.log(`Plan type: ${patientPricingData?.planType}`);
                console.log(`Is on demand plan: ${patientPricingData?.isOnDemandPlan}`);
                console.log(`Patient's zip code: ${patientPricingData?.zipCode}`);
                console.groupEnd();

                console.group(`Pricing data`);
                console.log(`Plan alias: ${getPricingPlanAlias(prescription.dependentEpostPatientNum)}`);
                console.log('Zip code: ', getPricingZipCode(prescription.dependentEpostPatientNum, cartZipCode));
                console.log(`Price found in History:`, priceFound);
                console.groupEnd();
                console.groupEnd();
            }
        },
        [
            drugDiscountPrices,
            findPrescriptionBirdiPrice,
            getPricingPlanAlias,
            getPricingZipCode,
            getPatientPricingData,
            cartZipCode
        ]
    );

    // When we receive price eligible Rx and configuring a cached value
    // to avoid calling pricing apis many times.
    // The zipcode must update when zipcode changes.
    useEffect(() => {
        if (priceEligiblePrescriptions.length > 0 && isDataLoaded) {
            getPrescriptionListPrices({ eligiblePrescriptions: priceEligiblePrescriptions });
        }
    }, [priceEligiblePrescriptions, isDataLoaded, cartZipCode, mainUserZipCode, getPrescriptionListPrices]);

    return {
        getPrescriptionPrice,
        getPrescriptionListPrices,
        setPriceEligiblePrescriptions,
        debugRxPrice,
        debugTransferRxPrice,
        getPatientPricingData,
        findPrescriptionBirdiPrice
    };
};

export default useBirdiPrice;
