import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { TFunction } from 'gatsby-plugin-react-i18next';

import { DrugDiscountPriceResponse, DrugLookupObjectPayload } from 'state/drug/drug.services';

import { formatPrice } from 'schema/price.schema';

import { DrugWithDiscountPrice } from 'types/drug-pricing';
import { DependentsPricingData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { PrescriptionObjectPayload, RxDetails } from 'types/prescription';

import { BIRDI_PLANS } from 'enums/plans';

import { getRxDrugCode } from 'util/prescription';

import { doesPlanAllowsPricing } from './pricing';

export function formatDrugName(t: TFunction<'translation'>, drug: DrugLookupObjectPayload): string {
    if (drug.brandDrugName && drug.brandDrugName !== drug.onSaleDrugName && drug.brandNameCode === 'G') {
        return t('prescriptionInfoForm.drugLookup.hasGeneric', {
            drugName: drug.onSaleDrugName,
            brandName: drug.brandDrugName
        });
    } else {
        return drug.onSaleDrugName ? drug.onSaleDrugName : drug.drugName;
    }
}

// Function that returns a drug found in the history
export const findDrugInList = (
    drug: DrugWithDiscountPrice,
    drugHistory: DrugWithDiscountPrice[]
): DrugWithDiscountPrice | undefined =>
    drugHistory.find(
        (item: DrugWithDiscountPrice) =>
            item.drugCode === drug.drugCode &&
            item.planAlias === drug.planAlias &&
            drug.quantity === item.quantity &&
            item.zipCode === drug.zipCode
    );

export const getDrugPricingPlanAlias = (pricingDetails?: DependentsPricingData) => {
    let clientAcCode;

    if (pricingDetails?.planAlias === BIRDI_PLANS.BRD_02 && pricingDetails.isOnDemandPlan) {
        clientAcCode = BIRDI_PLANS.BRD_01;
    } else {
        clientAcCode = pricingDetails?.planAlias;
    }

    return clientAcCode;
};

export const getDrugPricingZipCode = (
    pricingDetails?: DependentsPricingData,
    mainUserZipCode?: string | null,
    cartItems?: RefillRxs[],
    cartZipCode?: string | null,
    isTransferFlow = false
) => {
    // Set zipcode value
    let zipCode = pricingDetails?.zipCode;

    if (!pricingDetails?.zipCode && !!mainUserZipCode) {
        zipCode = mainUserZipCode;
    }

    // If the user is not transfer flow, take
    // the cart zipcode first.
    if (!isTransferFlow && cartItems && cartItems?.length > 0 && !!cartZipCode) {
        zipCode = cartZipCode;
    }

    return zipCode;
};

export const findBirdiPrice = ({
    prescription,
    familyPricingDetails,
    drugDiscountPrices,
    selectedMember,
    mainUserZipCode,
    cartZipCode,
    cartItems,
    isCart = false
}: {
    prescription?: RxDetails | PrescriptionObjectPayload;
    familyPricingDetails: DependentsPricingData[];
    drugDiscountPrices: DrugWithDiscountPrice[];
    selectedMember?: string | null;
    mainUserZipCode?: string | null;
    cartZipCode?: string | null;
    cartItems?: RefillRxs[];
    isCart?: boolean;
}): DrugWithDiscountPrice | undefined => {
    // Ensure we always have prescription details data loaded

    if (!prescription) return;
    // Find drug data
    const drugLookupData = getDrugLookupData({
        prescription,
        familyPricingDetails,
        selectedMember,
        mainUserZipCode,
        cartZipCode,
        cartItems,
        isCart
    });

    // Get birdi price from /medimpact-pricing API when loaded
    return findDrugInList(drugLookupData, drugDiscountPrices);
};

export const getDrugLookupData = ({
    prescription,
    familyPricingDetails,
    selectedMember,
    mainUserZipCode,
    cartZipCode,
    cartItems,
    isCart = false
}: {
    prescription: RxDetails | PrescriptionObjectPayload;
    familyPricingDetails: DependentsPricingData[];
    selectedMember?: string | null;
    mainUserZipCode?: string | null;
    cartZipCode?: string | null;
    cartItems?: RefillRxs[];
    isCart?: boolean;
}): DrugWithDiscountPrice => {
    // Get the epostPatientNum number
    const isTransferFlow = !!selectedMember;
    const epostPatientNum = isTransferFlow ? selectedMember : prescription.epostPatientNum;

    // We look for the pricing details of the patient
    const pricingDetails = familyPricingDetails.find((dependent) => epostPatientNum === dependent.epostPatientNum);
    let planAlias = getDrugPricingPlanAlias(pricingDetails);

    if (isCart && !doesPlanAllowsPricing(planAlias) && !!ALLOW_INSURED_BIRDI_PRICE) {
        planAlias = BIRDI_PLANS.BRD_01;
    }
    // Return object that will be used for Drug lookup
    return {
        drugCode: getRxDrugCode(prescription),
        quantity: prescription.fillQuantity,
        daysSupply: prescription.fillDaysSupply,
        planAlias,
        gpi: prescription.genericProductIndicator,
        rxNumber: prescription.rxNumber,
        zipCode: getDrugPricingZipCode(pricingDetails, mainUserZipCode, cartItems, cartZipCode, isTransferFlow)
    };
};

// Function that formats discount price payload
export const processDiscountPrice = (
    response: DrugDiscountPriceResponse,
    lookUpData: DrugWithDiscountPrice
): DrugWithDiscountPrice => {
    // Initialize values
    let price = 'NA';
    let priceValue = 0;
    let awpPrice = 'NA';
    let awpPriceValue = 0;

    // Ensure you get a valid price
    if (response.prescriptionResult[0].status !== 'DENIED') {
        // Get Price value
        price = response.prescriptionResult[0]?.pricingInformation?.memberCost?.patientPayAmount
            ? response.prescriptionResult[0].pricingInformation.memberCost.patientPayAmount
            : price; // '8.25';

        if (!isNaN(Number(price))) {
            priceValue = parseFloat(price);
            price = formatPrice(price);
        }
        // Get awp price
        awpPrice = response.prescriptionResult[0]?.pricingInformation?.awpPrice
            ? String(response.prescriptionResult[0].pricingInformation.awpPrice)
            : awpPrice; // '400.00';

        if (!isNaN(Number(awpPrice))) {
            awpPriceValue = parseFloat(awpPrice);
        }
    }

    return {
        price,
        priceValue,
        awpPrice,
        awpPriceValue,
        rxNumber: lookUpData.rxNumber as string,
        planAlias: response.member.memberIdent.clientAccountCode,
        isBirdiSelect: response.isBirdiSelect,
        zipCode: lookUpData.zipCode,
        drugCode: lookUpData.drugCode,
        quantity: lookUpData.quantity,
        daysSupply: lookUpData.daysSupply,
        gpi: lookUpData.gpi
    };
};
