// General
import { ENABLE_AUTO_REFILL, ENABLE_BIRDI_SELECT } from 'gatsby-env-variables';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

// Ui-kit
import Button from 'ui-kit/button/button';
import { ButtonProps } from 'ui-kit/button/button.props';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';
import SpinnerInline from 'ui-kit/spinner-inline/spinner';
import ToastBox from 'ui-kit/toast-box/toast-box';

// State
import { medicineCabinetIsBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

// Schemas
import { formatPrice } from 'schema/price.schema';

import { CartOrderRx } from 'types/cart';

// Utils
import { noop } from 'util/function';
import { getPhoneNumber } from 'util/globalVariables';

import AutoRefillBadge from '../auto-refill-badge/auto-refill-badge';
// Styles
import './prescription-item.style.scss';

// Main component interface
export interface PrescriptionItemProps {
    isLoading?: boolean;
    isCaregiver?: boolean;
    patientName?: string;
    onRemovePrescriptionClick?: ButtonProps['onClick'];
    prescription: CartOrderRx;
    isPlanAutoRefillEligible?: boolean;
}

interface DiscountSavingsProps {
    hasDiscount: boolean;
    prescription: CartOrderRx;
}

const DiscountSavings: React.FC<DiscountSavingsProps> = ({ hasDiscount, prescription }) => {
    const { t } = useTranslation();

    return (
        <>
            {hasDiscount && !!prescription && (
                <>
                    {' '}
                    {t('components.medicineCabinetCart.prescriptionInfo.save', {
                        save: formatPrice(prescription.cartPrice?.discount)
                    })}
                </>
            )}
        </>
    );
};

// Main component
const PrescriptionItem: React.FC<PrescriptionItemProps> = (props) => {
    // General
    const {
        onRemovePrescriptionClick = noop,
        prescription,
        patientName,
        isPlanAutoRefillEligible = false,
        isCaregiver
    } = props;

    // Hooks
    const { t } = useTranslation();

    // States
    const [isBusy, setIsBusy] = useState<boolean>(false);

    // Selectors
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);

    // Memoized Data
    const rxDetails = useMemo(
        () => prescription.rxDetails.prescriptionDetail,
        [prescription.rxDetails.prescriptionDetail]
    );
    const cartPrice = useMemo(() => prescription.cartPrice, [prescription.cartPrice]);
    const hasDiscount = useMemo(
        () => !!cartPrice?.discount && cartPrice?.discount > 0 && cartPrice.showStrikeThruPricing,
        [cartPrice]
    );
    const showBirdiPriceIcon = useMemo(() => cartPrice?.hasKnownPrice && cartPrice.showBirdiCashPrice, [cartPrice]);

    const isBirdiSelect = useMemo(
        () => !!ENABLE_BIRDI_SELECT && prescription.isBirdiSelect,
        [prescription.isBirdiSelect]
    );

    const showStar = useMemo(
        () =>
            (!prescription.isPatientMembership || !cartPrice?.hasAdjudicatedPrice) &&
            !cartPrice?.hasKnownPrice &&
            rxDetails?.dispensedProductName,
        [prescription.isPatientMembership, cartPrice, rxDetails]
    );

    const showAutoRefillFlag = useMemo(
        () => !!ENABLE_AUTO_REFILL && !!prescription.autoRefillEnabled && !!isPlanAutoRefillEligible,
        [prescription, isPlanAutoRefillEligible]
    );

    useEffect(() => {
        setIsBusy(prescriptionsIsBusySelector);
    }, [prescriptionsIsBusySelector]);

    return (
        <div className="pb-3">
            {prescription && (
                <>
                    <section className="prescription-item-wrapper">
                        {isCaregiver ? (
                            <h3 className="prescription-item-patient-name font-weight-bolder">{patientName}</h3>
                        ) : (
                            <></>
                        )}

                        {/* First Section */}
                        <div className="prescription-item">
                            {/* Drug price */}
                            <div className="prescription-item-info text-ebony-clay">
                                <div className="prescription-item-info__name">
                                    {rxDetails?.dispensedProductName}
                                    {/* Plan Badge */}
                                    {isBirdiSelect && <PlanBadge variant="birdi-select-blue" />}
                                </div>

                                {/* When the price is not adjudicated */}

                                {showStar ? <sup>*</sup> : ''}

                                {/* Prescription number */}

                                <div className="prescription-item-rx mt-2">
                                    {t('components.medicineCabinetCart.prescriptionInfo.rx', {
                                        number: prescription.rxDetails.rxNumber
                                    })}
                                </div>

                                {/* Qunatities */}
                                <div className="prescription-item-quantities d-flex align-items-center flex-wrap">
                                    {rxDetails?.fillQuantity && (
                                        <div>
                                            {t('pages.cart.quantity', {
                                                fillQuantity: rxDetails.fillQuantity
                                            })}
                                        </div>
                                    )}

                                    {(rxDetails?.dispensedProductStrength ||
                                        rxDetails?.dispensedProductUnitOfMeasure) && (
                                        <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                    )}

                                    {(rxDetails?.dispensedProductStrength ||
                                        rxDetails?.dispensedProductUnitOfMeasure) && (
                                        <div>
                                            {rxDetails?.dispensedProductStrength}
                                            {rxDetails?.dispensedProductUnitOfMeasure}
                                        </div>
                                    )}

                                    {rxDetails?.dispensedProductDosageForm && (
                                        <span className="divider mx-1 text-slate prescription-item-rx">|</span>
                                    )}

                                    {rxDetails?.dispensedProductDosageForm && (
                                        <div className="prescription-item-dosage">
                                            {rxDetails.dispensedProductDosageForm}
                                        </div>
                                    )}
                                </div>

                                {showAutoRefillFlag ? (
                                    <div className="mt-2">
                                        <AutoRefillBadge
                                            text={t('components.medicineCabinetCart.prescriptionInfo.autoRefill')}
                                        />
                                    </div>
                                ) : null}
                            </div>

                            <div className="left-n1 ml-lg-auto text-right prescription-item-price">
                                {/* Remove Button and price before loading cart with all information */}
                                {isBusy ? (
                                    <SpinnerInline />
                                ) : (
                                    <>
                                        {!!cartPrice && cartPrice?.hasKnownPrice ? (
                                            <>
                                                {hasDiscount && (
                                                    <div className="prescription-item-price-line">
                                                        {formatPrice(cartPrice.awpPrice)}
                                                    </div>
                                                )}
                                                <div className="prescription-item-price-total">
                                                    {formatPrice(cartPrice.finalPrice)}
                                                </div>
                                                {/* Est.Copay - just for insurance patients */}
                                                {prescription.isPrimaryPlanInsurance &&
                                                    !cartPrice.showBirdiCashPrice && (
                                                        <div className="prescription-item-price-copay">
                                                            {t('components.medicineCabinetCart.prescriptionInfo.copay')}
                                                        </div>
                                                    )}
                                            </>
                                        ) : (
                                            <div className="prescription-item-no-price">
                                                {t('components.medicineCabinetCart.prescriptionInfo.noPrice')}
                                            </div>
                                        )}
                                        {showBirdiPriceIcon && (
                                            <div>
                                                {/* Birdi price */}
                                                <div className="d-flex justify-content-end prescription-item-birdi-price">
                                                    <BirdiPriceIcon />
                                                    <div>
                                                        {t(
                                                            'components.medicineCabinetCart.prescriptionInfo.birdiPrice'
                                                        )}
                                                    </div>
                                                </div>

                                                {/* Discount price - Just for insurance patients */}
                                                {hasDiscount && (
                                                    <div className="d-flex text-right mt-1 prescription-item-saving justify-content-end">
                                                        <DiscountSavings
                                                            hasDiscount={hasDiscount}
                                                            prescription={prescription}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <Button
                                            type="button"
                                            variant="text"
                                            className="px-0 pt-0 no-min-width text-cerulean left-n1 ml-lg-auto mt-2 font-weight-bold text-right"
                                            label={t('shipping.removeAddress')}
                                            onClick={onRemovePrescriptionClick}
                                            dataGAFormName="addMorePrescriptions"
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </section>

                    {!!prescription.disclaimerTranslationKey && (
                        <div className="prescription-item-disclaimer">
                            <ToastBox icon="default" variant="info">
                                <div>
                                    <Trans
                                        i18nKey={prescription.disclaimerTranslationKey}
                                        values={{
                                            phoneNumber: getPhoneNumber({
                                                isEnd:
                                                    prescription?.disclaimerTranslationKey ===
                                                    'pages.cart.rxItemErrorMessageDiscount'
                                            })
                                        }}
                                    />
                                </div>
                            </ToastBox>
                        </div>
                    )}
                    {prescription.isBirdiSelect && (
                        <Row className="prescription-item-birdi-select-toast">
                            <ToastBox icon="default" variant="info">
                                <div className="prescription-item-birdi-select-info">
                                    {t('components.medicineCabinetCart.prescriptionInfo.birdiSelectInfo')}
                                </div>
                            </ToastBox>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

export default PrescriptionItem;
