import { createRoutine } from 'redux-saga-routines';

import { CreateWebAccountRequest } from './registration.types';

export const registrationCreateWebAccountRoutine = createRoutine('REGISTRATION_CREATE_WEB_ACCOUNT', {
    trigger: (payload: CreateWebAccountRequest) => payload
});
export const registrationContinueRegistrationRoutine = createRoutine('registration/CONTINUE_REGISTRATION');
export const registrationMinimalFinalizeRoutine = createRoutine('registration/MINIMAL_FINALIZE');

export const registrationInsuredValidationRoutine = createRoutine('registration/INSURANCE_VALIDATION');
