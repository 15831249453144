import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// UI Kit & Components
import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import BirdiModalErrorContent from 'components/birdi-modal/birdi-modal-error-content';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

// State
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartCompleteOrderRoutine } from 'state/cart/cart.routines';
import { cartIsBusySelector } from 'state/cart/cart.selectors';

// Schema
import { formatPrice } from 'schema/price.schema';

import { CartOrderRx } from 'types/cart';

// Utils
import { isAxiosError } from 'util/axiosClient';
import { TrackCheckoutStep } from 'util/google_optimize/optimize_helper';
import { doesPlanAllowsPricing } from 'util/pricing';

import useCart from 'hooks/useCart';
// Hooks
import useHealthConditions from 'hooks/useHealthConditions';
import usePrescriptionFlow from 'hooks/usePrescriptionFlow';

import './cart-total.style.scss';

interface ReviewCartTotalProps {
    isUnknownPrice: boolean;
}

export const CartTotal = ({ isUnknownPrice }: ReviewCartTotalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { cartData, prescriptionsInCart } = useCart();
    const { mainUserHasInsurance, mainUserPlan } = usePrescriptionFlow();
    const { isAnyCartPatientMissingHealthConditions } = useHealthConditions();

    const [isOneClickOrderAvailable, setIsOneClickOrderAvailable] = useState<boolean>(false);

    useEffect(() => {
        setIsOneClickOrderAvailable(!isAnyCartPatientMissingHealthConditions && !!cartData?.paymentMethod);
    }, [cartData, isAnyCartPatientMissingHealthConditions]);

    const isBusy = useSelector(cartIsBusySelector);

    const showPaymentError = () => {
        dispatch(
            openModal({
                showClose: false,
                type: 'danger',
                size: 'lg',
                onClose: () => {
                    dispatch(closeModal({}));
                },
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('modals.paymentMethods.paymentError.title')} icon="alert" />
                ),
                bodyContent: (
                    <BirdiModalErrorContent
                        description={t('modals.paymentMethods.paymentError.description')}
                        ctaText={t('modals.paymentMethods.paymentError.cta')}
                        onClose={() => {
                            dispatch(closeModal({}));
                        }}
                    />
                ),
                ctas: []
            })
        );
    };

    const handleSubmitOrderClick = () => {
        const cartPrescriptions: CartOrderRx[] = prescriptionsInCart || [];
        dispatch(
            cartCompleteOrderRoutine({
                currentShippingPrice: Number(cartData?.shipMethodPrice),
                lineItems: cartPrescriptions.map((rx) => rx.rxDetails),
                onSuccess: () => {
                    TrackCheckoutStep({
                        stepName: 'purchase',
                        step: '1',
                        carts: cartData?.patients || [],
                        prescriptions: cartPrescriptions,
                        t: t,
                        shippingCost: String(cartData?.shipMethodPrice),
                        accountHasInsurance: mainUserHasInsurance
                    });
                    navigate('/secure/cart/order-confirmation');
                },
                onFailure: (data: unknown) => {
                    if (
                        data &&
                        typeof data === 'object' &&
                        'response' in data &&
                        isAxiosError(data.response) &&
                        data.response.response?.data.SystemMessage === 'PRE-AUTH-DENIED'
                    ) {
                        showPaymentError();
                    } else {
                        dispatch(
                            openModal({
                                showClose: true,
                                bodyContent: (
                                    <BirdiModalContent
                                        icon={'alert'}
                                        title={t(`pages.cart.error.errorTitle`)}
                                        body={t(`pages.cart.error.errorMessage`)}
                                    />
                                ),
                                ctas: [
                                    {
                                        label: t(`pages.cart.error.cta`),
                                        variant: 'primary',
                                        onClick: () => {
                                            dispatch(closeModal({}));
                                        }
                                    }
                                ]
                            })
                        );
                    }
                }
            })
        );
    };

    return (
        <div className="cart-total">
            <div className="cart-total__calculations">
                <div className="cart-total__calculations__subtotal">
                    <div>
                        {t('pages.cart.subTotal')}
                        {isUnknownPrice ? <sup>*</sup> : ''}:
                    </div>
                    <div>{formatPrice(cartData?.subTotal)}</div>
                </div>

                <div className="cart-total__calculations__shipping">
                    <div>{t('pages.cart.shipping')}</div>
                    <div>{formatPrice(cartData?.shipMethodPrice)}</div>
                </div>
            </div>

            <div className="cart-total__total">
                <div className="cart-total__total__price">
                    {t('components.medicineCabinetCart.newCartTotal.total')}
                    {isUnknownPrice ? <sup>*</sup> : ''}
                </div>
                <div className="cart-total__total__price">{formatPrice(cartData?.total)}</div>
            </div>

            <div className="cart-total__container-notes">
                {mainUserHasInsurance ? (
                    <ToastBox icon="default" variant="info">
                        <div className="cart-total__note">
                            {t('components.medicineCabinetCart.newCartTotal.insuranceDisclaimer')}
                        </div>
                    </ToastBox>
                ) : (
                    !mainUserHasInsurance &&
                    doesPlanAllowsPricing(mainUserPlan) && (
                        <ToastBox icon="default" variant="info">
                            <div className="cart-total__note">
                                {t('components.medicineCabinetCart.newCartTotal.birdiPriceDisclaimer')}
                            </div>
                        </ToastBox>
                    )
                )}
            </div>
            <div className="cart-total__ctas">
                <Button
                    async
                    isBusy={isBusy}
                    disabled={!isOneClickOrderAvailable || isBusy}
                    type="button"
                    className="btn btn-primary no-min-width btn-bold"
                    label={t('components.medicineCabinetCart.newCartTotal.submit')}
                    onClick={handleSubmitOrderClick}
                />

                <Button
                    customFontSize="18"
                    type="button"
                    variant="text"
                    className="btn btn-text text-cerulean no-min-width"
                    label={t('components.medicineCabinetCart.newCartTotal.review')}
                    onClick={() => navigate('/secure/cart/review')}
                    disabled={isBusy}
                />

                <div className="cart-total__ctas__disclaimer">
                    {t('components.medicineCabinetCart.newCartTotal.disclaimer')}
                </div>
            </div>
            <br />
        </div>
    );
};
