import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';

import InputError from 'ui-kit/input-error/input-error';
import Tooltip from 'ui-kit/tooltip/tooltip';

import { TrackInputFocus } from 'util/google_optimize/optimize_helper';

import { TextProps } from './text.props';
import './text.style.scss';

const TextSetValue = ({
    className,
    defaultValue,
    errors,
    footNote,
    icon,
    label,
    name,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    showErrorsText = true,
    style,
    touched,
    tooltipText,
    type = 'text',
    value,
    disabled = false,
    maxLength = undefined,
    rows = undefined,
    ariaExpanded,
    inputRef,
    title,
    autoComplete = Date.now().toString(),
    readOnly
}: TextProps) => {
    const localInputRef = useRef(null);
    const inputEl = inputRef ? inputRef : localInputRef;
    const [isFocused, setIsFocused] = useState(false);
    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        TrackInputFocus(event.target, label);
        onFocus !== undefined && onFocus(event);
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        onBlur !== undefined && onBlur(event);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        onKeyDown !== undefined && onKeyDown(event);
    };

    const classes = classNames(
        'text',
        { focused: isFocused },
        { 'has-value': value || defaultValue },
        { 'has-errors': touched && errors },
        className
    );

    return (
        <div className={classes} style={style}>
            <div className={`text-input-container type-input`}>
                {label && (
                    <Form.Label className="text-label" htmlFor={name}>
                        {label}
                    </Form.Label>
                )}
                <Form.Control
                    ref={inputEl}
                    as={'input'}
                    className={`text-input ${className} ${disabled ? 'disabled' : ''}`}
                    type={type}
                    name={name}
                    id={name}
                    onChange={onChange}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onKeyDown={handleKeyDown}
                    value={value}
                    disabled={disabled}
                    maxLength={maxLength ? maxLength : 128}
                    aria-expanded={ariaExpanded}
                    autoComplete={autoComplete}
                    title={title}
                    readOnly={readOnly}
                />
                {tooltipText && <Tooltip tip={tooltipText} />}
                {icon}
            </div>
            <InputError
                inputElement={inputEl}
                touched={touched}
                label={label}
                errors={errors}
                showErrors={showErrorsText}
                className="text-errors"
            />
            {footNote && <div className="text-footnote">{footNote}</div>}
        </div>
    );
};

export default TextSetValue;
