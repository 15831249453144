import { ALLOW_INSURED_BIRDI_PRICE } from 'gatsby-env-variables';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { cartUpdateExpeditedShippingRoutine } from 'state/cart/cart.routines';
import { easyRefillUpdateShippingMethodRoutine } from 'state/easy-refill/easy-refill.routines';

import { CartOrderRx } from 'types/cart';
import { PatientBasicData } from 'types/family-account';
import { PrescriptionObjectPayload } from 'types/prescription';

import { RX_AVAILABLE_FLOWS } from 'enums/prescription';
import { PRICING_API_LOCATION } from 'enums/pricing';

import { EXPEDITED_SHIPPING_ID, getPatientsInCart } from 'util/cart';
import { UnknownFunction } from 'util/function';
import { isDeepEqual } from 'util/object';
import { doesPlanAllowsPricing } from 'util/pricing';

import useBirdiPrice from 'hooks/useBirdiPrice';

import usePrescriptionFlow from './usePrescriptionFlow';

const useCart = () => {
    // --- Hooks ---
    const dispatch = useDispatch();
    const { isDataLoaded, cart, currentFlow } = usePrescriptionFlow();
    const { setPriceEligiblePrescriptions } = useBirdiPrice();
    const [cachedPatientsInCart, setCachedPatientsInCart] = useState<PatientBasicData[]>([]);

    // Return all the Rxs list in the cart
    const prescriptionsInCart: CartOrderRx[] | undefined = useMemo(
        () => cart?.patients.flatMap((patient) => patient.cartRxs),
        [cart]
    );

    // Return patients list with items in the cart
    const patientsInCart: PatientBasicData[] = useMemo(() => {
        if (!cart) return [];
        return getPatientsInCart(cart);
    }, [cart]);

    // Function to trigger shipping method change from cart
    const changeCartShippingMethod = ({
        shipMethodId,
        onSuccess,
        onFailure
    }: {
        shipMethodId: string;
        onSuccess?: UnknownFunction;
        onFailure?: UnknownFunction;
    }) => {
        // Select the right routine to execute the change according to the flow
        const changeShippingMethodRoutines: Record<RX_AVAILABLE_FLOWS, any> = {
            [RX_AVAILABLE_FLOWS.EASY_REFILL]: easyRefillUpdateShippingMethodRoutine,
            [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: cartUpdateExpeditedShippingRoutine,
            [RX_AVAILABLE_FLOWS.AUTO_REFILL]: undefined
        };
        const changeShippingMethodRoutine = changeShippingMethodRoutines[currentFlow];

        // Perform shipping method change
        if (changeShippingMethodRoutine) {
            dispatch(
                changeShippingMethodRoutine({
                    orderHighPriority: shipMethodId === EXPEDITED_SHIPPING_ID,
                    shipMethodId,
                    onSuccess: () => {
                        if (onSuccess) onSuccess();
                    },
                    onFailure: () => {
                        if (onFailure) onFailure();
                    }
                })
            );
        }
    };

    // --- Use effects ---

    useEffect(() => {
        if (!!prescriptionsInCart && prescriptionsInCart.length > 0) {
            // Get prices for rxs in the cart that should be using birdi price and that doesn't have birdi price yet
            const priceEligibleRxs: PrescriptionObjectPayload[] = prescriptionsInCart
                .filter((rx) => {
                    // Validate feature flag is on and plan is insurance
                    const isInsurance = !doesPlanAllowsPricing(rx.primaryPlanAlias);
                    const insuranceAllowsPrice = (ALLOW_INSURED_BIRDI_PRICE && isInsurance) || !isInsurance;
                    // Get prices for rxs that are in the cart that allow Birdi cash price (BRD02 and BRD01)
                    return rx.cartPrice?.showBirdiCashPrice && insuranceAllowsPrice && !rx.cartPrice?.birdiPrice;
                })
                .map((rx) => rx.rxDetails.prescriptionDetail)
                .filter((rx) => rx !== undefined);

            // If we have more than 1 rx available we will pass the value through the
            // setCartPriceEligiblePrescriptions function that will cache the values avoiding
            // to load a request more times than needed.
            if (priceEligibleRxs.length > 0 && isDataLoaded) {
                setPriceEligiblePrescriptions(priceEligibleRxs, PRICING_API_LOCATION.CART);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescriptionsInCart, isDataLoaded]);

    useEffect(() => {
        if (!isDeepEqual(patientsInCart, cachedPatientsInCart)) {
            setCachedPatientsInCart(patientsInCart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientsInCart]);

    return {
        cartData: cart,
        patientsInCart: cachedPatientsInCart,
        prescriptionsInCart,
        changeCartShippingMethod
    };
};

export default useCart;
