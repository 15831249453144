import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ToastBox from 'ui-kit/toast-box/toast-box';

import { DEFAULT_SHIPPING_ID, EXPEDITED_SHIPPING_ID } from 'util/cart';

import useCart from 'hooks/useCart';

import './cart-shipping.style.scss';
import { CartShippingProps } from './types';

export const CartShipping = ({ handleDefaultShippingClick, handleExpeditedShippingClick }: CartShippingProps) => {
    const { t } = useTranslation();
    const { cartData } = useCart();
    const handleShippingTypeChange = ({ expedited }: { expedited: boolean }) => {
        if (expedited) {
            handleExpeditedShippingClick();
        } else {
            handleDefaultShippingClick();
        }
    };
    return (
        <>
            <Container className="cart-shipping d-flex flex-column" fluid>
                <Row>
                    <Col>
                        <label className="shipping-option d-flex">
                            <input
                                type="radio"
                                name="shipping-option"
                                id={`standard-shipping`}
                                defaultChecked={cartData?.shipMethodId === DEFAULT_SHIPPING_ID}
                                onClick={() => handleShippingTypeChange({ expedited: false })}
                            />
                            <span>{t('pages.cart.freeShipping')}</span>
                        </label>
                        <label className="shipping-option d-flex">
                            <input
                                type="radio"
                                name="shipping-option"
                                id={`expedited-shipping`}
                                defaultChecked={cartData?.shipMethodId === EXPEDITED_SHIPPING_ID}
                                onClick={() => handleShippingTypeChange({ expedited: true })}
                            />
                            <span>{t('pages.cart.expeditedShipping')}</span>
                        </label>
                        <div className="mt-3">
                            <ToastBox icon="default" variant="info">
                                {t('pages.cart.shippingFooter')}
                            </ToastBox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
