import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';

import { MembershipPersonalDetailsFormValues } from 'types/membership';

import { accountFinalizeNotInsuranced, ValidateInviteMembershipResponse } from './membership-registration.helpers';
import {
    membershipRegistrationClearPromoCodeRoutine,
    membershipRegistrationGetEmailZipcodeRoutine,
    membershipRegistrationGetEthnicitiesRoutine,
    membershipRegistrationGetGendersRoutine,
    membershipRegistrationGetPlanDetailsRoutine,
    membershipRegistrationGetRacesRoutine,
    membershipRegistrationPromoCodeRoutine,
    membershipRegistrationRegisterRoutine,
    membershipRegistrationUninsuredRegisterRoutine,
    membershipRegistrationValidateInsuranceIdRoutine,
    membershipRegistrationValidateInviteRoutine
} from './membership-registration.routines';

export interface SelectPlanState {
    membershipPlanId: number;
    membershipPlanDescription: string;
    membershipPlanAttributes: [
        {
            membershipMembers: number;
            membershipPlanPrescsavings: string;
            membershipPlanDiscsavings: string;
            membershipPlanFreeshipping: string;
            membershipPlanGenericdrugs: string;
            membershipPlanFreeprescripts: string;
            membershipPlanSchedtelevists: string;
            membershipPlanDiscdiabetic: string;
            pricingMonthly: number;
            pricingAnnual: number;
            pricingQuarter: number;
            joinFeeAmount: number;
            discountMonthly: number;
            discountAnnual: number;
            discountQuarter: number;
            membershipIncludedvisits: number;
            membershipIncludedrx: number;
            payAsYouGo: boolean;
        }
    ];
}
export interface MembershipRegistrationState {
    formValues: MembershipPersonalDetailsFormValues;
    gendersResult: {
        genderCode: string;
        genderDesc: string;
        genderId: number;
    }[];
    racesResult: {
        raceId: number;
        raceDesc: string;
        active: string;
    }[];
    ethnicitiesResult: {
        ethnicityId: number;
        ethnicityDesc: string;
        active: string;
    }[];
    emailZipcodeResult: {
        messageStatus: boolean;
        messageText: string;
        validate: string;
    };
    insuranceResult: {
        validate?: string;
    };
    selectPlanResult?: SelectPlanState[];
    isCaliforniaUser: boolean;
    paymentModel: 'Yearly' | 'Monthly';
    isPromoCodeValid: undefined | true | false;
    responseErrorPayload: accountFinalizeNotInsuranced | null;
    selectedPlanId: undefined | number;
    validateInviteData?: ValidateInviteMembershipResponse;
    isValidatingInvite: boolean;
    isBusy: boolean;
    patientPlanAutoRefillFlag: boolean;
    AutoRefill: boolean;
    isLoading: boolean;
}

export const initialState: MembershipRegistrationState = {
    formValues: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        ePostPatientNumber: '',
        dobMonth: '',
        dobDay: '',
        dobYear: '',
        prescriptionNumber: '',
        gender: '',
        race: '',
        ethnicity: '',
        email: '',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        password: '',
        passwordConfirm: ''
    },
    gendersResult: [],
    racesResult: [],
    ethnicitiesResult: [],
    selectPlanResult: [],
    emailZipcodeResult: {
        messageStatus: false,
        messageText: '',
        validate: ''
    },
    insuranceResult: {},
    isCaliforniaUser: false,
    paymentModel: 'Monthly',
    isPromoCodeValid: undefined,
    responseErrorPayload: null,
    selectedPlanId: undefined,
    validateInviteData: undefined,
    isValidatingInvite: true,
    isBusy: false,
    patientPlanAutoRefillFlag: false,
    AutoRefill: false,
    isLoading: false
};

const membershipRegistrationSlice = createSlice({
    name: 'membership-registration',
    initialState,
    reducers: {
        setInitialValues: (state, action: PayloadAction<{ initialValues: MembershipPersonalDetailsFormValues }>) => {
            state.formValues = action.payload.initialValues;
        },
        setMembershipFormValues: (state, { payload }: PayloadAction<MembershipPersonalDetailsFormValues>) => {
            state.formValues = payload;
        },
        updateMembershipFormValues: (state, { payload }: PayloadAction<MembershipPersonalDetailsFormValues>) => {
            state.formValues = {
                ...state.formValues,
                ...payload
            };
        },
        setMembershipIsCaliforniaUser: (state, { payload }: PayloadAction<any>) => {
            state.isCaliforniaUser = payload;
        },
        setPayMentModel: (state, { payload }: PayloadAction<any>) => {
            state.paymentModel = payload;
        },
        setSelectedPlanId: (state, { payload }: PayloadAction<any>) => {
            state.selectedPlanId = payload;
        },
        setAutoRefill: (state, { payload }: PayloadAction<any>) => {
            state.AutoRefill = payload;
        },
        setPatientPlanAutoRefillFlag: (state, { payload }: PayloadAction<any>) => {
            state.patientPlanAutoRefillFlag = payload;
        },
        startLoading: (state) => {
            state.isLoading = true;
        },
        stopLoading: (state) => {
            state.isLoading = false;
        },
        resetRegistrationInsuranceResult: (state) => {
            state.insuranceResult = {};
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(membershipRegistrationGetGendersRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.gendersResult = payload;
                // In the future, remove gendersResult
            })
        );
        addCase(membershipRegistrationGetRacesRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.racesResult = payload;
            })
        );
        addCase(membershipRegistrationGetEthnicitiesRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.ethnicitiesResult = payload;
            })
        );
        addCase(membershipRegistrationGetEmailZipcodeRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.emailZipcodeResult = payload;
            })
        );
        addCase(membershipRegistrationGetEmailZipcodeRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.emailZipcodeResult = payload;
            })
        );
        addCase(membershipRegistrationValidateInsuranceIdRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.insuranceResult.validate = payload.validate;
                draftState.formValues.formPersonalInfo = payload.formValues;
                draftState.formValues.insuranceForm = { insuranceId: payload.insuranceId };
            })
        );
        addCase(membershipRegistrationValidateInsuranceIdRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.insuranceResult.patientRegistrationPayload = payload?.patientRegistrationPayload;
                draftState.insuranceResult.validate = payload.validate;
                draftState.formValues.formPersonalInfo = payload.formValues;
                draftState.formValues.insuranceForm = { insuranceId: payload.insuranceId };
            })
        );
        addCase(membershipRegistrationGetPlanDetailsRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.selectPlanResult = payload;
            })
        );

        addCase(membershipRegistrationPromoCodeRoutine.TRIGGER, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.isPromoCodeValid = undefined;
                draftState.isBusy = true;
            })
        );

        addCase(membershipRegistrationPromoCodeRoutine.SUCCESS, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.selectPlanResult = payload;
                draftState.isPromoCodeValid = true;
                draftState.isBusy = false;
            })
        );

        addCase(membershipRegistrationPromoCodeRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isPromoCodeValid = false;
                draftState.isBusy = false;
            })
        );

        addCase(membershipRegistrationPromoCodeRoutine.FULFILL, (state) =>
            produce(state, (draftState) => {
                draftState.isPromoCodeValid = undefined;
            })
        );

        addCase(membershipRegistrationClearPromoCodeRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isPromoCodeValid = undefined;
            })
        );
        addCase(membershipRegistrationUninsuredRegisterRoutine.FAILURE, (state, { payload }: PayloadAction<any>) =>
            produce(state, (draftState) => {
                draftState.responseErrorPayload = payload || null;
            })
        );

        // Validate Invite Membership
        addCase(membershipRegistrationValidateInviteRoutine.TRIGGER, (state) =>
            produce(state, (draftState) => {
                draftState.isValidatingInvite = true;
            })
        );
        addCase(
            membershipRegistrationValidateInviteRoutine.SUCCESS,
            (state, { payload }: PayloadAction<ValidateInviteMembershipResponse>) =>
                produce(state, (draftState) => {
                    draftState.isValidatingInvite = false;
                    draftState.validateInviteData = payload;
                })
        );
        addCase(membershipRegistrationValidateInviteRoutine.FAILURE, (state) =>
            produce(state, (draftState) => {
                draftState.isValidatingInvite = false;
            })
        );
        addCase(membershipRegistrationRegisterRoutine.SUCCESS, (state) =>
            produce(state, (draftState) => {
                draftState.insuranceResult = {};
            })
        );
    }
});

export const {
    setMembershipFormValues,
    updateMembershipFormValues,
    setMembershipIsCaliforniaUser,
    setPayMentModel,
    setSelectedPlanId,
    setAutoRefill,
    setPatientPlanAutoRefillFlag,
    startLoading,
    stopLoading,
    resetRegistrationInsuranceResult
} = membershipRegistrationSlice.actions;

export default membershipRegistrationSlice.reducer;
