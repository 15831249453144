import { createSelector } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    accountAcCodeSelector,
    accountCreditCardsSelector,
    accountDefaultAddressZipCodeSelector,
    accountDependentsBasicDataSelector,
    accountHasInsuranceSelector,
    accountIsLoadingPlansSelector,
    accountIsMembershipSelector,
    accountPlansSelector,
    accountProfileAddressesSelector,
    accountProfilEPostPatientNumSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import {
    autoRefillAcCodeSelector,
    autoRefillAccountHasInsuranceSelector,
    autoRefillActiveTabSelector,
    autoRefillAdressesSelector,
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyMembersBasicDataSelector,
    autoRefillFamilyMembersPricingDataSelector,
    autoRefillIsCaregiverSelector,
    autoRefillPaymentCardsSelector,
    autoRefillPrescriptionsCardsSelector,
    autoRefillSelector,
    autoRefillZipCodeSelector
} from 'state/auto-refill/auto-refill.selectors';
import { setCartCache, setCartZipCodeCache, setFamilyPricingDataCache } from 'state/cache/cache.reducers';
import {
    cacheCartSelector,
    cacheCartZipCodeSelector,
    cacheFamilyPricingDataSelector
} from 'state/cache/cache.selectors';
import {
    cartIsBusySelector,
    cartItemsSelector,
    cartProcessedSelector,
    cartZipCodeSelector,
    lastOrderSelector
} from 'state/cart/cart.selectors';
import {
    easyFamilyMembersBasicDataSelector,
    easyRefillAccountHasInsuranceSelector,
    easyRefillActiveTabSelector,
    easyRefillAddressesSelector,
    easyRefillBusySelector,
    easyRefillCartItemsSelector,
    easyRefillCartProcessedSelector,
    easyRefillCartZipCodeSelector,
    easyRefillEpostPatientNumSelector,
    easyRefillFamilyMembersPricingDataSelector,
    easyRefillIsCaregiverSelector,
    easyRefillisLoadingPlansSelector,
    easyRefillIsMembershipSelector,
    easyRefillPatientShipAddressZipCodeSelector,
    easyRefillPaymentCardsSelector,
    easyRefillPlanAliasSelector,
    easyRefillPlansSelector,
    easyRefillPrescriptionsCardsSelector
} from 'state/easy-refill/easy-refill.selectors';
import { familyMembersPricingDataSelector } from 'state/family-profile/family-profile.selectors';
import {
    medicineCabinetActiveTabSelector,
    medicineCabinetPrescriptionsCardsSelector
} from 'state/medicine-cabinet/medicine-cabinet.selectors';

import { AddressPayload } from 'types/account';
import { CartObject } from 'types/cart';
import { CreditCardPayload } from 'types/credit-card';
import { DependentsPricingData, PatientBasicData } from 'types/family-account';
import { RefillRxs } from 'types/order-prescription';
import { PlansObjectPayload } from 'types/plans';
import { PrescriptionCardProps } from 'types/prescription';
import { Selector } from 'types/saga-handler';

import { BIRDI_PLANS } from 'enums/plans';
import { RX_AVAILABLE_FLOWS } from 'enums/prescription';

import { isDeepEqual } from 'util/object';

export interface FlowSelectorsMapping {
    prescriptionCards: Selector<PrescriptionCardProps[]>;
    activeTab: Selector<string | null>;
    cartZipCode: Selector<string | undefined>;
    cartItems: Selector<RefillRxs[] | undefined>;
    cartIsBusy: Selector<boolean>;
    familyPricingData: Selector<DependentsPricingData[]>;
    familyPlanDetails: Selector<PlansObjectPayload[]>;
    mainUserPlan: Selector<BIRDI_PLANS>;
    mainUserZipCode: Selector<string | undefined>;
    mainUserHasMembership: Selector<boolean>;
    mainUserHasInsurance: Selector<boolean>;
    mainUserAddresses: Selector<AddressPayload[]>;
    mainUserIsCaregiver: Selector<boolean | undefined>;
    mainUserCreditCards: Selector<CreditCardPayload[] | undefined>;
    mainUserEpostPatientNum: Selector<string | undefined>;
    cart: Selector<CartObject | undefined>;
    lastOrder: Selector<CartObject | undefined>;
    familyMembersBasicData: Selector<PatientBasicData[]>;
    loadingPlans: Selector<boolean>;
}

/**
 * This Hook allows us to unify the sagas selectors used across
 * the flows that render prescription information ensuring data consistency
 */
const usePrescriptionFlow = () => {
    const dispatch = useDispatch();

    // Cached values in store to reuse them regardless how many instances
    // of this hook are created
    const cachedFamilyPricingData = useSelector(cacheFamilyPricingDataSelector);
    const cachedCart = useSelector(cacheCartSelector);
    const cachedCartZipCode = useSelector(cacheCartZipCodeSelector);

    // Easy Refill data
    const easyRefillEpostNum = useSelector(easyRefillEpostPatientNumSelector);

    // Auto refill flow
    const autoRefillEpostPatientNum = useSelector(autoRefillePostPatientNumberSelector);

    const selectors: Record<RX_AVAILABLE_FLOWS, FlowSelectorsMapping> = {
        [RX_AVAILABLE_FLOWS.MEDICINE_CABINET]: {
            // Prescriptions Data
            prescriptionCards: medicineCabinetPrescriptionsCardsSelector,
            activeTab: medicineCabinetActiveTabSelector,
            // Cart Data
            cartItems: cartItemsSelector,
            cartIsBusy: cartIsBusySelector,
            cartZipCode: cartZipCodeSelector, // Move to cart
            // Family data
            familyPricingData: familyMembersPricingDataSelector,
            familyPlanDetails: accountPlansSelector,
            // Main user data
            mainUserEpostPatientNum: accountProfilEPostPatientNumSelector,
            mainUserPlan: accountAcCodeSelector,
            mainUserZipCode: accountDefaultAddressZipCodeSelector,
            mainUserHasMembership: accountIsMembershipSelector,
            mainUserHasInsurance: accountHasInsuranceSelector,
            mainUserAddresses: accountProfileAddressesSelector,
            mainUserIsCaregiver: accountProfilIsCaregiverSelector,
            mainUserCreditCards: accountCreditCardsSelector,
            cart: cartProcessedSelector,
            familyMembersBasicData: accountDependentsBasicDataSelector,
            loadingPlans: accountIsLoadingPlansSelector,
            lastOrder: lastOrderSelector
        },
        [RX_AVAILABLE_FLOWS.EASY_REFILL]: {
            // Prescriptions Data
            prescriptionCards: easyRefillPrescriptionsCardsSelector,
            activeTab: easyRefillActiveTabSelector,
            // Cart Data
            cartItems: easyRefillCartItemsSelector,
            cartZipCode: easyRefillCartZipCodeSelector,
            cartIsBusy: easyRefillBusySelector,

            // Family Data
            familyPricingData: easyRefillFamilyMembersPricingDataSelector,
            familyPlanDetails: easyRefillPlansSelector,
            // Main user data
            mainUserPlan: easyRefillPlanAliasSelector,
            mainUserZipCode: easyRefillPatientShipAddressZipCodeSelector,
            mainUserHasMembership: easyRefillIsMembershipSelector,
            mainUserHasInsurance: easyRefillAccountHasInsuranceSelector,
            mainUserAddresses: easyRefillAddressesSelector,
            mainUserIsCaregiver: easyRefillIsCaregiverSelector,
            mainUserCreditCards: easyRefillPaymentCardsSelector,
            mainUserEpostPatientNum: easyRefillEpostPatientNumSelector,
            cart: easyRefillCartProcessedSelector,
            familyMembersBasicData: easyFamilyMembersBasicDataSelector,
            loadingPlans: easyRefillisLoadingPlansSelector,
            lastOrder: lastOrderSelector
        },
        [RX_AVAILABLE_FLOWS.AUTO_REFILL]: {
            // Prescriptions Data
            prescriptionCards: autoRefillPrescriptionsCardsSelector,
            activeTab: autoRefillActiveTabSelector,
            // Cart Data
            cartZipCode: autoRefillZipCodeSelector,
            // The cart doesn't exist in auto refill so we mock
            // the following selectors's data

            cartItems: createSelector(autoRefillSelector, () => {
                return [] as RefillRxs[];
            }),

            cartIsBusy: createSelector(autoRefillSelector, () => {
                return false;
            }),

            // Family data
            familyPricingData: autoRefillFamilyMembersPricingDataSelector,
            familyPlanDetails: createSelector(autoRefillSelector, () => {
                return [];
            }),
            // Main user data
            mainUserPlan: autoRefillAcCodeSelector,
            mainUserZipCode: autoRefillZipCodeSelector,
            // We don't have user's plans information in auto refill so we
            // are mocking it to false
            mainUserHasMembership: createSelector(autoRefillSelector, () => {
                return false;
            }),
            mainUserHasInsurance: autoRefillAccountHasInsuranceSelector,
            mainUserAddresses: autoRefillAdressesSelector,
            mainUserIsCaregiver: autoRefillIsCaregiverSelector,
            mainUserCreditCards: autoRefillPaymentCardsSelector,
            mainUserEpostPatientNum: autoRefillePostPatientNumberSelector,
            cart: createSelector(autoRefillSelector, () => {
                return undefined;
            }),
            lastOrder: createSelector(autoRefillSelector, () => {
                return undefined;
            }),
            familyMembersBasicData: autoRefillFamilyMembersBasicDataSelector,
            loadingPlans: createSelector(autoRefillSelector, () => {
                return false;
            })
        }
    };

    // Load memoized data
    // TODO: Re-structure this data to be managed from the sagas, so we won't need
    // to manually switch the type of data. Ideally, BE will return consistent values too.
    // Also, this will need to be adapted to medicine cabinet
    const currentFlow: RX_AVAILABLE_FLOWS = useMemo(
        () =>
            easyRefillEpostNum
                ? RX_AVAILABLE_FLOWS.EASY_REFILL
                : autoRefillEpostPatientNum
                ? RX_AVAILABLE_FLOWS.AUTO_REFILL
                : RX_AVAILABLE_FLOWS.MEDICINE_CABINET,
        [easyRefillEpostNum, autoRefillEpostPatientNum]
    );

    // Mapping the selectors based on each flow
    const mainUserPlan: BIRDI_PLANS = useSelector(selectors[currentFlow].mainUserPlan);
    const familyPricingData: DependentsPricingData[] = useSelector(selectors[currentFlow].familyPricingData);
    const prescriptionCards: PrescriptionCardProps[] = useSelector(selectors[currentFlow].prescriptionCards);
    const activeTab: string | null = useSelector(selectors[currentFlow].activeTab);
    const cartItems: RefillRxs[] | undefined = useSelector(selectors[currentFlow].cartItems);
    const cartZipCode: string | undefined = useSelector(selectors[currentFlow].cartZipCode);
    const cartIsBusy: boolean = useSelector(selectors[currentFlow].cartIsBusy);
    const mainUserZipCode: string | undefined | null = useSelector(selectors[currentFlow].mainUserZipCode);
    const familyPlanDetails: PlansObjectPayload[] = useSelector(selectors[currentFlow].familyPlanDetails);
    const mainUserHasMembership: boolean = useSelector(selectors[currentFlow].mainUserHasMembership);
    const mainUserHasInsurance: boolean = useSelector(selectors[currentFlow].mainUserHasInsurance);
    const mainUserAddresses: AddressPayload[] = useSelector(selectors[currentFlow].mainUserAddresses);
    const mainUserIsCaregiver: boolean | undefined = useSelector(selectors[currentFlow].mainUserIsCaregiver);
    const mainUserCreditCards: CreditCardPayload[] | undefined = useSelector(
        selectors[currentFlow].mainUserCreditCards
    );
    const mainUserEpostPatientNum: string | undefined = useSelector(selectors[currentFlow].mainUserEpostPatientNum);
    const cart: CartObject | undefined = useSelector(selectors[currentFlow].cart);
    const lastOrder: CartObject | undefined = useSelector(selectors[currentFlow].lastOrder);
    const familyMembersBasicData: PatientBasicData[] = useSelector(selectors[currentFlow].familyMembersBasicData);
    const isLoadingPlans: boolean = useSelector(selectors[currentFlow].loadingPlans);

    const isDataLoaded = useMemo(() => {
        const familyDataLoaded = mainUserIsCaregiver ? !isLoadingPlans && familyMembersBasicData.length > 0 : true;
        return familyDataLoaded && familyPricingData.length > 0 && (!!mainUserZipCode || !!cartZipCode) && !cartIsBusy;
    }, [
        familyPricingData,
        cartIsBusy,
        mainUserZipCode,
        cartZipCode,
        familyMembersBasicData,
        mainUserIsCaregiver,
        isLoadingPlans
    ]);

    // Using state and use effect to cache the values and prevent data being exposed to components multiple times
    useEffect(() => {
        if (familyPricingData.length > 0 && !isDeepEqual(cachedFamilyPricingData, familyPricingData)) {
            dispatch(setFamilyPricingDataCache(familyPricingData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [familyPricingData, cachedFamilyPricingData]);

    useEffect(() => {
        if (cartZipCode !== cachedCartZipCode) {
            dispatch(setCartZipCodeCache(cartZipCode));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartZipCode, cachedCartZipCode]);

    useEffect(() => {
        if (cart !== undefined && cachedCart !== undefined && !isDeepEqual(cachedCart, cart)) {
            dispatch(setCartCache(cart));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart, cachedCart]);

    return {
        currentFlow,
        cartZipCode: cachedCartZipCode,
        mainUserPlan,
        familyPricingData: cachedFamilyPricingData,
        prescriptionCards,
        activeTab,
        cartItems,
        mainUserZipCode,
        cartIsBusy,
        familyPlanDetails,
        mainUserHasMembership,
        mainUserHasInsurance,
        mainUserAddresses,
        mainUserIsCaregiver,
        mainUserCreditCards,
        mainUserEpostPatientNum,
        isDataLoaded,
        cart,
        lastOrder,
        familyMembersBasicData
    };
};

export default usePrescriptionFlow;
