import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import { drupalFetchCSRFRoutine, drupalMaintenanceRoutine } from 'state/drupal/drupal.routines';

import { MaintenanceType } from 'types/drupal';

import { baseEffectHandler } from 'util/sagas/sagas';
import storageHelper from 'util/storageHelper';

import DrupalService from './drupal.services';

export default function* drupalSaga() {
    yield takeLatest(drupalFetchCSRFRoutine.TRIGGER, function* (action: PayloadAction<any>) {
        yield baseEffectHandler({
            service: DrupalService.fetchCSRF().get,
            *onResponse(data: any) {
                yield put(drupalFetchCSRFRoutine.success(data));
            },
            *onError(error: any) {
                yield put(drupalFetchCSRFRoutine.failure(error));
            }
        });
    });

    yield takeLatest(drupalMaintenanceRoutine.TRIGGER, function* () {
        // Uses cookie to avoid call the endpoint if the user reload multiple times.
        const cookie = storageHelper.cookies;
        const isInMaintenanceMode = cookie.getIsInMaintenanceMode();

        if (isInMaintenanceMode) {
            yield put(
                drupalMaintenanceRoutine.success({
                    maintenance_mode: isInMaintenanceMode === 'true',
                    maintenance_content: JSON.parse(localStorage.getItem('maintenanceContent') || '{}')
                })
            );
            return;
        }

        yield baseEffectHandler({
            service: DrupalService.maintenance().get,
            *onResponse(data: MaintenanceType) {
                cookie.setIsInMaintenanceMode(data.maintenance_mode);
                localStorage.setItem('maintenanceContent', JSON.stringify(data.maintenance_content));
                yield put(drupalMaintenanceRoutine.success(data));
            },
            *onError() {
                yield put(
                    drupalMaintenanceRoutine.failure({
                        maintenance_mode: false
                    })
                );
            }
        });
    });
}
