import { createRoutine } from 'redux-saga-routines';

export const easyRefillVerifyUserRoutine = createRoutine('easy-refill/VERIFY_USER');
export const easyRefillGetSecretKeyHashRoutine = createRoutine('easy-refill/GET_SECRET_KEY_HASH');
export const easyRefillAuthenticateUserRoutine = createRoutine('easy-refill/AUTHENTICATE_USER');
export const easyRefillGetPatientDataRoutine = createRoutine('easy-refill/GET_PATIENT_DATA');

// Address Data
export const easyRefillGetPatientAddressesRoutine = createRoutine('easy-refill/GET_PATIENT_ADDRESSES');
export const easyRefillAddAddressesRoutine = createRoutine('easy-refill/ADD_ADDRESSES');
export const easyRefillUpdateAddressesRoutine = createRoutine('easy-refill/UPDATE_ADDRESSES');
export const easyRefillSetPrimaryAddressRoutine = createRoutine('easy-refill/SET_PRIMARY_ADDRESS');

// Payment Data
export const easyRefillGetPatientPaymentCardsRoutine = createRoutine('easy-refill/GET_PATIENT_PAYMENT_CARDS');
export const easyRefillPaymetricDetailsRoutine = createRoutine('easy-refill/PAYMETRIC_ACCESS_TOKEN');
export const easyRefillTokenizedCardNumberRoutine = createRoutine('easy-refill/TOKENIZE_CARD');
export const easyRefillAddPaymentCardsRoutine = createRoutine('easy-refill/ADD_PAYMENT_CARDS');
export const easyRefillUpdatePaymentCardsRoutine = createRoutine('easy-refill/UPDATE_PAYMENT_CARDS');
export const easyRefillGetPatientPlansRoutine = createRoutine('easy-refill/GET_PLANS');

// Order Data
export const easyRefillGetCartRoutine = createRoutine('easy-refill/GET_CART');
export const easyRefillStartOrderRoutine = createRoutine('easy-refill/START_ORDER');
export const easyRefillCancelOrderLineRoutine = createRoutine('easy-refill/CANCEL_ORDER_LINE');
export const easyRefillSubmitOrderRoutine = createRoutine('easy-refill/SUBMIT_ORDER');
export const easyRefillCancelOrderRoutine = createRoutine('easy-refill/CANCEL_ORDER');
export const easyRefillUpdateShippingRoutine = createRoutine('easy-refill/UPDATE_SHIPPING');
export const easyRefillUpdateCartPaymentRoutine = createRoutine('easy-refill/UPDATE_CART_PAYMENT');
export const easyRefillUpdateShippingMethodRoutine = createRoutine('easy-refill/UPDATE_SHIPPING_METHOD');

// Rxs Data
export const easyRefillRemoveRxToRefillRoutine = createRoutine('easy-refill/REMOVE_RX_TO_REFILL');
export const easyRefillRemoveAllRxToRefillRoutine = createRoutine('easy-refill/REMOVE_ALL_RX_TO_REFILL');
export const easyRefillAddRxToRefillRoutine = createRoutine('easy-refill/ADD_RX_TO_REFILL');
export const easyRefillToggleAutoRefillRxStatusRoutine = createRoutine('easy-refill/TOGGLE_AUTO_REFILL_RX_STATUS');

// Health Conditions
export const easyRefillFetchHealthConditionsRoutine = createRoutine('easy-refill/FETCH_HEALTH_CONDITIONS');
export const easyRefillUpdateMedicalConditionsRoutine = createRoutine('easy-refill/MEDICAL_CONDITIONS_UPDATE');
export const easyRefillUpdateAllergiesRoutine = createRoutine('easy-refill/ALLERGIES_UPDATE');
