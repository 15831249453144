import classNames from 'classnames';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { Container, Button as ReactButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// UI Kit
import Button from 'ui-kit/button/button';
import CartIcon from 'ui-kit/icons/cart-icon/cart-icon';
import SidebarCloseIconProps from 'ui-kit/icons/sidebar-close-icon/sidebar-close-icon';
import Spinner from 'ui-kit/spinner/spinner';

// Components
import Chatbot from 'components/chatbot/chatbot.component';
import { CartTotal } from 'components/medicine-cabinet-cart/cart-total/total-cart.component';
import EmptyCart from 'components/medicine-cabinet-cart/empty-cart/empty-cart.component';
import HealthProfile from 'components/medicine-cabinet-cart/health-profile/health-profile.component';
import PaymentMethod from 'components/medicine-cabinet-cart/payment-method/payment-method.component';
import PrescriptionInformation from 'components/medicine-cabinet-cart/prescription-information/prescription-information.component';
import ShippingAddress from 'components/medicine-cabinet-cart/shipping-address/shipping-address.component';
import ShippingMethod from 'components/medicine-cabinet-cart/shipping-method/shipping-method.component';
import { SidebarColumnProps } from 'components/sidebar-column/sidebar-column.component';

import {
    cartIsBusySelector,
    cartItemsTotalSelector,
    isLoadingAddressShippingCartSelector,
    isLoadingPaymentsCartSelector
} from 'state/cart/cart.selectors';
import {
    isLoadingAllergiesSelector,
    isLoadingHealthConditionsSelector
} from 'state/medical-conditions/medical-conditions.selector';
import { medicineCabinetIsBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';

import useWindowDimensions from 'hooks/useWindowDimensions';

interface SidebarColumnMobileProps extends SidebarColumnProps {
    cartLabel: string;
}

const SidebarColumnMobile: FC<SidebarColumnMobileProps> = ({ cartLabel }) => {
    const { t } = useTranslation();

    const ref = useRef<HTMLDivElement>(null);
    const isMounted = useRef<boolean>(true);

    const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
    const [isLabelVisible, setIsLabelVisible] = useState(true);
    const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false);
    const [isCartButtonVisible, setIsCartButtonVisible] = useState(true);

    const { width } = useWindowDimensions();

    // Autenticated Cart
    const cartItemsTotal = useSelector(cartItemsTotalSelector) || 0;
    const isLoadingPayments = useSelector(isLoadingPaymentsCartSelector);
    const isLoadingAllergies = useSelector(isLoadingAllergiesSelector);
    const isLoadingHealthConditions = useSelector(isLoadingHealthConditionsSelector);
    const isLoadingAddressShipping = useSelector(isLoadingAddressShippingCartSelector);
    const prescriptionsIsBusySelector = useSelector(medicineCabinetIsBusySelector);
    const cartIsBusy = useSelector(cartIsBusySelector);

    const isCartLoading =
        isLoadingAddressShipping ||
        isLoadingAllergies ||
        isLoadingHealthConditions ||
        prescriptionsIsBusySelector ||
        isLoadingPayments ||
        cartIsBusy;

    const userId = uuidv4();

    const toggleMobileMenuVisibility = () => {
        document.body.classList.toggle('scroll-lock');
        setIsMobileMenuVisible(!isMobileMenuVisible);
    };

    // Hotfix DRX-1982.
    // This implementation helps to prevent conflicting rules to
    // maintain the mobile opened after clicking on close button.
    const disableMobileMenuVisibility = () => {
        document.body.classList.remove('scroll-lock');
        setIsMobileMenuVisible(false);
    };

    const handleCartClick = () => {
        if (!isFloatingMenuVisible) {
            setIsFloatingMenuVisible(true);
            setIsCartButtonVisible(false);
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (isMounted.current && ref.current && !ref.current.contains(event.target as Node)) {
            setIsFloatingMenuVisible(false);
            setIsCartButtonVisible(true);
        }
    };

    const closeMenu = () => {
        disableMobileMenuVisibility();
        setIsFloatingMenuVisible(false);
        setIsCartButtonVisible(true);
    };

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY === 0) {
                setIsLabelVisible(true);
            } else {
                setIsLabelVisible(false);
            }
        });
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);

    // Hotfix DRX-1982.
    // This implementation prevents the screen to be scroll locked after
    // removing all prescriptions from cart on mobile devices.
    useEffect(() => {
        if (!cartItemsTotal || (cartItemsTotal && cartItemsTotal < 1)) {
            closeMenu();
        }
    }, [cartItemsTotal]);

    useEffect(() => {
        if (width > 990) closeMenu();
    }, [width]);

    return (
        <>
            {cartItemsTotal === 0 ? (
                <Chatbot uniqueID={userId} />
            ) : (
                <>
                    {isCartButtonVisible && (
                        <>
                            <div className="d-none d-lg-block">
                                <Chatbot uniqueID={userId} />
                            </div>
                            <Button
                                itemCount={cartItemsTotal}
                                cartIcon={true}
                                separator={true}
                                type="button"
                                label={isLabelVisible ? cartLabel : ''}
                                aria-label={'Cart Button'}
                                onClick={handleCartClick}
                                className={`d-lg-none sidebar-column__drawer-expanded-btn btn border-0 p-3 bg-cathams-blue no-min-width btn-primary d-flex align-items-center justify-content-between`}
                                style={isLabelVisible ? { width: '177px' } : ({ width: '112px' } as CSSProperties)}
                                chevron="up"
                            />
                        </>
                    )}
                    <div className={isFloatingMenuVisible ? 'blur-background' : ''}>
                        <Container
                            ref={ref}
                            fluid
                            className={classNames(
                                'sidebar-column__drawer d-lg-none justify-content-center',
                                `sidebar-column__drawer--${isMobileMenuVisible ? 'open' : 'closed'}`
                            )}
                        >
                            {isFloatingMenuVisible && (
                                <>
                                    <p className="sidebar-column__drawer-chat-text">
                                        {t('components.medicineCabinetCart.responsiveCart.chatButtonLabel')}
                                    </p>
                                    <Chatbot uniqueID={userId} isInCart />
                                    <p className="sidebar-column__drawer-cart-text">{cartLabel}</p>
                                    <Button
                                        label=""
                                        itemCount={cartItemsTotal!}
                                        cartIcon={true}
                                        type="button"
                                        aria-label={'Cart Button'}
                                        className={`sidebar-column__drawer-toggle btn border-0 p-3 bg-cathams-blue no-min-width btn-primary`}
                                        onClick={toggleMobileMenuVisibility}
                                    />
                                </>
                            )}

                            <div className="sidebar-column__drawer-content">
                                <div className="sidebar-column__drawer-close">
                                    <ReactButton
                                        className="thumbsup bg-transparent p-0 border-0 no-min-width"
                                        onClick={disableMobileMenuVisibility}
                                    >
                                        <SidebarCloseIconProps style={{ height: '1rem', width: '1rem' }} />
                                    </ReactButton>
                                </div>
                                <div className="medicine-cabinet-v2-cart__header">
                                    <CartIcon className={'header-icon-container'} />
                                    <h3>{cartLabel}</h3>
                                </div>

                                {isCartLoading && cartItemsTotal > 0 && (
                                    <div className="medicine-cabinet-v2-cart__loading__blocker--mobile">
                                        <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                                    </div>
                                )}

                                {isCartLoading && cartItemsTotal === 0 && (
                                    <div className="medicine-cabinet-v2-cart__loading__blocker--mobile">
                                        <Spinner isVisible fullOverlay={false} t={t} color="silver" />
                                    </div>
                                )}

                                {cartItemsTotal && cartItemsTotal > 0 ? (
                                    <>
                                        <ShippingAddress />
                                        <ShippingMethod />
                                        <PaymentMethod
                                            buttonVariant="text-blue"
                                            showSelectCardRadioInput
                                            showSetDefaultLink
                                        />
                                        <HealthProfile />
                                        <PrescriptionInformation />
                                        <CartTotal isUnknownPrice={false} />
                                    </>
                                ) : (
                                    <EmptyCart
                                        title={t('components.medicineCabinetCart.empty.title')}
                                        body={t('components.medicineCabinetCart.empty.body')}
                                    />
                                )}
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </>
    );
};
export default SidebarColumnMobile;
