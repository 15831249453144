import { navigate } from 'gatsby';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logout } from 'state/account/account.reducers';
import { accountResetReducersOnLogout } from 'state/account/account.routines';
import { clearCache } from 'state/cache/cache.reducers';
import { resetCart } from 'state/cart/cart.reducers';

import { setLoggedOutInterceptor } from 'util/axiosClient';
import { noop } from 'util/function';
import { TrackFlowAbandonment } from 'util/google_optimize/optimize_helper';

const GlobalLinkContext = createContext({
    lastFormField: '',
    setFormName: (formName: string) => noop,
    setLastFormField: (field: string) => noop,
    setStepName: (step: string) => noop,
    setFlowName: (flowName: string) => noop,
    handleFieldFocus: (fieldName: string) => noop,
    trackFormAbandonment: () => noop,
    flowName: '',
    handleSignOut: () => noop
});

export function GlobalLinkProvider({ children }: { children: React.ReactNode }) {
    const globalLinkVal = useProvideGlobalLink();

    return <GlobalLinkContext.Provider value={globalLinkVal}>{children}</GlobalLinkContext.Provider>;
}

export const useGlobalLink = () => {
    return useContext(GlobalLinkContext);
};

const useProvideGlobalLink = () => {
    const [lastFormField, setLastFormField] = useState<string>('');
    const [formName, setFormName] = useState<string>('');
    const [flowName, setFlowName] = useState<string>('');
    const [stepName, setStepName] = useState<string>('');

    const dispatch = useDispatch();

    const trackFormAbandonment = useCallback(() => {
        // if we do not have a last form field set or flow name, break out of this call
        if (!flowName) return;

        TrackFlowAbandonment({ lastFormField: lastFormField || 'Not Available', formName, flowName, stepName });

        // reset fields
        setLastFormField('');
        setFormName('');
        setFlowName('');
        setStepName('');
    }, [flowName, formName, lastFormField, stepName]);

    const handleFieldFocus = useCallback((fieldName: string) => {
        setLastFormField(fieldName);
    }, []);

    const handleSignOut = useCallback(() => {
        // clean cart state
        dispatch(resetCart());
        trackFormAbandonment();
        dispatch(accountResetReducersOnLogout.trigger());
        // Cancel all the request that are being made.
        setLoggedOutInterceptor();
        dispatch(logout());
        dispatch(clearCache());
        navigate('/sign-in');
    }, [dispatch, trackFormAbandonment]);

    return {
        lastFormField,
        setLastFormField,
        formName,
        setFormName,
        flowName,
        setFlowName,
        stepName,
        setStepName,
        trackFormAbandonment,
        handleFieldFocus,
        handleSignOut
    };
};
